import React, { useState } from "react";
import styles from "./InputImage.module.css";


function ColumSubTab(props) {

    return (
      <div className={styles["colum-element"]} key={props.id}>
          {props.children}
      </div>
    );
  }
  
  export default ColumSubTab;
  