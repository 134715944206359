import React from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import { alpha, styled } from '@mui/material/styles';
import { green } from '@mui/material/colors';
import Switch from '@mui/material/Switch';

function InputCheckBox(props) {

    const GreenSwitch = styled(Switch)(({ theme }) => ({
        '& .MuiSwitch-switchBase.Mui-checked': {
          color: green[500],
          '&:hover': {
            backgroundColor: alpha(green[300], theme.palette.action.hoverOpacity),
          },
        },
        '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
          backgroundColor: green[600],
        },
      }));

  return (
    <FormControlLabel control={<GreenSwitch checked={props.checked} onChange={props.onChange}/>} labelPlacement="top" label={props.label} />
  )
}

export default InputCheckBox;