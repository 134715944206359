import React, { useState } from "react";
import styles from "../ui/RightColumm.module.css";
import TabColummVertical from "./TabColummVertical";
import icoPosition from "../../assets/ico/icona-posizionamento.svg";
import icoLights from "../../assets/ico/icona-luci.svg";
import icoMaterial from "../../assets/ico/icona-materiali.svg";
import icoHotspot from "../../assets/ico/icona-hotspot.svg";
import icoTransition from "../../assets/ico/icona-transizioni.svg";
import icoCollection from "../../assets/ico/icona-collezioni.svg";
import icoSettings from "../../assets/ico/icona-impostazioni.svg";
import icoAr from "../../assets/ico/icona_ar.svg";
import icoComp from "../../assets/ico/icona-compressione.svg";
import icoSave from "../../assets/ico/icona-save.png";
import icoExport from '../../assets/ico/icon_export.png'

export default function RightColumm(props) {
  // const [isDisabled, setIsDisabled] = useState(props.disabled);
  const [tabActive, setTabActive] = useState("1");

  function tabChangeHanlder(tab) {
    setTabActive(tab);
    props.tab(tab);
  }

  return (
    <div>
      <div
        className={styles["categorie1182"]}
        style={
          props.disabled
            ? {
                opacity: "0.3",
              }
            : {
                opacity: "0.99",
              }
        }
      >
        <TabColummVertical
          icon={icoPosition}
          title={"Posizionamento"}
          tab={(t) => tabChangeHanlder(t)}
          tabNumber={"1"}
          disabled={props.disabled}
          active={tabActive}
        ></TabColummVertical>
        <TabColummVertical
          icon={icoLights}
          title={"Luci"}
          tab={(t) => tabChangeHanlder(t)}
          tabNumber={"2"}
          disabled={props.disabled}
          active={tabActive}
        ></TabColummVertical>
        <TabColummVertical
          icon={icoMaterial}
          title={"Materiali"}
          tab={(t) => tabChangeHanlder(t)}
          tabNumber={"3"}
          disabled={props.disabled}
          active={tabActive}
        ></TabColummVertical>
        <TabColummVertical
          icon={icoHotspot}
          title={"hotspot"}
          tab={(t) => tabChangeHanlder(t)}
          tabNumber={"4"}
          disabled={props.disabled}
          active={tabActive}
        ></TabColummVertical>
        <TabColummVertical
          icon={icoTransition}
          title={"Transazioni"}
          tab={(t) => tabChangeHanlder(t)}
          tabNumber={"5"}
          disabled={props.disabled}
          active={tabActive}
        ></TabColummVertical>

        <TabColummVertical
          icon={icoCollection}
          title={"Collezioni"}
          tab={(t) => tabChangeHanlder(t)}
          tabNumber={"6"}
          disabled={props.disabled}
          active={tabActive}
        ></TabColummVertical>
        <TabColummVertical
          icon={icoSettings}
          title={"Impostazioni Progetto"}
          tab={(t) => tabChangeHanlder(t)}
          tabNumber={"7"}
          disabled={props.disabled}
          active={tabActive}
        ></TabColummVertical>
        <TabColummVertical
          icon={icoAr}
          title={"Impostazioni AR"}
          tab={(t) => tabChangeHanlder(t)}
          tabNumber={"8"}
          disabled={props.disabled}
          active={tabActive}
        ></TabColummVertical>
        <TabColummVertical
          icon={icoComp}
          title={"Compressione"}
          tab={(t) => tabChangeHanlder(t)}
          tabNumber={"9"}
          disabled={props.disabled}
          active={tabActive}
        ></TabColummVertical>

        <div className={styles["row-element"]} key={props.id}>
          <div className={styles["colum-element"]} key={props.id}>
            <div className={styles["save-_button"]} onClick={props.saveHandler}>
              <div className={styles["flex-col"]}>
                <img
                  src={icoSave}
                  alt="Not Found"
                  className={styles["icon-_save"]}
                />
                <p className={styles["txt-959 flex-hcenter"]}>Save</p>
              </div>
            </div>
          </div>
          <div className={styles["colum-element"]} key={props.id}>
            <div
              className={styles["export-_button"]}
              onClick={props.saveHandler}
            >
              <div className={styles["flex-col"]}>
                <img
                  src={icoExport}
                  alt="Not Found"
                  className={styles["icon-_save"]}
                />
                <p className={styles["txt-959 flex-hcenter"]}>export</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
