import React, { useState } from "react";
import styles from "./inputSliderRow.module.css";
import NumberFactor from "./NumberFactor";
import Slider from "./Slider";

function InputSliderRow(props) {
  return (
    <div className={styles["row-element"]} key={props.id}>

      <Slider
        id={props.id}
        maxValue={props.maxValue}
        minValue={props.minValue}
        step={props.step}
        defaultValue={props.value}
        onChange={props.onChange}
      />

      <NumberFactor
        value={props.value}
        id={props.id}
        onChange={props.onChange}
      ></NumberFactor>

    </div>
  );
}

export default InputSliderRow;
