import { createSlice } from "@reduxjs/toolkit";

const initialProjectState = [];

const authSlice = createSlice({
  name: "project",
  initialState: initialProjectState,
  reducers: {
    setSelectedModel(state, action) {
      const { idProject, value } = action.payload;
      const selectedProject = state.find((project) => project.id === idProject);
      if (selectedProject) {
        selectedProject.selected_model = value;
      }
    },
    setMenuType(state,action){
      const { idProject, value } = action.payload;
      const selectedProject = state.find((project) => project.id === idProject);
      if (selectedProject) {
        selectedProject.menu_type = value;
      }
    },
    setMenuPosition(state,action){
      const { idProject, value } = action.payload;
      const selectedProject = state.find((project) => project.id === idProject);
      if (selectedProject) {
        selectedProject.menu_position = value;
      }
    },
    addModelToList(state, action) {
      const { idProject, idModel } = action.payload;
      const selectedProject = state.find((project) => project.id === idProject);
      if (selectedProject) {
        selectedProject.model_list.push(idModel);
      }
    },
    setModelList(state, action) {
      const { idProject, value } = action.payload;
      const selectedProject = state.find((project) => project.id === idProject);
      if (selectedProject) {
        selectedProject.model_list = value;
      }
    },
    setSelectedModelInView(state, action) {
      const { idProject, value } = action.payload;
      const selectedProject = state.find((project) => project.id === idProject);
      if (selectedProject) {
        selectedProject.selected_model_view = value;
      }
    },
    setMenuOrientation(state, action) {
      const { idProject, value } = action.payload;
      const selectedProject = state.find((project) => project.id === idProject);
      if (selectedProject) {
        //console.log("in view", value);
        selectedProject.menu_orientation = value;
      }
    },
    addNewProject(state, action) {
      const { idProject, value, formatDate } = action.payload;

      const newProject = { ...value, id: idProject, creation_date: formatDate };
      state.push(newProject);
    },
    setIsModelLoaded(state, action) {
      const { idProject, value } = action.payload;
      const selectedProject = state.find((project) => project.id === idProject);
      if (selectedProject) {
        selectedProject.isFirstModelLoaded = value;
      }
    },
    setFirebaseId(state, action) {
      const { idProject, value } = action.payload;
      const selectedProject = state.find((project) => project.id === idProject);
      if (selectedProject) {
        selectedProject.id = value;
      }
    },
    setPosterImage(state, action) {
      const { idProject, value } = action.payload;
      const selectedProject = state.find((project) => project.id === idProject);
      if (selectedProject) {
        selectedProject.poster = value;
      }
    },
    setTitle(state, action) {
      const { idProject, value } = action.payload;
      console.log("dispatch title", idProject);
      const selectedProject = state.find((project) => project.id === idProject);
      if (selectedProject) {
        selectedProject.title = value;
      }
    },
    setQrCode(state, action) {
      const { idProject } = action.payload;
      const selectedProject = state.find((config) => config.id === idProject);
      if (selectedProject) {
        selectedProject.qrCode = !selectedProject.qrCode;
      }
    },
    setQrBackGroundColor(state, action) {
      const { idProject, value } = action.payload;
      const selectedProject = state.find((config) => config.id === idProject);
      if (selectedProject) {
        selectedProject.qrBackGroundColor = value;
      }
    },
    setQrForeGroundColor(state, action) {
      const { idProject, value } = action.payload;
      const selectedProject = state.find((config) => config.id === idProject);
      if (selectedProject) {
        selectedProject.qrForeGroundColor = value;
      }
    },
    setModalQr(state, action) {
      const { idProject } = action.payload;
      const selectedProject = state.find((config) => config.id === idProject);
      if (selectedProject) {
        selectedProject.modalQr = !selectedProject.modalQr;
      }
    },
    reset: () => initialProjectState,
  },
});

export const modelActionsProject = authSlice.actions;

export default authSlice.reducer;
