import React from "react";

export const MENUITEMS = [

  {
    title: 'Projects',
    icon: <i className="pe-7s-home pe-lg"></i>,
    path: "/dashboard/project",
    type: "sub",
    active: true,
    bookmark: true,
    // children: [
    //   { title: "Dashboard", type: "sub" },
    //   { title: "Default", type: "link", path: "/default/sample-page" },
    // ],
  },
  {
    title: "Profile",
    icon: <i className="pe-7s-edit"></i>,
    path: "/dashboard/profile",
    type: "sub",
    active: false,
    // children: [
    //   { title: "Sample Page", type: "sub" },
    //   { title: "Sample Page", type: "link", path: "/starter-kit/sample-page" },
    // ],
  },
  {
    title: "Documentation",
    icon: <i className="pe-7s-note2"></i>,
    path: "/dashboard/documentation",
    type: "sub",
    active: false,
    // children: [
    //   { title: "Ticket", type: "sub" },
    //   {
    //     title: "Raise Ticket",
    //     type: "exteral_link",
    //     path: "http://support.pixelstrap.com/help-center",
    //   },
    // ],
  },
  {
    title: "Report Bugs",
    icon: <i className="pe-7s-note2"></i>,
    path: "/dashboard/report",
    type: "sub",
    active: false,
    // children: [
    //   { title: "Ticket", type: "sub" },
    //   {
    //     title: "Raise Ticket",
    //     type: "exteral_link",
    //     path: "http://support.pixelstrap.com/help-center",
    //   },
    // ],
  },

];
