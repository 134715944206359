import {combineReducers} from 'redux'
import Customizer from './customizer/reducer'
import Auth from "./auth";
import Model from "./model";
import Project from './project'
import defaultModel from './defaultModel';
import defaultProject from './defaultProject';
import generalConfig from './generalConfig';
import loading from './loading';

const reducers = combineReducers({
    Customizer: Customizer,
    auth: Auth,
    model: Model,
    project: Project,
    defaultModel: defaultModel,
    defaultProject: defaultProject,
    config: generalConfig,
    loading: loading,
});

export default reducers;