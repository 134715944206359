import React from "react";
import { Container, Col } from "reactstrap";
import { Settings } from "react-feather";

const Rightsidebar = (props) => {
  let images = require.context("../../../assets/images", true);

  const dynamicImage = (image) => {
    return images(`./${image}`);
  };

  return (
    <div className="right-sidebar" id="right_side_bar">
   
    </div>
  );
};

export default Rightsidebar;


