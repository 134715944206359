import styles from "../ui/ProjectDashBoardCard.module.css";
import React, { useState } from "react";
import { Button, Label, Input } from "reactstrap";
import { firebaseSaveProjectTitle } from "../../utility/function/firebaseFunction";
import BackDrop from "./modal/BackDrop";
import Modal from "./modal/ModalRename";
import { useDispatch } from "react-redux";
import styleModal from "./modal/Modal.module.css";
import stylesBackDrop from "./modal/BackDrop.module.css";
import { modelActionsProject } from "../../redux/project";
import RowSubTab from "./InputComponent/RowSubTab";
import ColumSubTab from "./InputComponent/ColumSubTab";
import coverPosterDashboard from "../../assets/ico/cover-poster-dash.svg";
import icoChangeTitle from "../../assets/ico/edit-title-icon.svg";
import icoTrash from "../../assets/ico/icona-cestino.svg";
import icoEdit from "../../assets/ico/icona-edit.svg";
import icoDuplicate from "../../assets/ico/icona-duplica.svg";
import icoBorder from "../../assets/ico/icona-edit-border.svg";


export default function ProjectDashBoardCard(props) {
  
  const dispatch = useDispatch();
  const [isModalRenameOpen, setIsModalRenameOpen] = useState(false);
  const [projectName, setProjectName] = useState("");

  const modelCard = props.isModelCard;

  async function submitProjectNameHandler(project) {
    dispatch(
      modelActionsProject.setTitle({
        idProject: project.id,
        value: projectName,
      })
    );
    firebaseSaveProjectTitle(project, projectName);
    setIsModalRenameOpen(false);
  }

  return (
    <div>
      {modelCard && (
        <RowSubTab>
          <div className={styles["model"]}>
            <div
              className={styles["model-image"]}
              onClick={props.onClickBackground}
              style={{
                backgroundImage: `url(${coverPosterDashboard})`,
              }}
            >
              <div
                className={styles["model-img"]}
                style={{ backgroundImage: `url(${props.poster})` }}
              >
                <div className={styles["Group"]}>
                  <span className={styles["text2"]}>{props.title.slice(0,7)}</span>
                </div>
              </div>
            </div>
          </div>

          <ColumSubTab>
            <img
              alt="Vector291628"
              src={icoChangeTitle}
              className={styles["svg2"]}
              onClick={props.onClickEditTitle}
            />

            <img
              src={icoTrash}
              alt="delete4291645"
              className={styles["svg2"]}
              onClick={props.onClickDelete}
            />
            <img
              src={icoEdit}
              alt="edit1291650"
              className={styles["svg2"]}
              onClick={props.onClickEdit}
            />
            <img
              src={icoDuplicate}
              alt="duplicate2291653"
              className={styles["svg2"]}
              onClick={props.onClickDuplicate}
            />
          </ColumSubTab>
        </RowSubTab>
      )}
      {!modelCard && (
        <div className={styles["project"]}>
          <div className={styles["project11142"]}>
            <div
              className={styles["Group95291618"]}
              onClick={props.onClickBackground}
            >
              <div className={styles["Group14291619"]}>
                <img
                  src={coverPosterDashboard}
                  alt="Rectangle11291620"
                  className={styles["image"]}
                />
              </div>
              <img
                src={coverPosterDashboard}
                alt="Rectangle11291621"
                className={styles["image1"]}
              />
              <img
                src={props.poster}
                alt="esempio_3D_1291622"
                className={styles["image2"]}
              />
              <div className={styles["Group15291624"]}>
                <span className={styles["text"]}>{props.title}</span>
              </div>
              <div className={styles["Group16291824"]}>
                <span className={styles["text1"]}>{props.date}</span>
              </div>
            </div>
            <div className={styles["iconelementi291626"]}>
              <div className={styles["edittxt1291627"]}>
                <img
                  alt="Vector291628"
                  src={icoChangeTitle}
                  className={styles["svg"]}
                />
                <img
                  src={icoBorder}
                  alt="Group291629"
                  className={styles["image3"]}
                  onClick={() => setIsModalRenameOpen(true)}
                />
              </div>
              <img
                src={icoTrash}
                alt="delete4291645"
                className={styles["image4"]}
                onClick={props.onClickDelete}
              />
              <img
                src={icoEdit}
                alt="edit1291650"
                className={styles["image5"]}
                onClick={props.onClickEdit}
              />
              <img
                src={icoDuplicate}
                alt="duplicate2291653"
                className={styles["image6"]}
                onClick={props.onCLickDuplicate}
              />
            </div>
            {isModalRenameOpen && (
              <Modal className={styleModal["modal-rename"]}>
                <div>
                  <Label>Change project name</Label>
                  <Input
                    type="text"
                    maxLength="30"
                    onChange={(e) => setProjectName(e.target.value)}
                    defaultValue={props.project.title}
                  />
                  <Button
                    onClick={() => submitProjectNameHandler(props.project)}
                  >
                    Submit
                  </Button>
                </div>
              </Modal>
            )}
            {isModalRenameOpen && (
              <BackDrop
                className={stylesBackDrop.backdrop}
                onCancel={() => setIsModalRenameOpen(false)}
              />
            )}
          </div>
        </div>
      )}
    </div>
  );
}
