import React, { useState } from "react";
import styles from "./InputImage.module.css";


function RowSubTab(props) {

    return (
      <div className={styles["row-element"]} key={props.id}>
          {props.children}
      </div>
    );
  }
  
  export default RowSubTab;
  