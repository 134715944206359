import React, { createRef, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
// import { useTranslation } from "react-i18next";
import project, { modelActionsProject } from "../redux/project";
import { Form, Button } from "reactstrap";
import CloneModel from "./CloneModel.js";
import { useHistory } from "react-router-dom";
import {modelActions} from '../redux/model';

function ModelsThumbView(props) {
  const dispatch = useDispatch();
  const history = useHistory();

  const modelView = useSelector((state) =>
    state.model.find((model) => model.id === props.modelId)
  );

  function selectModelToEdit() {
    dispatch(
      modelActionsProject.setSelectedModel({
        idProject: props.project.id,
        value: modelView.id,
      })
    );
    // history.push("/dashboard/edit-model");
  }

  return (
    <div>
      modello {modelView.name}
      {modelView.poster_image === "/static/media/not-image.d1f69042.png" && (
        <div>
          <p>
            Anteprima non disponibile genera una poster image
            <Button> genera un poster</Button>
          </p>
        </div>
      )}
      {modelView.poster_image !== "/static/media/not-image.d1f69042.png" && (
        <div>
          <img
            src={modelView.poster_image}
            alt={modelView.alt}
            width="50px"
          ></img>
        </div>
      )}
      <Button onClick={selectModelToEdit}>Edit Model</Button>
      <CloneModel project={props.project} model={modelView}></CloneModel>
    </div>
  );
}

export default ModelsThumbView;
