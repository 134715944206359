import style from "../ui/ModelHeader.module.css";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import Modal from "./modal/ModalRename";
import BackDrop from "./modal/BackDrop";
import { firebaseSaveProjectTitle } from "../../utility/function/firebaseFunction";
import { Button, Label, Input } from "reactstrap";
import {useHistory} from 'react-router-dom';
import { modelActionsProject } from "../../redux/project";
import styleModal from './modal/Modal.module.css';
import stylesBackDrop from './modal/BackDrop.module.css';
import icoSpaarkly from "../../assets/ico/icona_spaarkly.png";
import icoBack from "../../assets/ico/back-icon.svg";
import icoChangeTitle from "../../assets/ico/edit-title-icon.svg";  

export default function ModelHeader(props) {
  const dispatch = useDispatch();
  const history = useHistory();

  const [isModalRenameOpen, setIsModalRenameOpen] = useState(false);
  const [projectName, setProjectName] = useState("");

  function renameHandle() {
    console.log(projectName);
    setIsModalRenameOpen(true);
  }

  async function submitProjectNameHandler(project) {
    console.log("new title");
    dispatch(
      modelActionsProject.setTitle({
        idProject: project.id,
        value: projectName,
      })
    );
    firebaseSaveProjectTitle(project, projectName);
    setIsModalRenameOpen(false);
  }

  return (
    <div className={style.headerProject}>
      {isModalRenameOpen && (
        <Modal className={styleModal["modal-rename"]}>
          <div>
            <Label>Change project name</Label>
            <Input
              type="text"
              maxLength="30"
              onChange={(e) => setProjectName(e.target.value)}
              defaultValue={props?.project?.title}
            />
            <Button onClick={() => submitProjectNameHandler(props.project)}>
              Submit
            </Button>
          </div>
        </Modal>
      )}
      {isModalRenameOpen && (
        <BackDrop
          className={stylesBackDrop.backdrop}
          onCancel={() => setIsModalRenameOpen(false)}
        />
      )}

      <img
        src={icoSpaarkly}
        alt="image not found"
        className={style.spaarklyLogo1}
      />
      <div className={style["titoloprogetto291004"]}>
        <img
          src={icoBack}
          alt="freccia1291006"
          className={style["image"]}
          onClick={() => history.push('/dashboard/project')}
        />
        <div className={style["text"]}>
          Projects | {props?.project?.title}
          <div className={style["edittxt1291627"]}>
            <img
              alt="Vector291628"
              src={icoChangeTitle}
              className={style["svg"]}
              onClick={() => {renameHandle()}}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
