import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Label } from "reactstrap";
import BaseColor from "../BaseColor";
import NotImage from "../../assets/images/not-texture.png";
import { modelActions } from "../../redux/model";
import {changeTexture} from '../../utility/function/textureChange';
import SubtitleTab from "../../component/ui/SubtitleTab";
import InputImageRow from "../../component/ui/InputComponent/InputImageRow";

function BaseColorTexture(props) {
  const dispatch = useDispatch();

  const newMaterialList = [];
  const listOfMaterial = props?.modelSelected?.list_material_two;

  const idConfig = useSelector((state) =>
    state.config.find((config) => config.id === "general")
  );

  const project = useSelector((state) =>
    state.project.find((project) => project.id === idConfig.projectOnEdit)
  );

  const model = document.querySelector("model-viewer#model");
  const materialsViewer = model?.model?.materials;

  const selectedMaterialViewer = materialsViewer?.find(
    (mat) => mat.name === props.materialSelected?.name
  );

  async function changeTextureHandler(event) {
    console.log("selected mat", selectedMaterialViewer);

    if (!event.target.files[0]) {
      return;
    }

    const url = await changeTexture('baseColorTexture', selectedMaterialViewer, event.target.files[0], props.modelSelected, project);

    listOfMaterial.map((material) => {
      if (material.name === props.materialSelected.name) {
        let a = {
          name: material.name,
          metallicFactor: material.metallicFactor,
          roughnessFactor: material.roughnessFactor,
          baseColorFactor: material.baseColorFactor,
          emissiveFactor: material.emissiveFactor,
          baseColorImage: url,
          metallicRoughnessImage: material.metallicRoughnessImage,
          emissiveImage: material.emissiveImage,
          normalImage: material.normalImage,
          occlusionImage: material.occlusionImage,
        };
        newMaterialList.push(a);
      }
       else {
        let b = {
          name: material.name,
          metallicFactor: material.metallicFactor,
          roughnessFactor: material.roughnessFactor,
          baseColorFactor: material.baseColorFactor,
          emissiveFactor: material.emissiveFactor,
          baseColorImage: material.baseColorImage,
          metallicRoughnessImage: material.metallicRoughnessImage,
          emissiveImage: material.emissiveImage,
          normalImage: material.normalImage,
          occlusionImage: material.occlusionImage,
        };
        newMaterialList.push(b);
      }
    });

    dispatch(
      modelActions.updateListMaterial({
        idModel: props.modelSelected.id,
        value: newMaterialList,
      })
    );
  }

  async function defaultThumbBaseColorTexture() {
    if (!props.originalTxt?.source) {
      return null;
    }
    const promise = await props.originalTxt.source?.createThumbnail(256, 256);
    return promise;
  }

  const [savedTxt, setSavedTxt] = useState(
    props.materialSelected?.baseColorTexture
  );

  async function restoreTexture() {
    selectedMaterialViewer.pbrMetallicRoughness.baseColorTexture.setTexture(
      savedTxt
    );

    console.log(savedTxt);

    const thumb = await defaultThumbBaseColorTexture();

    listOfMaterial.map((material) => {
      if (material.name === selectedMaterialViewer.name) {
        let a = {
          name: material.name,
          metallicFactor: material.metallicFactor,
          roughnessFactor: material.roughnessFactor,
          baseColorFactor: material.baseColorFactor,
          emissiveFactor: material.emissiveFactor,
          baseColorImage: thumb ? thumb : NotImage,
          metallicRoughnessImage: material.metallicRoughnessImage,
          emissiveImage: material.emissiveImage,
          normalImage: material.normalImage,
          occlusionImage: material.occlusionImage,
          
        };
        newMaterialList.push(a);
      } else {
        let b = {
          name: material.name,
          metallicFactor: material.metallicFactor,
          roughnessFactor: material.roughnessFactor,
          baseColorFactor: material.baseColorFactor,
          emissiveFactor: material.emissiveFactor,
          baseColorImage: material.baseColorImage,
          metallicRoughnessImage: material.metallicRoughnessImage,
          emissiveImage: material.emissiveImage,
          normalImage: material.normalImage,
          occlusionImage: material.occlusionImage,
        };
        newMaterialList.push(b);
      }
    });
    dispatch(
      modelActions.updateListMaterial({
        idModel: props.modelSelected.id,
        value: newMaterialList,
      })
    );
  }

  function removeTexture() {
    selectedMaterialViewer.pbrMetallicRoughness.baseColorTexture.setTexture(
      null
    );

    listOfMaterial.map((material) => {
      if (material.name === selectedMaterialViewer.name) {
        let a = {
          name: material.name,
          baseColorImage: NotImage,
          pbrMetallicRoughness: material.pbrMetallicRoughness,
          emissiveTexture: material.emissiveTexture,
          normalTexture: material.normalTexture,
          occlusionTexture: material.occlusionTexture,
          metallicRoughnessImage: material.metallicRoughnessImage,
          emissiveImage: material.emissiveImage,
          normalImage: material.normalImage,
          occlusionImage: material.occlusionImage,
        };
        newMaterialList.push(a);
      } else {
        console.log("materials doesnt match");
        let b = {
          name: material.name,
          baseColorImage: material.baseColorImage,
          pbrMetallicRoughness: material.pbrMetallicRoughness,
          emissiveTexture: material.emissiveTexture,
          normalTexture: material.normalTexture,
          occlusionTexture: material.occlusionTexture,
          metallicRoughnessImage: material.metallicRoughnessImage,
          emissiveImage: material.emissiveImage,
          normalImage: material.normalImage,
          occlusionImage: material.occlusionImage,
        };
        newMaterialList.push(b);
      }
    });
    dispatch(
      modelActions.updateListMaterial({
        idModel: props.modelSelected.id,
        value: newMaterialList,
      })
    );
  }

  function filesHandler() {
    document.getElementById('input-basecolor').click();
  }

  return (
    <div>
     
      <div>
      <SubtitleTab title="Fattore" />
        <BaseColor
          modelViewerRef={props.modelViewerRef}
          modelSelected={props.modelSelected}
          materialSelected={props.materialSelected}
          listOfMaterial = {listOfMaterial}
          color={props.color}
          // setColor={props.setColor}
          id ={props.materialSelected?.name}
        />
      </div>
      <SubtitleTab title="Texture" />
      <InputImageRow id={"baseColor"} text="Image" image={props.materialSelected?.baseColorImage} onChange={changeTextureHandler} accept="image/*" remove={true} restore={true} restoreTexture={restoreTexture} removeTexture={removeTexture}></InputImageRow>

    
    </div>
  );
}

export default BaseColorTexture;
