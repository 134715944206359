import { firestore } from "../firebase";
import React, { Fragment, useState, createRef, useEffect } from 'react'

import { storage } from "../firebase";
import { useSelector, useDispatch } from "react-redux";
import { modelActions } from "../../redux/model";

let counter = 0;

export async function firebaseSaveProject(project, reduxModel) {
  const res = firestore.collection("Progetti").doc(project.id);

    //Save Project Poster

    
    let responseSrcPoster = await fetch(project.poster);
    const blobSrcPoster = await responseSrcPoster.blob();

    let storageRefFile = storage.ref(
      `Progetti/${project.id}/project-${project.id}-Poster.png`,
    )

    let urlPosterProject = await storageRefFile.put(blobSrcPoster).then(()=>{
      return storageRefFile.getDownloadURL()
    })




  const result = res.set({...project,poster:urlPosterProject});
  let finish = await project.model_list.map((model) => {
      SaveProjectModel(reduxModel, res, model, project);
  })
  return finish;
};

export const firebaseSaveProjectTitle = (project, projectName) => {
  const data = { ...project, title: projectName };
  const res = firestore.collection("Progetti").doc(project.id);
  const result = res.set(data);
};

export async function firebaseSetProjectToUser(user, projectId) {
  const setProjectOnUser = await firestore.collection("Account").doc(user);

  return firestore
    .runTransaction((transaction) => {
      // This code may get re-run multiple times if there are conflicts.
      return transaction.get(setProjectOnUser).then((sfDoc) => {
        if (!sfDoc.exists) {
          throw "Document does not exist!";
        }
        // Note: this could be done without a transaction
        console.log(projectId);

        let newProjectList = sfDoc.data().project_list;

        console.log("new project list", newProjectList);

        let projectExists = false;

        newProjectList.map((project) => {
          if (project === projectId) {
            projectExists = true;
          }
        });
        if (projectExists) {
          console.log("progetto presente");
        } else {
          newProjectList.push(projectId);
          console.log("progetto non esiste");
        }
        transaction.update(setProjectOnUser, { project_list: newProjectList });
      });
    })
    .then(() => {
      console.log("Transaction successfully committed!");
    })
    .catch((error) => {
      console.log("Transaction failed: ", error);
    });
}

async function SaveProjectModel(reduxModel, res, modelId, project) {

   let model = reduxModel.find(model => model.id === modelId)
    console.log(model)

    console.log("sono qui");
    let modelCollection = firestore
      .collection("Progetti")
      .doc(res.id)
      .collection("Model")
      .doc(modelId);

    //Save Model

    let responseSrcModel = await fetch(model.src);
    const blobSrcModel = await responseSrcModel.blob();

    let storageRefFileModel = storage.ref(
      `Progetti/${project.id}/${model.id}/model-${model.id}.glb`,
    )

    let urlModel = await storageRefFileModel.put(blobSrcModel).then(()=>{
      return storageRefFileModel.getDownloadURL()
    })

     //Save Original Model

    //  let responseSrcOriginalModel = await fetch(model.original_model);
    //  const blobSrcModelOriginal = await responseSrcOriginalModel.blob();
 
    //  let storageRefFileModelOriginal = storage.ref(
    //    `Progetti/${project.id}/${model.id}/model-${model.id}-original.glb`,
    //  )
 
    //  let urlModelOriginal = await storageRefFileModelOriginal.put(blobSrcModelOriginal).then(()=>{
    //    return storageRefFileModelOriginal.getDownloadURL()
    //  })



    //Save Poster

    let responseSrcPosterModel = await fetch(model.poster_image);
    const blobSrcModelPoster = await responseSrcPosterModel.blob();

    let storageRefPosterModel = storage.ref(
      `Progetti/${project.id}/${model.id}/poster-${model.id}.png`,
    )

    let urlPoster = await storageRefPosterModel.put(blobSrcModelPoster).then(()=>{
      return storageRefPosterModel.getDownloadURL()
    })

    
      modelCollection.set({...model,src:urlModel, poster_image:urlPoster});

    

    

      // SaveStorageFile(res, model, modelCollection);
      // let modelToSave = {...model, src:urlModel}

      // modelCollection.set({...model,src:urlModel, poster_image:urlPoster});
      // console.log(pathModel);
 }



export async function deleteModel(projectId, modelId) {
  const ref = await firestore
    .collection("Progetti")
    .doc(projectId)
    .collection('Model')
    .doc(modelId)
    .delete()
  // .then(() => console.log("succes delete"))
  // .catch((error) => console.log(error.message));

  // await deleteDoc(ref);
}

export const createFirebaseProjectRef = () => {
  const ref = firestore.collection("Progetti").doc();
  return ref.id;
};

export const createFirebaseModelRef = () => {
  const ref = firestore.collection("Progetti").doc();
  return ref.id;
};

export async function setNewGlb(modelSelected, project) {
  const glTF = await document.getElementById("model").exportScene();

  let file = new File([glTF], "newGlb.glb");

  // let storageRefFile = storage.ref(
  //   `Progetti/${project.id}/${modelSelected.id}/new-model-${modelSelected.id}.glb`
  // );
  const responseSrc = await fetch(URL.createObjectURL(file));

  const blobSrc = await responseSrc.blob();

  // let url = await storageRefFile.put(blobSrc).then((snapshot) => {
  //   return storageRefFile.getDownloadURL();
  // });

  return URL.createObjectURL(blobSrc);
  
}
