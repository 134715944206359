import { createSlice } from '@reduxjs/toolkit';

const initialState = {
};

const loadingSlice = createSlice({
  name: 'loading',
  initialState: initialState,
  reducers: {},
});

export const loadingActions = loadingSlice.actions;

export default loadingSlice.reducer;