import React,{useState} from "react";
import styles from "../ui/TabColummVertical.module.css";

export default function TabColummVertical(props) {
  
  // const [isDisabled, setIsDisabled] = useState(props.disabled);
  
  function tabChangeHanlder(tab) {
    
    props.tab(tab);
  }

  return (

    <div className={`${props.tabNumber === props.active ? styles["posizionamentoTabActive"] : styles["posizionamentoTab"]}`} onClick={() => {
        if(props.disabled){
          return ;
        } else {
          tabChangeHanlder(props.tabNumber)
        }
      }}>
       
        <img
              src={props.icon}
              alt="icon_posizionamento39615"
              className={styles["image01"]}
        />
       <span className={styles["textTab"]}> {props.title}</span>

    </div>
  );
}