import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { modelActions } from "../redux/model";
import { useTranslation } from "react-i18next";
import { Button } from "reactstrap";
import { storage } from "../utility/firebase";
import { modelActionsProject } from "../redux/project";
import ButtonUpload from "../component/ui/ButtonUpload";

function Screenshot(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  const loaded = props.isGlbLoaded;
  //console.log("props modelviewerRef", document.getElementById('model'))
  async function screenShotHandler() {
    // const blob = await props.modelViewerRef.current.toBlob({idealAspect: false })
    // const url = URL.createObjectURL(blob);

    const blob = await document
      .getElementById("model")
      .toBlob({ idealAspect: false });
    // const url = URL.createObjectURL(blob);

    // let storageRefFile = storage.ref(
    //   `Progetti/${props.project.id}/${props.modelSelectedId}/model-${props.modelSelectedId}-poster.png`
    // );


    // const responsePoster = await fetch(blob);
    // const blobPoster= await responsePoster.blob();
    
    
    // const url = await storageRefFile.put(blob).then((snapshot) => {
    //   return storageRefFile.getDownloadURL();
    // });

    dispatch(
      modelActions.setPosterImage({
        idModel: props.modelSelectedId,
        value: URL.createObjectURL(blob),
      })
    );
    dispatch(
      modelActionsProject.setPosterImage({
        idProject: props.project.id,
        value: URL.createObjectURL(blob),
      })
    );
    props.onClick();
  }

  function changePoster(event) {
    dispatch(
      modelActions.setPosterImage({
        idModel: props.modelSelectedId,
        value: URL.createObjectURL(event.target.files[0]),
      })
    );
  }

  return (
    <div>
      <button
        style={{
          background: "#00d3d7",
          borderRadius: "13.5px",
          color: "white",
          padding: "10px",
          size: "14px",
          marginBottom: "auto",
        }}
        className="m-10"
        onClick={screenShotHandler}
        //disabled={!loaded}
      >
        {t("tab2_createposter")}
      </button>
      {/*Container  poster*/}

      {props.posterGlb !== "/static/media/not-image.d1f69042.png" && (
        <div
          style={{
            borderRadius: "13.5px",
            backgroundColor: "white",
            width: "200px",
            height: "200px",
            margin: "10px",
            backgroundImage: `url(${props.posterGlb})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        ></div>
      )}

      {props.posterGlb === "/static/media/not-image.d1f69042.png" && (
        <div
          style={{
            borderRadius: "13.5px",
            backgroundColor: "white",
            width: "200px",
            height: "200px",
            margin: "10px",
          }}
        >
          <div>
            <img
              style={{
                width: "200px",
                height: "200px",
                borderRadius: "13.5px",
              }}
              src={props.posterGlb}
            />
            <p>genera una poster image per il modello 3D</p>
          </div>

          {/* {props.posterGlb !== "/static/media/not-image.d1f69042.png" && (
        <img className="poster-img-preview" src={props.posterGlb} alt=""></img>
      )} */}
        </div>
      )}
      <div style={{ marginTop: "50px" }}>
        <ButtonUpload
          id="btn-scrn"
          text="Image"
          accept="image/*"
          onChange={changePoster}
        />
      </div>
    </div>
  );
}

export default Screenshot;
