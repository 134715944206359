import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import model, { modelActions } from "../../redux/model";
import Styles from "../ui/ModelContainer.module.css";
import { modelActionsProject } from "../../redux/project";

function CardModelView(props) {
  const dispatch = useDispatch();

  const model = useSelector((state) =>
    state.model.find((model) => model.id === props.modelId)
  );
  // console.log(props.modelId, props.selected);

  // itemClassic
  // itemSelected
  // itemDot
  // itemDotSelected
  // item

  return (
    <div>
      {props.cardStyle === "circle" && (
        <div
          className={
            props.modelId === props.selected
              ? Styles["itemDotSelected"]
              : Styles["itemDot"]
          }
          onClick={props.selectModel}
          style={{ backgroundImage: `url(${model?.poster_image})` , 
           width:`${props.modelId === props.selected ? props.project.items_with_selected : props.project.items_with}`, 
           height:`${props.modelId === props.selected ? props.project.items_with_selected : props.project.items_with}`}}
        ></div>
      )}
      {props.cardStyle === "plane" && (
        <div
          className={
            props.modelId === props.selected
              ? Styles["itemClassicSelected"]
              : Styles["itemClassic"]
          }
          onClick={props.selectModel}
        >
          <img
            src={model?.poster_image}
            alt="card model viewer"
            className={Styles["image_plane"]}
          ></img>
          {props.modelId === props.selected && (
            <p className={Styles["selected-indicator"]}></p>
          )}
        </div>
      )}

      {props.cardStyle === "card" && (
        <div
          className={
            props.modelId === props.selected
              ? Styles["itemSelected"]
              : Styles["item"]
          }
          onClick={props.selectModel}
          style={{ backgroundImage: `url(${model?.poster_image})` }}
        >
          {/* <img src={model.poster_image} alt="card model viewer" className={Styles["image"]} ></img>  */}
        </div>
      )}
    </div>
  );
}

export default CardModelView;
