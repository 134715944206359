import React from "react";
import { modelActions } from "../redux/model";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import InputCheckBox from '../component/ui/InputComponent/InputCheckBox';

function AutoRotate(props) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  function rotateHandler() {
    dispatch(
      modelActions.setAutoRotate({
        idModel: props.modelSelected.id,
        value: !props.modelSelected.autoRotate,
      })
    );
  }

  return (
    <div>
      <InputCheckBox checked={props?.modelSelected?.autoRotate} onChange={rotateHandler} label={t('tab1_autorotate')}/>
    </div>
  );
}

export default AutoRotate;
