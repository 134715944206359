import React, { Fragment, useState, createRef, useEffect } from "react";
import { useTranslation } from "react-i18next";
import Breadcrumbs from "../component/common/breadcrumb/breadcrumb";
import { useSelector, useDispatch } from "react-redux";
import ProjectModelViewer from "../component/viewer/ProjectModelViewer";
import Screenshot from "../actions/Screenshot";
import {
  Container,
  Row,
  Col,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Button,
} from "reactstrap";
import ModelsThumbView from "../actions/ModelsThumbView";
import AddNewProject from "../actions/AddNewModel";
import NotImage from "../assets/images/not-image.png";
import { useHistory } from "react-router-dom";
import SelectedMenuOrientation from "../actions/menu/SelectedMenuOrientation";
import Modal from "../component/ui/modal/Modal";
import BackDrop from "../component/ui/modal/BackDrop";


function EditProject() {
  const project = useSelector((state) =>
    state.project.find((project) => project.id === "d65768f")
  );
  const modelSelected = useSelector((state) =>
    state.model.find((model) => model.id === project.selected_model)
  );

  const [RightLineTab, setRightLineTab] = useState("1");
  const history = useHistory();

  function setProjectTab(tab) {
    if (tab === "2") {
      // history.push("/dashboard/edit-model");
      setViewerMaterial();
    } else {
      setRightLineTab(tab);
    }
  } 

  //base color caricata da store in model
  async function setViewerMaterialList() {
    try {
      const materialsViewer =
        document.querySelector("model-viewer")?.model?.materials;
      if (materialsViewer) {
        materialsViewer.map((mat) => {
          modelSelected.list_material_two.map((reduxMaterial) => {
            if (reduxMaterial.name === mat.name) {
              mat.pbrMetallicRoughness.baseColorTexture.setTexture(
                reduxMaterial.baseColorTexture
              );
            }
          });
        });
      }
    } catch (error) {}
  }

  useEffect(() => {
    setViewerMaterialList();
  }, []);

  //texture dello store caricate in model-viewer
  async function setViewerMaterial() {
    try {
      const materialsViewer =
        document.querySelector("model-viewer")?.model?.materials;
      if (materialsViewer) {
        materialsViewer.map((mat) => {
          modelSelected.list_material_two.map((reduxMaterial) => {
            if (reduxMaterial.name === mat.name) {
              const texture =
                mat.pbrMetallicRoughness.baseColorTexture?.texture?.source;
              texture.setURI(reduxMaterial.baseColorTexture);
              console.log("i materiali coincidono");
            }
          });
        });
      }
    } catch (error) {
      console.log("errore nel caricamneto del modello", error);
    }
  }

  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <Fragment>
      <Breadcrumbs
        parent="dashboard"
        title={project.title}
      />
      <Container fluid={true}>
        <Row>
          <Col className="col-md-9">
            <div>
              <ProjectModelViewer project={project}></ProjectModelViewer>
            </div>
          </Col>
          <Col className="col-md-3">
            <Row>
              <Col sm="9">
                <TabContent activeTab={RightLineTab} className="text-right">
                  {/* Tab 1: poster e screenShoot*/}
                  <TabPane tabId="1">
                    <div>
                      a project view
                      {project.model_list.map((modelId, i) => (
                        <ModelsThumbView
                          project={project}
                          key={i}
                          modelId={modelId}
                        ></ModelsThumbView>
                      ))}
                      <AddNewProject project={project}></AddNewProject>
                    </div>
                    <div>
                      <Button>Button</Button>
                    </div>
                  </TabPane>
                </TabContent>
                <TabContent activeTab={RightLineTab} className="text-right">
                  {/* Tab 1: poster e screenShoot*/}
                  <TabPane tabId="3">
                    <div>edit Menu</div>
                    <SelectedMenuOrientation
                      project={project}
                    ></SelectedMenuOrientation>
                  </TabPane>
                </TabContent>
              </Col>
              <Col sm="3" className="tabs-responsive-side">
                <Nav className="flex-column border-tab-primary nav-right">
                  <NavItem>
                    <NavLink
                      href="#"
                      className={RightLineTab === "1" ? "active" : ""}
                      onClick={() => setProjectTab("1")}
                      // disabled={!isGlbLoaded}
                    >
                      Project config
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      href="#"
                      className={RightLineTab === "2" ? "active" : ""}
                      onClick={() => setProjectTab("2")}
                      // disabled={!isGlbLoaded}
                    >
                      return to edit Model
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      href="#"
                      className={RightLineTab === "3" ? "active" : ""}
                      onClick={() => setProjectTab("3")}
                      // disabled={!isGlbLoaded}
                    >
                      Menu config
                    </NavLink>
                  </NavItem>
                </Nav>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
}

export default EditProject;
