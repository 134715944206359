import React from "react";
import styles from "./ButtonUpload.module.css";
import ico from "../../assets/ico/icon_export.png";

function ButtonUpload(props) {

  function filesHandler() {
    document.getElementById(`input-${props.id}`).click();
  }

  return (
    <div className={styles.containerButton}>
      <button className={styles.button} onClick={filesHandler}><img className={styles.icon} src={ico}/> {props.text}</button>
      <input id={`input-${props.id}`} className={styles.input} type="file" accept={props.accept} onChange={props.onChange}/>
    </div>
  );
}

export default ButtonUpload;
