import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import ColorPickerContainer from "../component/ui/InputComponent/ColorPickerContainer";
import { modelActions } from "../redux/model";
import { saveColorFactor, rgbaTohex } from "../utility/function/textureChange";

function BaseColor(props) {
  const dispatch = useDispatch();

  const materialsViewer =
    document.querySelector("model-viewer")?.model?.materials;

  const selectedMaterialViewer = materialsViewer?.find(
    (mat) => mat.name === props.materialSelected?.name
  );

  const listOfMaterial = props.listOfMaterial;
  let newMaterialialList = [];

  const [colorGlb, setColorGlb] = useState("#ffffff");

  function convertColor(hex) {
    let c;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
      c = hex.substring(1).split("");
      if (c.length === 3) {
        c = [c[0], c[0], c[1], c[1], c[2], c[2]];
      }
      c = "0x" + c.join("");
      return `${[(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",")},1`;
    }
  }

  function colorHandler(color) {
    setColorGlb(color);

    let color1 = convertColor(color);
    color1 = color1.split(",").map((numberString) => parseFloat(numberString));

    const colordef = [
      color1[0] / 255,
      color1[1] / 255,
      color1[2] / 255,
      selectedMaterialViewer.pbrMetallicRoughness.baseColorFactor[3],
    ];
    // console.log(colordef);

    selectedMaterialViewer.pbrMetallicRoughness.setBaseColorFactor(colordef);
    newMaterialialList = saveColorFactor(
      listOfMaterial,
      selectedMaterialViewer,
      colordef,
      colorGlb
    );

    dispatch(
      modelActions.updateListMaterial({
        idModel: props.modelSelected.id,
        value: newMaterialialList,
      })
    );

    // let a = rgbaTohex(color);
    // props.setColor(colorGlb)
  }

  useEffect(() => {
    const rgba = selectedMaterialViewer.pbrMetallicRoughness.baseColorFactor;
    const newColor = rgbaTohex(rgba);
    setColorGlb(newColor);
  }, [selectedMaterialViewer])

  return (
    <div id={props.materialSelected?.name}>
      <ColorPickerContainer
        id={`baseColorMaterial-${props.materialSelected?.name}`}
        setColor={(color) => colorHandler(color)}
        color={colorGlb}
      />
    </div>
  );
}

export default BaseColor;
