import React from "react";
import ico from "../../assets/ico/icona-cestino-white.svg"
import styles from "./ItemSelectObject.module.css";

function ItemSelectObject(props) {
  
  function select(key) {
    props.onSelectClick(key);
  }

  return (
    <div className={styles.container}>
      <div
        key={props.index}
        className={` ${styles["container-object"]} ${
          props.onActive ? styles["active"] : ""
        }`}
        onClick={() => select(props.index)}
      >
        <span className={styles.title}>
          {props.title}
          {props.onActive}
        </span>
      </div>
      {props.isDeletable && (
        <div className={styles["delete-object"]}>
          <img
            src={ico}
            alt="delete4291645"
            className={styles.icon}
            onClick={props.deletableAction}
          />
        </div>
      )}
    </div>
  );
}

export default ItemSelectObject;
