import React, { useState } from "react";
import ReactTooltip from "react-tooltip";
import icoArrow from "../../assets/ico/icona-info-freccia.svg";
import icoArrowClose from "../../assets/ico/icona-freccia2.png";
import icoCircle from "../../assets/ico/icona-info-circle.svg";
import icoInfo from "../../assets/ico/icona-info.svg";
import styles from "./TabContainer.module.css";

function TabContainer(props) {
  const [close, setClose] = useState(false);

  return (
    <div style={props.disabled ? {} : {display: "none"}}>
      <div
        className={`${styles["sottocategorie29203"]} ${
          close ? styles["background-fade-out"] : ""
        }`}
      >
        <div className={styles["Frame1boxautorotate1783"]}>
          <div className={styles["titlecheckboxrotate1257"]}>
          <div
            onClick={() => {
              setClose(!close);
            }}
          >
            <img
              className={styles["image3"]}
              id={props.id}
              src={
                !close
                  ? icoArrow : icoArrowClose
              }
              alt="freccia22629"
            />
          </div>
            <span className={styles["text"]}>{props.title}</span>
            <div
              className={styles["iconinfo522241"]}
              data-tip
              data-for={`info-tip-${props.id}`}
            >
              <img
                alt="Ellipse54522242"
                src={icoCircle}
                className={styles["svg"]}
              />
              <img
                src={icoInfo}
                alt="icon_info522243"
                className={styles["image2"]}
              />
            </div>
            <ReactTooltip
              id={`info-tip-${props.id}`}
              place="bottom"
              effect="float"
            >
              {props.tooltip}
            </ReactTooltip>
          </div>
          <div
            className={`${styles["autorotate522251"]} ${
              close && styles["fade-out"]
            }`}
          >
            {props.children}
          </div>
        </div>
      </div>
    </div>
  );
}

export default TabContainer;
