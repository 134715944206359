import React, { useState, useRef, } from "react";
import { ChromePicker } from "react-color";
import { HexColorPicker, HexColorInput } from "react-colorful";
import BackDrop from "../modal/BackDrop";
import styles from "./InputImage.module.css";

function ColorPickerContainer(props) {
  // const popover = useRef();

  const isBorderPic = props.isBorder;
  const [display, setDisplay] = useState(false);

  const presetColors = ["#b81d1d", "#ffffff"];

  function handlerPopUp() {
    setDisplay(!display);
  }

  return (
    <div className={styles["row-element"]}>
      <div
        id={props.id}
        style={{
          backgroundColor: `${props.color}`,
          width: "60px",
          height: "60px",
          borderRadius: "13px",
          padding: "5px",
        }}
        onClick={() => handlerPopUp()}
      >
        {isBorderPic && <div className="isBorder"></div>}
      </div>

      {display && (
        <div className={`popup`}>
          <div className={`custom-layout`}>
            <HexColorPicker color={props.color} onChange={props.setColor} />
            #<HexColorInput color={props.color} onChange={props.setColor} />
            <div className="picker__swatches">
              {presetColors.map((presetColor) => (
                <button
                  key={presetColor}
                  className="picker__swatch"
                  style={{ background: presetColor }}
                  onClick={() => props.setColor(presetColor)}
                />
              ))}
            </div>
          </div>
        </div>
      )}
      {display && (
        <BackDrop onCancel={() => handlerPopUp()} trasparent={true} />
      )}
      {!display && (
        <div className={`picker1`}>
          <div className={`picker1`}>
            <HexColorPicker color={props.color} onChange={props.setColor} />
            <output>{props.color}</output>
          </div>
        </div>
      )}
    </div>
  );
}

export default ColorPickerContainer;
