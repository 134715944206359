import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { firestore } from "../utility/firebase";
import { useDispatch, useSelector } from "react-redux";
import { modelActionsProject } from "../redux/project";
import { modelActions } from "../redux/model";
import { storage } from "../utility/firebase";
import styles from "../assets/css/Viewer3d.module.css";
import { ThreeSixtyViewer } from "react-threesixty";
import ModelContainerFrontEnd from "../component/ui/ModelContainerFrontEnd";
import ImpostazioniProgetto from "../actions/ImpostazioniProgetto";
import ModelContainer from "../component/ui/ModelContainer";
import ProjectModelViewer from "../component/viewer/ProjectModelViewer";
import CardModelView from "../component/viewer/CardModelView";
import MenuContainer from "../component/viewer/MenuContainer";
import Styles from "../assets/css/NewProject.module.css";

function Viewer3d() {
  const params = useParams();
  const dispatch = useDispatch();

  let selectedModel = null;

  const [model, setModel] = useState();
  const [loaded, setLoaded] = useState(false);
  const [storageSrc, setStorageSrc] = useState();
  const [display360, setDisplay360] = useState(false);
  const [displayModelViewer, setDisplayModelViewer] = useState(false);

  const idConfig = useSelector((state) =>
    state.config.find((config) => config.id === "general")
  );

  const arButton = idConfig.qrCode;

  const project = useSelector((state) =>
    state.project.find((project) => project.id === params.token)
  );

  const modelSelected = useSelector((state) =>
    state.model.find((model) => model.id === project.selected_model)
  );

  const modelInView = useSelector((state) =>
    state.model.find((model) => model.id === project?.selected_model_view)
  );

  const MODEL_INSTANCE = {
    src: modelSelected?.src,
    alt: modelSelected?.alt,
    cameraOrbit: modelSelected?.cameraOrbit,
    cameraControls: modelSelected?.cameraControls,
    autoRotate: modelSelected?.autoRotate,
    disableZoom: modelSelected?.disableZoom,
    lightExposition: modelSelected?.lightExposition,
    shadowSmooth: modelSelected?.shadowSmooth,
    shadowIntensity: modelSelected?.shadowIntensity,
    rotationPerSecond: modelSelected?.speedRotate,
    animationName: modelSelected?.animationName,
    poster: modelSelected?.poster,
    ios: modelSelected?.ios,
    numberS: modelSelected?.setNumberStep,
    scale: modelSelected?.scale,
    text: modelSelected?.text,
    hdr: modelSelected?.hdr,
    skybox: modelSelected?.skybox,
  };

  function camera() {
    if (MODEL_INSTANCE.cameraOrbit === null) {
      MODEL_INSTANCE.cameraOrbit = "0deg 0deg 101%";
    }
    return MODEL_INSTANCE.cameraOrbit;
  }

  function castValues(value) {
    return !value ? null : value;
  }

  function selecteModelInView(project, modelId) {
    console.log("select");
    dispatch(
      modelActionsProject.setSelectedModelInView({
        idProject: project.id,
        value: modelId,
      })
    );
  }

  useEffect(async () => {
    const docRef = firestore.collection("Progetti").doc(params.token);
    await docRef.get().then((doc) => {
      const progetto = doc.data();
      dispatch(
        modelActionsProject.addNewProject({
          idProject: progetto?.id,
          value: progetto,
          formatDate: progetto?.creation_date,
        })
      );
      selectedModel = progetto?.selected_model_view;
    });
    await docRef
      .collection("Model")
      .get()
      .then((snap) => {
        snap.forEach((model) => {
          const m = model.data();
          dispatch(modelActions.setNewModel({ idModel: m.id, value: m }));
        });
      });
    setLoaded(true);
  }, []);

  function readArrayImg(storageRef360) {
    let array = [];
    for (let i = 0; i < 143; i++) {
      storageRef360
        .child(`pic-${i}.png`)
        .getDownloadURL()
        .then(async (url) => {
          const fetchUrl = await fetch(url);
          const blob = await fetchUrl.blob();
          const url1 = URL.createObjectURL(blob);
          array[i] = { id: i, uri: url1, order: i + 1 };
        });
    }
    return array;
  }

  const [imageArray, setImageArray] = useState([]);

  let ar = [];

  // useEffect(async () => {
  //   if (model) {
  //     let storageRefFile = await storage.ref(
  //       `Progetti/${params.token}/${model.id}/new-model-${model.id}.glb`
  //     );

  //     const url = await storageRefFile.getDownloadURL();

  //     setStorageSrc(url);

  //     document.getElementById("front-c").style.background =
  //       model.background_color;

  //     let storageRef360 = await storage.ref(
  //       `Progetti/${params.token}/${model.id}/f360/`
  //     );

  //     ar = readArrayImg(storageRef360);
  //     console.log({ ar });
  //     setImageArray(ar);
  //   }

  //   setDisplayModelViewer(true);
  //   displayComponent360();
  // }, [loaded]);

  function displayComponent360() {
    setTimeout(() => {
      setDisplay360(!display360);
    }, 4500);
  }

  return (
    // <div className={styles.container} id="front-c">
      <div>
        {!display360 && (
          <ModelContainerFrontEnd
            modelSelected={modelInView}
          >
            {project?.menu_position === "top" && (
              <MenuContainer style={"TOP"} menu={project.menu_type}>
                {project?.model_list.map((modelIdCard, i) => (
                  <CardModelView
                    key={i}
                    modelId={modelIdCard}
                    selected={project.selected_model_view}
                    project={project}
                    selectModel={() => selecteModelInView(project, modelIdCard)}
                    cardStyle={project?.menu_type}
                  ></CardModelView>
                ))}
              </MenuContainer>
            )}
            {project?.menu_position === "left" && (
              <MenuContainer style={"LEFT"} menu={project.menu_type}>
                {project?.model_list.map((modelIdCard, i) => (
                  <CardModelView
                    key={i}
                    modelId={modelIdCard}
                    selected={project.selected_model_view}
                    project={project}
                    selectModel={() => selecteModelInView(project, modelIdCard)}
                    cardStyle={project?.menu_type}
                  ></CardModelView>
                ))}
              </MenuContainer>
            )}
            { ( (!navigator.userAgent.match(/FBAV/i) ) ) && (

            <ProjectModelViewer
              project={project}
              viewerPage
              paramsId={params.token}
            ></ProjectModelViewer>
            )}
              { (navigator.userAgent.match(/FBAV/i)  && (  !navigator.userAgent.match(/(iPod|iPhone|iPad)/) )) && ( 
                  <div id={
                    project?.menu_position === "left" ||
                    project?.menu_position === "right"
                      ? "projectTwo"
                      : "project"
                  }>
                      <img src={modelInView?.not_supported_image} style={{width:"100%"}}></img>
                      il browser di facebook non supporta modelli 3D e realtà aumentata. 
                      prova con un altro broswer
                  </div>
              )}
            {project?.menu_position === "bottom" && (
              <MenuContainer style={"BOTTOM"} menu={project.menu_type}>
                {project?.model_list.map((modelIdCard, i) => (
                  <CardModelView
                    key={i}
                    modelId={modelIdCard}
                    selected={project.selected_model_view}
                    project={project}
                    selectModel={() => selecteModelInView(project, modelIdCard)}
                    cardStyle={project?.menu_type}
                  ></CardModelView>
                ))}
              </MenuContainer>
            )}

            {project?.menu_position === "right" && (
              <MenuContainer style={"RIGHT"} menu={project.menu_type}>
                {project?.model_list.map((modelIdCard, i) => (
                  <CardModelView
                    key={i}
                    modelId={modelIdCard}
                    selected={project.selected_model_view}
                    project={project}
                    selectModel={() => selecteModelInView(project, modelIdCard)}
                    cardStyle={project?.menu_type}
                  ></CardModelView>
                ))}
              </MenuContainer>
            )}
          </ModelContainerFrontEnd>
          // <ModelContainer viewerPage modelSelected={modelInView}>
          //   <ProjectModelViewer paramsId={params.token} viewerPage project={project}></ProjectModelViewer>

          //   <div
          //     className={
          //       project?.menu_type === "plane"
          //         ? Styles["wrapper-classic"]
          //         : Styles["row-wrapp"]
          //     }
          //   >
          //     <div className="fixedPowered"> Powered By Spaarkly</div>
          //     {project?.model_list?.map((modelIdCard, i) => (
          //       <CardModelView
          //         key={i}
          //         modelId={modelIdCard}
          //         selected={project.selected_model_view}
          //         project={project}
          //         selectModel={() => {
          //           selecteModelInView(project, modelIdCard);
          //         }}
          //         cardStyle={project?.menu_type}
          //       ></CardModelView>
          //     ))}
          //   </div>
          // </ModelContainer>
        )}
      </div>
    // </div>
  );
}

export default Viewer3d;
