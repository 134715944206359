import React from "react";
import styles from "./Modal.module.css";

function Modal(props) {
  return (
    <div className={props.right ? styles["modalTwo"] : styles["modal"]}>
      <div className={styles["modal-dialog"]}>
        <div className={styles["modal-body"]}>{props.children}</div>
      </div>
    </div>
  );
}

export default Modal;
