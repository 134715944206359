
import React,{useState} from "react";
import { Link } from "react-router-dom";
import { useAuth } from "../utility/AuthContext";

function ActivateAccount() {
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState('');
  const [msg, setMsg] = useState('');
  const {currentUser} = useAuth();

  async function sendVerification() {
    setError('');
    try{
      setIsLoading(true);
      await currentUser.sendEmailVerification();
      setMsg('Verification email sent, check the bin');
    } catch{
      setError('Cannot send verification email');
      return error;
    }
    setIsLoading(false);
  }

  return (
    <React.Fragment>
      <h1>Activate account</h1>
      {error && <p className="centered">{error}</p>}
      {msg && <p className="centered">{msg}</p>}
      <section >
        <div >
          <p>
            We send you a verification email. If you didnt receive it click in
            the button below
          </p>
        </div>
        <div>
          <button disabled={isLoading} onClick={sendVerification}>Resend verification email</button>
        </div>
        <div>
          <Link to="/login">Logout</Link>
        </div>
      </section>
    </React.Fragment>
  );
}

export default ActivateAccount;
