import React, {useState} from "react";
import { useDispatch, useSelector } from "react-redux";
import { modelActions } from "../redux/model";
import { Label, Button } from "reactstrap";
import SubtitleTab from "../component/ui/SubtitleTab";
import ButtonUpload from "../component/ui/ButtonUpload";
import ColorPickerContainer from "../component/ui/InputComponent/ColorPickerContainer";
import InputImageRow from "../component/ui/InputComponent/InputImageRow";
import {storage} from '../utility/firebase';

function BackGroundEdit(props) {
  const dispatch = useDispatch();

  const [color, setColor] = useState("#000000");
  const [imageBackground, setImageBackground] = useState(props.modelSelected?.imageBackground ? props.modelSelected?.imageBackground: "../static/media/not-texture.43cab7c5.png" );

  function backgroundColorHandler(color) {
    setColor(color)
    dispatch(
      modelActions.setBackgroundColor({
        idModel: props.modelSelected.id,
        value: color,
      })
    );
  }
  const idConfig = useSelector((state) =>
    state.config.find((config) => config.id === "general")
  );

  const project = useSelector((state) =>
    state.project.find((project) => project.id === idConfig.projectOnEdit)
  );

  async function changeBackground(event) {
    //url
    // let storageRefFile = storage.ref(
    //   `Progetti/${project.id}/${props.modelSelected.id}/bg-image/bg-${props.modelSelected.id}`
    // );
    const file = event.target.files[0];
    const responseSrc = await fetch(URL.createObjectURL(file));
    const blobSrc = await responseSrc.blob();
    // const urlBlb = await storageRefFile.put(blobSrc).then((snapshot) => {
    //   return storageRefFile.getDownloadURL();
    // });

    // console.log({urlBlb})
    // const url = URL.createObjectURL(event.target.files[0]);
    dispatch(
      modelActions.setBackgroundBooleanImage({
        idModel: props.modelSelected.id,
        value: true,
      })
    );
    dispatch(
      modelActions.setBackgroundImage({
        idModel: props.modelSelected.id,
        value: URL.createObjectURL(blobSrc),
      })
    );
    setImageBackground(blobSrc);
  }

  function removeImg() {
    dispatch(
      modelActions.setBackgroundBooleanImage({
        idModel: props.modelSelected.id,
        value: false,
      })
    );
    setImageBackground("../static/media/not-texture.43cab7c5.png");
    //dispatch(modelActions.setBackgroundImage(null));
  }

  return (
    <div>
      <SubtitleTab title="Color Backgroung" />
      
      <ColorPickerContainer setColor={(color)=>backgroundColorHandler(color)} color={color}/>

      <div>
        <SubtitleTab title="Carica immgine background" />
       
        <InputImageRow key={"background"} text="Image" image={imageBackground} onChange={changeBackground} accept="image/*" remove={true} restore={false} removeTexture={removeImg}></InputImageRow>
      </div>
    
    </div>
  );
}

export default BackGroundEdit;
