import React, { Fragment, useState, useEffect } from "react";
import Breadcrumb from "../common/breadcrumb/breadcrumb";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  Label,
  Input,
} from "reactstrap";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { Button } from "react-bootstrap";
import model, { modelActions } from "../../redux/model";
import { nanoid } from "@reduxjs/toolkit";
import { modelActionsProject } from "../../redux/project";
import { firestore } from "../../utility/firebase";
import { USER } from "../../model/user";
import Cookies from "js-cookie";
import { useAuth } from "../../utility/AuthContext";
import { generalActions } from "../../redux/generalConfig";
import {
  createFirebaseProjectRef,
  createFirebaseModelRef,
} from "../../utility/function/firebaseFunction";
import ProjectHeader from "../ui/ProjectHeader";
import styles from "./dashboardProject.module.css";
import AddFirstProjectDashboard from "../ui/AddFirstProjectDashboard";
import ProjectDashBoardCard from "../ui/ProjectDashBoardCard";
import icoPlus from "../../assets/ico/ico1.png";

const Sample = (props) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { currentUser } = useAuth();

  const defaultModel = useSelector((state) =>
    state.defaultModel.find((model) => model.id === "defoult")
  );

  const [isModalOpen, setIsModalOpen] = useState(false);

  const userRegisterData = useSelector((state) => state.auth.userRegisterData);

  const projectList = useSelector((state) => state.project);

  const defaultProject = useSelector((state) =>
    state.defaultProject.find((project) => project.id === "default")
  );

  const firebaseDataLoad = useSelector((state) =>
    state.config.find((general) => general.id === "general")
  );

  const [isFirstProject, setFirstProject] = useState(false);

  function duplicateDefault(idProject) {
    const idModel = createFirebaseModelRef();

    dispatch(
      modelActions.setNewModel({ idModel: idModel, value: defaultModel })
    );
    dispatch(
      modelActionsProject.addModelToList({
        idProject: idProject,
        idModel: idModel,
      })
    );
    dispatch(
      modelActionsProject.setSelectedModel({
        idProject: idProject,
        value: idModel,
      })
    );
    dispatch(
      modelActionsProject.setSelectedModelInView({
        idProject: idProject,
        value: idModel,
      })
    );
  }

  function NewProjectHandler() {
    dispatch(modelActions.reset());

    let idProject;
    try {
      idProject = createFirebaseProjectRef();
    } catch (e) {
      return;
    }
    let date = Date.now();
    let formatDate = new Intl.DateTimeFormat("en-US", {
      year: "numeric",
      month: "2-digit",
      day: "2-digit",
      hour: "2-digit",
      minute: "2-digit",
    }).format(date);
    dispatch(
      modelActionsProject.addNewProject({
        idProject: idProject,
        value: defaultProject,
        formatDate: formatDate,
      })
    );

    duplicateDefault(idProject);
    dispatch(
      generalActions.setProjectOnEdit({ idConfig: "general", value: idProject })
    );

    history.push(`/dashboard/edit-model`);

    // setIsModalOpen(true);
  }

  const userLogInfo = useSelector((state) => state.auth.userLogData);

  const [projects, serProjects] = useState([]);
  const userEmail = Cookies.get("email");

  useEffect(async () => {
    if (!sessionStorage.getItem("userLog")) {
      sessionStorage.setItem("userLog", {
        data: new Date(),
        navigator: navigator.userAgent,
      });
    }

    if (!firebaseDataLoad.isDataProjectIsLoad) {
      firestore
        .collection("Account")
        .doc(currentUser.email)
        .get()
        .then((user) => {
          user.data().project_list.map((project) => {
            // console.log(project);
            firestore
              .collection("Progetti")
              .doc(project)
              .get()
              .then((data) => {
                projects.push(data.data());
                // console.log("data:", data.creation_date);
                dispatch(
                  modelActionsProject.addNewProject({
                    idProject: data.id,
                    value: data.data(),
                    formatDate: data.data().creation_date,
                  })
                );
                setFirstProject(false);
              })
              .catch((error) => {
                console.log("no data found");
                setFirstProject(true);
              });
          });

          dispatch(
            generalActions.setFirebaseDataLoad({
              idConfig: "general",
              value: true,
            })
          );
        });
    }
  }, []);

  const [isModalRenameOpen, setIsModalRenameOpen] = useState(false);

  function renameHandle(idProject) {
    setIsModalRenameOpen(true);
  }

  function deleteHandle(idProject) {
    console.log("delete");
  }

  function duplicateHandle(idProject) {
    console.log("duplica");
  }

  async function loadProjectHandle(idProject) {
    await firestore
      .collection("Progetti")
      .doc(idProject)
      .collection("Model")
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((model) => {
          dispatch(
            modelActions.setNewModel({ idModel: model.id, value: model.data() })
          );
        });
      })
      .catch((error) => {
        console.log("errore", error);
      })
      .finally((complete) => {
        dispatch(
          generalActions.setProjectOnEdit({
            idConfig: "general",
            value: idProject,
          })
        );
        setTimeout(() => {
          history.push(`/dashboard/edit-model`);
        }, 500);
      });
  }

  return (
    <Fragment>
      <ProjectHeader />
      <Container fluid={true}>
        <Row>
          <Col sm="11" className={styles.projectListContainer}>
            {isFirstProject && (
              <div>
                <div className={styles.titoloProgetto}>
                  <p className={styles.txt1064}>Projects </p>
                </div>
                <p className="txt-891">
                  CLICCA IN BASSO PER AGGIUNGERE UN NUOVO MODELLO 3D
                </p>
                <AddFirstProjectDashboard>
                  <img
                    src={icoPlus}
                    alt="image not found"
                    className={styles.plus}
                    onClick={NewProjectHandler}
                  />
                </AddFirstProjectDashboard>
              </div>
            )}
            {!isFirstProject && (
              <div>
                <div className={styles.titoloProgetto}>
                  <p className={styles.txt1064}>Projects </p>
                </div>
                <p className="txt-891">SELEZIONA IL PROGETTO</p>

                <div className={styles.flexGrid}>
                  <AddFirstProjectDashboard>
                    <img
                      src={icoPlus}
                      alt="image not found"
                      className={styles.plus}
                      onClick={NewProjectHandler}
                    />
                  </AddFirstProjectDashboard>

                  {projectList.map((project, i) => (
                    <div key={i}>
                      <ProjectDashBoardCard
                        project={project}
                        date={project.creation_date}
                        poster={project.poster}
                        title={project.title}
                        onClickBackground={() => loadProjectHandle(project.id)}
                        onClickRename={() => renameHandle(project.id)}
                        onClickDelete={() => deleteHandle(project.id)}
                        onClickEdit={() => loadProjectHandle(project.id)}
                        onCLickDuplicate={() => duplicateHandle(project.id)}
                      ></ProjectDashBoardCard>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </Col>
          <Col sm="1"></Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default Sample;
