import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { modelActions } from "../redux/model";
import { useTranslation } from "react-i18next";
import {Label} from 'reactstrap';
import SubtitleTab from "../component/ui/SubtitleTab";
import InputSliderRow from "../component/ui/InputComponent/InputSliderRow";

function ShadowIntensity(props) {

  const { t } = useTranslation();

  const dispatch = useDispatch();


  function shadowHandler(event) {
    dispatch(modelActions.setShadowIntensity({idModel:props.modelSelected.id,value:event.target.value}));
  }

  return (
    <div>
      <SubtitleTab title="Intensita" />
      {/* <Label for="range2">{t("tab1_shadow")}</Label> */}
    
        <InputSliderRow  id={"shadow"}
          maxValue={10}
          minValue={0}
          step={1}
          value={props.modelSelected?.shadowIntensity}
          onChange={shadowHandler}></InputSliderRow>
    </div>
  );
}

export default ShadowIntensity;
