import React from "react";
import styles from "./ModelContainer.module.css";
import CardModelView from "../viewer/CardModelView";

function ModelContainer(props) {
  let project = props.project;

  return (
    <div className={styles["canvas29985"]}>
      <div className={props.viewerPage ? styles["groupViewePage"] : styles["Group14291011"]}>
        <div
          style={
            !props.modelSelected?.background_boolean_image
              ? {
                  backgroundColor: `${props.modelSelected?.background_color}`,
                }
              : {
                  backgroundImage: `url(${props.modelSelected?.background_image})`,
                  backgroundColor: `${props.modelSelected?.background_color}`,
                }
          }
          alt="Rectangle11291012"
          className={
            props.project?.menu_position === "left" ||
            props.project?.menu_position === "right"
              ? styles["imageColum"]
              : styles["image"]
          }
        >
          <div className="fixedPowered"> Powered By Spaarkly</div>

          {/* {project?.menu_position === 'left' && (
            <div
              className={
                project?.menu_type === 'plane'
                  ? styles['wrapper-classic']
                  : styles['row-wrapp']
              }
            >
              {project?.model_list.map((modelIdCard, i) => (
                <CardModelView
                  key={i}
                  modelId={modelIdCard}
                  selected={project.selected_model_view}
                  project={project}
                  selectModel={() => {
                    props.selecteModelInView(project, modelIdCard)
                  }}
                  cardStyle={project?.menu_type}
                ></CardModelView>
              ))}
            </div>
          )} */}

          {props.children}

          {/* {project?.menu_position === 'bottom' && (
            <div
              className={
                project?.menu_type === 'plane'
                  ? styles['wrapper-classic']
                  : styles['row-wrapp']
              }
            >
              {project?.model_list.map((modelIdCard, i) => (
                <CardModelView
                  key={i}
                  modelId={modelIdCard}
                  selected={project.selected_model_view}
                  project={project}
                  selectModel={() => {
                    props.selecteModelInView(project, modelIdCard)
                  }}
                  cardStyle={project?.menu_type}
                ></CardModelView>
              ))}
            </div>
          )} */}
        </div>
      </div>
    </div>
  );
}

export default ModelContainer;
