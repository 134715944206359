export let USER = {
  // name: null,
  // lastName: null,
  // email: null,
  // subscriptionDate: null,
  // isCompany: null,
  // vat: null,
  // companyName: null,
  // address: {
  //   country: null,
  //   city: null,
  //   address: null,
  //   postalCode: null,
  // },
  // lastLogin: null,
};
