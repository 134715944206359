import React, { useEffect } from "react";
import logoAr from "../../assets/images/AR.png";
import BackDropTransparent from "./modal/BackDropTransparent";
import ModalQR from "./modal/ModalQR";
import { useSelector, useDispatch } from "react-redux";
import { generalActions } from "../../redux/generalConfig";
import { modelActionsProject } from "../../redux/project";
import styles from "./ButtonAr.module.css";
import { isMobile } from "react-device-detect";


function ButtonAr(props) {
  const dispatch = useDispatch();

  const idConfig = useSelector((state) =>
    state.config.find((config) => config.id === "general")
  );

  const bgColor = idConfig.qrBackGroundColor;
  const fgColor = idConfig.qrForeGroundColor;

  let project = useSelector((state) =>
    state.project.find((project) => project.id === idConfig.projectOnEdit)
  );

  function closeModal() {
    dispatch(generalActions.setModalQr({idConfig: "general"}));
    // dispatch(modelActionsProject.setModalQr({idProject: project.id}));
  }

  return (
    // <div style={{ position: "fixed", right: "20", bottom: "20" }}>
    <>
      {isMobile && (
        <button slot="ar-button" className={props.project?.arPosition === "TOP" ? styles.buttonAR : styles.buttonARBottom } >
        <img src={logoAr} style={{ width: "30px", height: "30px" }} alt="ar" />
      </button>
      )}
      {!isMobile && (
        <div className={props.project?.arPosition === "TOP" ? styles["button-right-top"] : styles["button-right-bottom"] }>
      <button className={styles.button}  onClick={props.onClick}>
        <img src={logoAr} style={{ width: "40px", height: "40px" }} alt="ar" />
      </button>
     
      {props.isModal && <ModalQR fgColor={fgColor} bgColor={bgColor} project={props.project}/>}
      {props.isModal && <BackDropTransparent onCancel={closeModal} />}
    </div>
     )}
     </>
  );
}

export default ButtonAr;
