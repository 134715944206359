import React, { createRef, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
// import { useTranslation } from "react-i18next";
import { modelActions } from "../redux/model";
import { Label } from "reactstrap";
import styles from "../assets/css/OutputSlider.module.css";
import InputSliderRow from "../component/ui/InputComponent/InputSliderRow";

function Roughness(props) {
  const roughnessRef = createRef();
  const dispatch = useDispatch();

  const newMaterialList = [];

  const listOfMaterial = props.modelSelected.list_material_two;

  const materialsViewer =
    document.querySelector("model-viewer").model?.materials;

  const selectedMaterialViewer = materialsViewer?.find(
    (mat) => mat.name === props.materialSelected?.name
  );

  const [value, setValue] = useState(props.materialSelected?.roughnessFactor);

  function roughnessHandler(event) {
    setValue(event.target.value);

    selectedMaterialViewer.pbrMetallicRoughness.setRoughnessFactor(value);

    listOfMaterial.map((material) => {
      if (material.name === props.materialSelected.name) {
        let a = {
          name: material.name,
          metallicFactor: material.metallicFactor,
          roughnessFactor: value,
          baseColorFactor: material.baseColorFactor,
          baseColorImage: material.baseColorImage,
          metallicRoughnessImage: material.metallicRoughnessImage,
          emissiveImage: material.emissiveImage,
          normalImage: material.normalImage,
          occlusionImage: material.occlusionImage,
        };
        newMaterialList.push(a);
      } else {
        let b = {
          name: material.name,
          metallicFactor: material.metallicFactor,
          roughnessFactor: material.roughnessFactor,
          baseColorFactor: material.baseColorFactor,
          baseColorImage: material.baseColorImage,
          metallicRoughnessImage: material.metallicRoughnessImage,
          emissiveImage: material.emissiveImage,
          normalImage: material.normalImage,
          occlusionImage: material.occlusionImage,
        };
        newMaterialList.push(b);
      }
    });

    dispatch(
      modelActions.updateListMaterial({
        idModel: props.modelSelected.id,
        value: newMaterialList,
      })
    );
  }

  useEffect(() => {
    setValue(props.materialSelected?.roughnessFactor)
  },[props.materialSelected.name])

  return (
    <div>
      <div style={{ display: "flex" }}>
        <InputSliderRow
          id="roughness"
          // ref={roughnessRef}
          maxValue={1}
          minValue={0}
          step={0.1}
          value={value}
          onChange={roughnessHandler}
        ></InputSliderRow>
      </div>
    </div>
  );
}

export default Roughness;
