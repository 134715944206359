import React, { useState, useEffect } from "react";
// import { Label, Button } from "reactstrap";
import { useSelector, useDispatch } from "react-redux";
import { modelActions } from "../redux/model";

function Textures(props) {
  const dispatch = useDispatch();

  const loaded = props.isGlbLoaded;

   //const [imgs, setImgs] = useState([]);
  // const modelMat = useSelector((state) => state.model.model_material);
  // const selectedMaterial = useSelector((state) => state.model.selected_material);

  const THUMBNAIL_SIZE = 256;
  function getModelViewer() {
    return props.modelViewerRef.current;
  }
  function getTextureId(gltfImage) {
    var _a;
    return (_a = gltfImage.uri) !== null && _a !== void 0
      ? _a
      : gltfImage.bufferView.toString();
  }

  async function pushThumbnail(thumbnailsById, textureInfo) {
    const { texture } = textureInfo;
    if (texture == null) {
      return null;
    }
    const { source } = texture;
    const id = getTextureId(source);
    if (!thumbnailsById.has(id)) {
      thumbnailsById.set(id, {
        objectUrl: await source.createThumbnail(THUMBNAIL_SIZE, THUMBNAIL_SIZE),
        texture,
      });
    }
    return id;
  }

  async function createThumbnails() {
    var _a;
    const thumbnailsById = new Map();
    if (props.modelViewerRef.current.model) {
      for (const material of (_a = props.modelViewerRef.current.model) ===
        null || _a === void 0
        ? void 0
        : _a.materials) {
        const {
          pbrMetallicRoughness,
          normalTexture,
          emissiveTexture,
          occlusionTexture,
        } = material;
        const { baseColorTexture, metallicRoughnessTexture } =
          pbrMetallicRoughness;
        await pushThumbnail(thumbnailsById, normalTexture);
        await pushThumbnail(thumbnailsById, emissiveTexture);
        await pushThumbnail(thumbnailsById, occlusionTexture);
        await pushThumbnail(thumbnailsById, baseColorTexture);
        await pushThumbnail(thumbnailsById, metallicRoughnessTexture);
      }
    }
    return thumbnailsById;
  }

  async function GenerateThumbs() {
    const thumbnailsById = await createThumbnails();
    let pics = [];
    thumbnailsById.forEach((thumb) => {
      pics.push(thumb.objectUrl);
      // console.log('thumb: ',thumb.texture);
    });
    dispatch(modelActions.setListTexture({idModel: props.modelSelected.id, value:pics}));
    // setImgs(pics);
  }

  useEffect(() => {
    if (loaded) {
      // GenerateThumbs();
      const listTexture = [];
      listTexture.push(document.querySelector('model-viewer').model?.selected_material?.pbrMetallicRoughness.baseColorTexture.texture);
      listTexture.push(document.querySelector('model-viewer').model?.selected_material?.pbrMetallicRoughness.metallicRoughnessTexture.texture);
      listTexture.push(document.querySelector('model-viewer').model?.selected_material?.emissiveTexture.texture);
      listTexture.push(document.querySelector('model-viewer').model?.selected_material?.normalTexture.texture);
      listTexture.push(document.querySelector('model-viewer').model?.selected_material?.occlusionTexture.texture);
      //dispatch(modelActions.setListTexture({idModel: props.modelSelected.id, value: listTexture}));

    }
    //todo fix that warning
  }, [loaded, props.modelViewerRef.current]);

  return (
    <React.Fragment>
    </React.Fragment>
  );
}

export default Textures;
