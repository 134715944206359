import { createSlice } from "@reduxjs/toolkit";

const initialState = [];

const authSlice = createSlice({
  name: "model",
  initialState,
  reducers: {
    setNewModel(state, action) {
      const { idModel, value } = action.payload;
      const newModel = { ...value, id: idModel };
      state.push(newModel);
    },
    setOriginalModel(state,action){
      const { idModel, value } = action.payload;
      const selectModel = state.find((model) => model.id === idModel);
      if (selectModel) {
        selectModel.original_model = value;
      }
    },
    removeModelList(state, action) {
      const { index } = action.payload;
      if (index === 0) {
        state.splice(index, 1);
      } else {
        state.splice(index, index);
      }
    },
    setTitle(state, action) {
      const { idModel, value } = action.payload;
      const selectModel = state.find((model) => model.id === idModel);
      if (selectModel) {
        selectModel.title = value;
      }
    },
    setSrc(state, action) {
      const { idModel, src } = action.payload;
      const selectModel = state.find((model) => model.id === idModel);
      if (selectModel) {
        selectModel.src = src;
      }
    },
    setPosterImage(state, action) {
      const { idModel, value } = action.payload;
      const selectModel = state.find((model) => model.id === idModel);
      if (selectModel) {
        selectModel.poster_image = value;
      }
    },
    setBackgroundColor(state, action) {
      const { idModel, value } = action.payload;
      const selectModel = state.find((model) => model.id === idModel);
      if (selectModel) {
        selectModel.background_color = value;
      }
    },
    setBackgroundImage(state, action) {
      const { idModel, value } = action.payload;
      const selectModel = state.find((model) => model.id === idModel);
      if (selectModel) {
        selectModel.background_image = value;
      }
    },
    setBackgroundBooleanImage(state, action) {
      const { idModel, value } = action.payload;
      const selectModel = state.find((model) => model.id === idModel);
      if (selectModel) {
        selectModel.background_boolean_image = value;
      }
    },
    setListTexture(state, action) {
      const { idModel, value } = action.payload;
      const selectModel = state.find((model) => model.id === idModel);
      if (selectModel) {
        selectModel.list_texture = value;
      }
    },
    setLightExposition(state, action) {
      const { idModel, value } = action.payload;
      const selectModel = state.find((model) => model.id === idModel);
      if (selectModel) {
        selectModel.lightExposition = value;
      }
    },
    setShadowIntensity(state, action) {
      const { idModel, value } = action.payload;
      const selectModel = state.find((model) => model.id === idModel);
      if (selectModel) {
        selectModel.shadowIntensity = value;
      }
    },
    setShadowSmooth(state, action) {
      const { idModel, value } = action.payload;
      const selectModel = state.find((model) => model.id === idModel);
      if (selectModel) {
        selectModel.shadowSmooth = value;
      }
    },
    setListMaterial(state, action) {
      const { idModel, value } = action.payload;
      const selectModel = state.find((model) => model.id === idModel);
      if (selectModel) {
        selectModel.list_material_initial = value;
      }
    },
    setListMaterialTwo(state, action) {
      const { idModel, value, image, imageMR, imageEM, imageNR, imageOC } =
        action.payload;
      const selectModel = state.find((model) => model.id === idModel);
      if (selectModel) {
        const material = {
          name: value.name,
          metallicFactor: value.pbrMetallicRoughness.metallicFactor,
          roughnessFactor: value.pbrMetallicRoughness.roughnessFactor,
          baseColorFactor: value.pbrMetallicRoughness.baseColorFactor,
          emissiveFactor: value.emissiveFactor,
          baseColorImage: image,
          metallicRoughnessImage: imageMR,
          emissiveImage: imageEM,
          normalImage: imageNR,
          occlusionImage: imageOC,
        };
        selectModel.list_material_two.push(material);
      }
    },
    setListMaterialInitial(state, action) {
      const { idModel, value, image, imageMR, imageEM, imageNR, imageOC } =
        action.payload;
      const selectModel = state.find((model) => model.id === idModel);
      if (selectModel) {
        const material = {
          name: value.name,
          metallicFactor: value.pbrMetallicRoughness.metallicFactor,
          roughnessFactor: value.pbrMetallicRoughness.roughnessFactor,
          baseColorFactor: value.pbrMetallicRoughness.baseColorFactor,
          emissiveFactor: value.emissiveFactor,
          baseColorImage: image,
          metallicRoughnessImage: imageMR,
          emissiveImage: imageEM,
          normalImage: imageNR,
          occlusionImage: imageOC,
        };
        selectModel.list_material_initial.push(material);
      }
    },
    updateListMaterial(state, action) {
      const { idModel, value } = action.payload;
      const selectModel = state.find((model) => model.id === idModel);
      if (selectModel) {
        selectModel.list_material_two = value;
      }
    },
    setModelMaterial(state, action) {
      const { idModel, value } = action.payload;
      const selectModel = state.find((model) => model.id === idModel);
      if (selectModel) {
        selectModel.model_material = value;
      }
    },
    setAutoRotate(state, action) {
      const { idModel, value } = action.payload;
      const selectModel = state.find((model) => model.id === idModel);
      if (selectModel) {
        selectModel.autoRotate = value;
      }
    },
    setMetalness(state, action) {
      const { idModel, value } = action.payload;
      const selectModel = state.find((model) => model.id === idModel);
      if (selectModel) {
        selectModel.metalness = value;
      }
    },
    setCameraControls(state, action) {
      const { idModel, value } = action.payload;
      const selectModel = state.find((model) => model.id === idModel);
      if (selectModel) {
        selectModel.cameraControls = !selectModel.cameraControls;
      }
    },
    setListButtonHotspot(state, action) {
      const { idModel, value } = action.payload;
      const selectModel = state.find((model) => model.id === idModel);
      if (selectModel) {
        selectModel.list_button_hotspot = value;
      }
    },
    setListButtonHotspotFix(state, action) {
      const { idModel, value } = action.payload;
      const selectModel = state.find((model) => model.id === idModel);
      if (selectModel) {
        selectModel.list_button_hotspot_fix = value;
      }
    },
    toggleHotspot(state, action) {
      const { idModel, value } = action.payload;
      const selectModel = state.find((model) => model.id === idModel);
      if (selectModel) {
        selectModel.hotspotActive = value;
      }
    },
    setHotspotDetechPosition(state, action) {
      const { idModel, value } = action.payload;
      const selectModel = state.find((model) => model.id === idModel);
      if (selectModel) {
        selectModel.hotspot_detech_position = value;
      }
    },
    setSelectedHotspot(state, action) {
      const { idModel, value } = action.payload;
      const selectModel = state.find((model) => model.id === idModel);
      if (selectModel) {
        selectModel.hotspot_selected = value;
      }
    },
    setEditHotspot(state, action) {
      const { idModel, hotspot } = action.payload;
      const selectModel = state.find((model) => model.id === idModel);
      if (selectModel) {
        selectModel.list_button_hotspot = selectModel.list_button_hotspot.map(
          (content) => (content.id === hotspot.id ? hotspot : content)
        );
      }
    },
    setHdr(state, action) {
      const { idModel, value } = action.payload;
      const selectModel = state.find((model) => model.id === idModel);
      if (selectModel) {
        selectModel.hdr = value;
      }
    },
    setSkybox(state, action) {
      const { idModel, value } = action.payload;
      const selectModel = state.find((model) => model.id === idModel);
      if (selectModel) {
        selectModel.skybox = value;
      }
    },
    reset: () => initialState,
  },
});

export const modelActions = authSlice.actions;

export default authSlice.reducer;
