import React from 'react';
import styles from './SubtitleTab.module.css';

function SubtitleTab(props) {
  return (
    <div className={styles.container}>
        <span className={styles.subtitle}>{props.title}</span>
    </div>
  )
}

export default SubtitleTab