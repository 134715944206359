import { createSlice } from "@reduxjs/toolkit";

const initialState = [
  {
    id: "default",
    title: "Un Nuovo Progetto 3D",
    creation_date: "Date",
    poster_project_image: "default.url",
    model_number: 1,
    state: "draft",
    model_list: [],
    qrcode_src: "default.url",
    link: "default.url",
    selected_model: "1",
    selected_model_view: "1",
    menu_orientation: 1,
    isFirstModelLoaded: false,
    poster:"",
    menu_type: "circle",
    menu_position: "bottom",
    qrCode: false,
    qrForeGroundColor: '#000000',
    qrBackGroundColor: '#ffffff',
    modalQr: false,
    watermark: true,
    arPosition: "TOP",
    items_with_selected: "30px",
    items_with:"25px",
  },
];

const projectDefaultSlice = createSlice({
    name: "defaultProject",
    initialState,
    reducers: {},
  });
  
  export const projectDefaultActions = projectDefaultSlice.actions;
  
  export default projectDefaultSlice.reducer;