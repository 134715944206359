import React from "react";
import styles from "./Modal.module.css";
// import ReactDom from 'react-dom';

function Modal(props) {
  return (
    <div className={styles['modal-rename']}>
      <div className={styles["modal-dialog"]}>
        <div className={styles["modal-body"]}>{props.children}</div>
      </div>
    </div>
    // document.getElementById('portal')
  );
}

export default Modal;
