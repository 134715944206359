import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Styles from "../../assets/css/NewProject.module.css";
import CardModelView from "./CardModelView";
import { firestore } from "../../utility/firebase";
import { modelActionsProject } from "../../redux/project";
import { generalActions } from "../../redux/generalConfig";
import ButtonAr from "../ui/ButtonAr";

function ProjectModelViewer(props) {
  const dispatch = useDispatch();

  const [modalQR, setModalQR] = useState(false);

  const idConfig = useSelector((state) =>
    state.config.find((config) => config.id === "general")
  );

  const project = props.project;

  const arButton = idConfig.qrCode;
  const modalQr = idConfig.modalQr;

  let modelSelectedProjectId = project?.selected_model_view;
  const modelProjectSelected = useSelector((state) =>
    state.model.find((model) => model.id === modelSelectedProjectId)
  );

  function selecteModelInView(project, modelId) {
    console.log("select");
    dispatch(
      modelActionsProject.setSelectedModelInView({
        idProject: project.id,
        value: modelId,
      })
    );
  }

  const MODEL_INSTANCE = {
    src: modelProjectSelected?.src,
    alt: modelProjectSelected?.alt,
    ref: modelProjectSelected?.forwardRef,
    cameraOrbit: modelProjectSelected?.cameraOrbit,
    cameraControls: modelProjectSelected?.cameraControls,
    autoRotate: modelProjectSelected?.autoRotate,
    disableZoom: modelProjectSelected?.disableZoom,
    lightExposition: modelProjectSelected?.lightExposition,
    shadowSmooth: modelProjectSelected?.shadowSmooth,
    shadowIntensity: modelProjectSelected?.shadowIntensity,
    rotationPerSecond: modelProjectSelected?.speedRotate,
    animationName: modelProjectSelected?.animationName,
    poster: modelProjectSelected?.poster,
    ios: modelProjectSelected?.ios,
    numberS: modelProjectSelected?.setNumberStep,
    scale: modelProjectSelected?.scale,
    text: modelProjectSelected?.text,
  };

  function castValues(value) {
    return !value ? null : value;
  }

  function camera() {
    if (MODEL_INSTANCE.cameraOrbit === null) {
      MODEL_INSTANCE.cameraOrbit = "0deg 0deg 101%";
    }
    return MODEL_INSTANCE.cameraOrbit;
  }

  function modalHandler() {
    setModalQR(!modalQR);
    dispatch(generalActions.setModalQr({ idConfig: "general" }));
    dispatch(modelActionsProject.setModalQr({ idProject: project.id }));
  }

  const [arBtn, setArBtn] = useState(false);

  useEffect(() => {
    if (props.viewerPage) {
      firestore
        .collection("Progetti")
        .doc(props.paramsId)
        .get()
        .then((doc) => {
          const progetto = doc.data();
          if(progetto.qrCode) {
            console.log('qr code',progetto.qrCode);
            setArBtn(progetto.qrCode);
          }
        });
    }
  }, []);

  return (
    <>
      <model-viewer
        //  id={"project"}
        id={
          props?.project?.menu_position === "left" ||
          props?.project?.menu_position === "right"
            ? "projectTwo"
            : "project"
        }
        src={MODEL_INSTANCE.src}
        alt={MODEL_INSTANCE.alt}
        ref={MODEL_INSTANCE.ref}
        camera-controls={castValues(MODEL_INSTANCE.cameraControls)}
        auto-rotate={castValues(MODEL_INSTANCE.autoRotate)}
        rotation-per-second={MODEL_INSTANCE.rotationPerSecond}
        disable-zoom={castValues(MODEL_INSTANCE.disableZoom)}
        exposure={MODEL_INSTANCE.lightExposition}
        shadow-intensity={MODEL_INSTANCE.shadowIntensity}
        shadow-softness={MODEL_INSTANCE.shadowSmooth}
        camera-orbit={camera()}
        poster={MODEL_INSTANCE.poster}
        ar
        ar-modes="webxr scene-viewer quick-look"
        // animation-crossfade-duration="0"
        environment-image={MODEL_INSTANCE.hdr}
        skybox-image={MODEL_INSTANCE.skybox}
        allow="xr-spatial-tracking"
      >
        {modelProjectSelected?.hotspot &&
          modelProjectSelected?.list_button_hotspot.map((button, i) => (
            <button
              key={i}
              slot={button.slot}
              className={button.class}
              data-position={button.position}
              data-normal={button.normal}
            >
              {button.description}
            </button>
          ))}
        {props.viewerPage && arBtn && (
          <ButtonAr onClick={modalHandler} isModal={modalQr} project={project}/>
        )}
        {arButton && <ButtonAr onClick={modalHandler} isModal={modalQr} />}
      </model-viewer>
    </>
  );
}

export default ProjectModelViewer;
