import { storage } from "../firebase";

export async function changeTexture(
  chanel,
  materials,
  file,
  modelSelected,
  project
) {
  let modelViewer = document.querySelector("model-viewer#model");
  let texture = await modelViewer.createTexture(URL.createObjectURL(file));
  console.log("texture:", texture);
  try {
    if (chanel.includes("base") || chanel.includes("metallic")) {
      materials.pbrMetallicRoughness[chanel].setTexture(texture);
    } else {
      materials[chanel].setTexture(texture);
    }
    // let storageRefFile = storage.ref(
    //   `Progetti/${project.id}/${modelSelected.id}/model-${modelSelected.id}-material-${materials.name}-baseColor-2.png`
    // );
    const responseSrc = await fetch(URL.createObjectURL(file));
    const blobSrc = await responseSrc.blob();
    // const url = await storageRefFile.put(blobSrc).then((snapshot) => {
    //   return storageRefFile.getDownloadURL();
    // });
    // return url;
    return URL.createObjectURL(blobSrc);

  } catch (error) {
    console.log(error);
  }
}

export function saveColorFactor(listOfMaterial, selectedMaterialViewer, colordef, colorGlb) {
  let newMaterialList = [];

  listOfMaterial.map((material) => {
    if (material.name === selectedMaterialViewer.name) {
      let a = {
        name: material.name,
          metallicFactor: material.metallicFactor,
          roughnessFactor: material.roughnessFactor,
          baseColorFactor: colordef,
          // baseColorFactorHex: colorGlb,
          emissiveFactor: material.emissiveFactor,
          // emissiveFactorHex: material.emissiveFactorHex,
          baseColorImage: material.baseColorImage,
          metallicRoughnessImage: material.metallicRoughnessImage,
          emissiveImage: material.emissiveImage,
          normalImage: material.normalImage,
          occlusionImage: material.occlusionImage,
      };
      newMaterialList.push(a);
    } else {
      let b = {
        name: material.name,
          metallicFactor: material.metallicFactor,
          roughnessFactor: material.roughnessFactor,
          baseColorFactor: material.baseColorFactor,
          // baseColorFactorHex: material.baseColorFactorHex,
          emissiveFactor: material.emissiveFactor,
          // emissiveFactorHex: material.emissiveFactorHex,
          baseColorImage: material.baseColorImage,
          metallicRoughnessImage: material.metallicRoughnessImage,
          emissiveImage: material.emissiveImage,
          normalImage: material.normalImage,
          occlusionImage: material.occlusionImage,
      };
      newMaterialList.push(b);
    }
  });
  return newMaterialList;
}

export function saveColorFactorEmissive(listOfMaterial, selectedMaterialViewer, colordef, colorGlb) {
  let newMaterialList = [];

  listOfMaterial.map((material) => {
    if (material.name === selectedMaterialViewer.name) {
      let a = {
        name: material.name,
          metallicFactor: material.metallicFactor,
          roughnessFactor: material.roughnessFactor,
          baseColorFactor: material.baseColorFactor,
          // baseColorFactorHex: material.baseColorFactorHex,
          emissiveFactor: colordef,
          // emissiveFactorHex: colorGlb,
          baseColorImage: material.baseColorImage,
          metallicRoughnessImage: material.metallicRoughnessImage,
          emissiveImage: material.emissiveImage,
          normalImage: material.normalImage,
          occlusionImage: material.occlusionImage,
      };
      newMaterialList.push(a);
    } else {
      let b = {
        name: material.name,
          metallicFactor: material.metallicFactor,
          roughnessFactor: material.roughnessFactor,
          baseColorFactor: material.baseColorFactor,
          // baseColorFactorHex: material.baseColorFactorHex,
          emissiveFactor: material.emissiveFactor,
          // emissiveFactorHex: material.emissiveFactorHex,
          baseColorImage: material.baseColorImage,
          metallicRoughnessImage: material.metallicRoughnessImage,
          emissiveImage: material.emissiveImage,
          normalImage: material.normalImage,
          occlusionImage: material.occlusionImage,
      };
      newMaterialList.push(b);
    }
  });
  return newMaterialList;
}

export function rgbaTohex(rgbArr) {
  const color = `${rgbArr[0]*255}, ${rgbArr[1]*255}, ${rgbArr[2]*255}, ${rgbArr[3]})`;
  const rgba = color.replace(/^rgba?\(|\s+|\)$/g, '').split(',');

  return `#${((1 << 24) + (parseInt(rgba[0]) << 16) + (parseInt(rgba[1]) << 8) + parseInt(rgba[2])).toString(16).slice(1)}`;
}

