import React from 'react'
import dropzoneSvg from "../../assets/ico/dropzone-container.svg";
import styles from './DropzoneModel.module.css'

const DropZoneModel = () => {
  return (
    <div>
      <div className={styles['canvas29985']}>
        <div className={styles['Group14291011']}>
          <img
            src={dropzoneSvg}
            alt="Rectangle11291012"
            className={styles['image']}
          />
        </div>
        <div className={styles['titoloprogetto291014']}>
          <span className={styles['text']}>
            <span>Drag a 3D model here!</span>
            <br></br>
            <span></span>
          </span>
        </div>
      </div>
    </div>
  )
}

export default DropZoneModel