import React, { Fragment, useEffect, useState } from "react";
import Merge from "merge-images";
import { storage } from "../utility/firebase";
import { useSelector } from "react-redux";

function Image360() {
  const [imageArray, setImageArray] = useState([]);

  const imageArr = [];

  const [mergedImage, setMergedImage] = useState("");

  const model = document.querySelector("model-viewer#project");

  function spinHandler(event) {
    event.preventDefault();

    document.querySelector("model-viewer").cameraOrbit = "0deg 75deg 105%";

    let value = 0;
    let timeInterval = 100;
    // let arrayPoster = [];

    async function getPoster64() {
      model.style.minHeight = "0";
      model.style.minWidth = "0";
      // 
      model.style.width = "768px";//128
      model.style.height = "768px";//128
      model.cameraControls = false;

      const poster = await document
        .querySelector("model-viewer")
        .toBlob({ idealAspect: false });
      const url = URL.createObjectURL(poster);
      console.log("get poster");
      return {
        id: 100 + imageArr.length,
        uri: url,
        order: imageArr.length,
      };
    }

    const interval = setInterval(async () => {
      if (value >= 355) {
        clearInterval(interval);
        // console.log(arrayPoster);
      }
      value += 2.5;
      let poster = await getPoster64().then((promise) => {
        imageArr.push(promise);
      });

      // arrayPoster.push(poster);
      document.querySelector(
        "model-viewer"
      ).cameraOrbit = `${value}deg 75deg 105%`;
    }, timeInterval);

    setImageArray(imageArr);
  }

  function getImg() {
    const DIM = 64;
    console.log("image arr", imageArray);
    Merge(
      [
        { src: imageArray[0].uri, x: 0, y: 0 },
        { src: imageArray[1].uri, x: DIM, y: 0 },
        { src: imageArray[2].uri, x: DIM * 2, y: 0 },
        { src: imageArray[3].uri, x: DIM * 3, y: 0 },
        { src: imageArray[4].uri, x: DIM * 4, y: 0 },
        { src: imageArray[5].uri, x: DIM * 5, y: 0 },

        { src: imageArray[6].uri, x: DIM * 6, y: 0 },
        { src: imageArray[7].uri, x: DIM * 7, y: 0 },
        { src: imageArray[8].uri, x: DIM * 8, y: 0 },
        { src: imageArray[9].uri, x: DIM * 9, y: 0 },
        { src: imageArray[10].uri, x: DIM * 10, y: 0 },
        { src: imageArray[11].uri, x: DIM * 11, y: 0 },

        { src: imageArray[12].uri, x: DIM * 12, y: 0 },
        { src: imageArray[13].uri, x: DIM * 13, y: 0 },
        { src: imageArray[14].uri, x: DIM * 14, y: 0 },
        { src: imageArray[15].uri, x: DIM * 15, y: 0 },
        { src: imageArray[16].uri, x: DIM * 16, y: 0 },
        { src: imageArray[17].uri, x: DIM * 17, y: 0 },

        { src: imageArray[18].uri, x: DIM * 18, y: 0 },
        { src: imageArray[19].uri, x: DIM * 19, y: 0 },
        { src: imageArray[20].uri, x: DIM * 20, y: 0 },
        { src: imageArray[21].uri, x: DIM * 21, y: 0 },
        { src: imageArray[22].uri, x: DIM * 22, y: 0 },
        { src: imageArray[23].uri, x: DIM * 23, y: 0 },

        { src: imageArray[24].uri, x: DIM * 24, y: 0 },
        { src: imageArray[25].uri, x: DIM * 25, y: 0 },
        { src: imageArray[26].uri, x: DIM * 26, y: 0 },
        { src: imageArray[27].uri, x: DIM * 27, y: 0 },
        { src: imageArray[28].uri, x: DIM * 28, y: 0 },
        { src: imageArray[29].uri, x: DIM * 29, y: 0 },

        { src: imageArray[30].uri, x: DIM * 30, y: 0 },
        { src: imageArray[31].uri, x: DIM * 31, y: 0 },
        { src: imageArray[32].uri, x: DIM * 32, y: 0 },
        { src: imageArray[33].uri, x: DIM * 33, y: 0 },
        { src: imageArray[34].uri, x: DIM * 34, y: 0 },
        { src: imageArray[35].uri, x: DIM * 35, y: 0 },

        { src: imageArray[36].uri, x: DIM * 36, y: 0 },
        { src: imageArray[37].uri, x: DIM * 37, y: 0 },
        { src: imageArray[38].uri, x: DIM * 38, y: 0 },
        { src: imageArray[39].uri, x: DIM * 39, y: 0 },
        { src: imageArray[40].uri, x: DIM * 40, y: 0 },
        { src: imageArray[41].uri, x: DIM * 41, y: 0 },

        { src: imageArray[42].uri, x: DIM * 42, y: 0 },
        { src: imageArray[43].uri, x: DIM * 43, y: 0 },
        { src: imageArray[44].uri, x: DIM * 44, y: 0 },
        { src: imageArray[45].uri, x: DIM * 45, y: 0 },
        { src: imageArray[45].uri, x: DIM * 46, y: 0 },
        { src: imageArray[46].uri, x: DIM * 47, y: 0 },

        { src: imageArray[47].uri, x: 0, y: DIM },
        { src: imageArray[48].uri, x: DIM, y: DIM },
        { src: imageArray[49].uri, x: DIM * 2, y: DIM },
        { src: imageArray[50].uri, x: DIM * 3, y: DIM },
        { src: imageArray[51].uri, x: DIM * 4, y: DIM },
        { src: imageArray[52].uri, x: DIM * 5, y: DIM },

        { src: imageArray[53].uri, x: DIM * 6, y: DIM },
        { src: imageArray[54].uri, x: DIM * 7, y: DIM },
        { src: imageArray[55].uri, x: DIM * 8, y: DIM },
        { src: imageArray[56].uri, x: DIM * 9, y: DIM },
        { src: imageArray[57].uri, x: DIM * 10, y: DIM },
        { src: imageArray[58].uri, x: DIM * 11, y: DIM },

        { src: imageArray[59].uri, x: DIM * 12, y: DIM },
        { src: imageArray[60].uri, x: DIM * 13, y: DIM },
        { src: imageArray[61].uri, x: DIM * 14, y: DIM },
        { src: imageArray[62].uri, x: DIM * 15, y: DIM },
        { src: imageArray[63].uri, x: DIM * 16, y: DIM },
        { src: imageArray[64].uri, x: DIM * 17, y: DIM },

        { src: imageArray[65].uri, x: DIM * 18, y: DIM },
        { src: imageArray[66].uri, x: DIM * 19, y: DIM },
        { src: imageArray[67].uri, x: DIM * 20, y: DIM },
        { src: imageArray[68].uri, x: DIM * 21, y: DIM },
        { src: imageArray[69].uri, x: DIM * 22, y: DIM },
        { src: imageArray[70].uri, x: DIM * 23, y: DIM },

        { src: imageArray[71].uri, x: DIM * 24, y: DIM },
        { src: imageArray[72].uri, x: DIM * 25, y: DIM },
        { src: imageArray[73].uri, x: DIM * 26, y: DIM },
        { src: imageArray[74].uri, x: DIM * 27, y: DIM },
        { src: imageArray[75].uri, x: DIM * 28, y: DIM },
        { src: imageArray[76].uri, x: DIM * 29, y: DIM },

        { src: imageArray[77].uri, x: DIM * 30, y: DIM },
        { src: imageArray[78].uri, x: DIM * 31, y: DIM },
        { src: imageArray[79].uri, x: DIM * 32, y: DIM },
        { src: imageArray[80].uri, x: DIM * 33, y: DIM },
        { src: imageArray[81].uri, x: DIM * 34, y: DIM },
        { src: imageArray[82].uri, x: DIM * 35, y: DIM },

        { src: imageArray[83].uri, x: DIM * 36, y: DIM },
        { src: imageArray[84].uri, x: DIM * 37, y: DIM },
        { src: imageArray[85].uri, x: DIM * 38, y: DIM },
        { src: imageArray[86].uri, x: DIM * 39, y: DIM },
        { src: imageArray[87].uri, x: DIM * 40, y: DIM },
        { src: imageArray[88].uri, x: DIM * 41, y: DIM },

        { src: imageArray[89].uri, x: DIM * 42, y: DIM },
        { src: imageArray[90].uri, x: DIM * 43, y: DIM },
        { src: imageArray[91].uri, x: DIM * 44, y: DIM },
        { src: imageArray[92].uri, x: DIM * 45, y: DIM },
        { src: imageArray[93].uri, x: DIM * 46, y: DIM },
        { src: imageArray[94].uri, x: DIM * 47, y: DIM },

        { src: imageArray[95].uri, x: 0, y: DIM * 2 },
        { src: imageArray[96].uri, x: DIM, y: DIM * 2 },
        { src: imageArray[97].uri, x: DIM * 2, y: DIM * 2 },
        { src: imageArray[98].uri, x: DIM * 3, y: DIM * 2 },
        { src: imageArray[99].uri, x: DIM * 4, y: DIM * 2 },
        { src: imageArray[100].uri, x: DIM * 5, y: DIM * 2 },

        { src: imageArray[101].uri, x: DIM * 6, y: DIM * 2 },
        { src: imageArray[102].uri, x: DIM * 7, y: DIM * 2 },
        { src: imageArray[103].uri, x: DIM * 8, y: DIM * 2 },
        { src: imageArray[104].uri, x: DIM * 9, y: DIM * 2 },
        { src: imageArray[105].uri, x: DIM * 10, y: DIM * 2 },
        { src: imageArray[106].uri, x: DIM * 11, y: DIM * 2 },

        { src: imageArray[107].uri, x: DIM * 12, y: DIM * 2 },
        { src: imageArray[108].uri, x: DIM * 13, y: DIM * 2 },
        { src: imageArray[109].uri, x: DIM * 14, y: DIM * 2 },
        { src: imageArray[110].uri, x: DIM * 15, y: DIM * 2 },
        { src: imageArray[111].uri, x: DIM * 16, y: DIM * 2 },
        { src: imageArray[112].uri, x: DIM * 17, y: DIM * 2 },

        { src: imageArray[113].uri, x: DIM * 18, y: DIM * 2 },
        { src: imageArray[114].uri, x: DIM * 19, y: DIM * 2 },
        { src: imageArray[115].uri, x: DIM * 20, y: DIM * 2 },
        { src: imageArray[116].uri, x: DIM * 21, y: DIM * 2 },
        { src: imageArray[117].uri, x: DIM * 22, y: DIM * 2 },
        { src: imageArray[118].uri, x: DIM * 23, y: DIM * 2 },

        { src: imageArray[119].uri, x: DIM * 24, y: DIM * 2 },
        { src: imageArray[120].uri, x: DIM * 25, y: DIM * 2 },
        { src: imageArray[121].uri, x: DIM * 26, y: DIM * 2 },
        { src: imageArray[122].uri, x: DIM * 27, y: DIM * 2 },
        { src: imageArray[123].uri, x: DIM * 28, y: DIM * 2 },
        { src: imageArray[124].uri, x: DIM * 29, y: DIM * 2 },

        { src: imageArray[125].uri, x: DIM * 30, y: DIM * 2 },
        { src: imageArray[126].uri, x: DIM * 31, y: DIM * 2 },
        { src: imageArray[127].uri, x: DIM * 32, y: DIM * 2 },
        { src: imageArray[127].uri, x: DIM * 33, y: DIM * 2 },
        { src: imageArray[128].uri, x: DIM * 34, y: DIM * 2 },
        { src: imageArray[129].uri, x: DIM * 35, y: DIM * 2 },

        { src: imageArray[130].uri, x: DIM * 36, y: DIM * 2 },
        { src: imageArray[131].uri, x: DIM * 37, y: DIM * 2 },
        { src: imageArray[132].uri, x: DIM * 38, y: DIM * 2 },
        { src: imageArray[133].uri, x: DIM * 39, y: DIM * 2 },
        { src: imageArray[134].uri, x: DIM * 40, y: DIM * 2 },
        { src: imageArray[135].uri, x: DIM * 41, y: DIM * 2 },

        { src: imageArray[136].uri, x: DIM * 42, y: DIM * 2 },
        { src: imageArray[137].uri, x: DIM * 43, y: DIM * 2 },
        { src: imageArray[138].uri, x: DIM * 44, y: DIM * 2 },
        { src: imageArray[139].uri, x: DIM * 45, y: DIM * 2 },
        { src: imageArray[140].uri, x: DIM * 46, y: DIM * 2 },
        { src: imageArray[141].uri, x: DIM * 47, y: DIM * 2 },

        { src: imageArray[142].uri, x: 0, y: DIM * 3 },
      ],
      {
        width: 3072,
        height: 270,
      }
    ).then((b64) => {
      setMergedImage(b64);
    });
    model.style.width = "100%";
    model.style.height = "100%";
  }

  const idConfig = useSelector((state) =>
    state.config.find((config) => config.id === "general")
  );

  let project = useSelector((state) =>
    state.project.find((project) => project.id === idConfig.projectOnEdit)
  );

  const modelSelected = useSelector((state) =>
    state.model.find((model) => model.id === project.selected_model)
  );

  function uploadImgsToFirebase() {
    imageArray.map(async (img, i) => {
      console.log({ i, img });
      let storageRefFile = storage.ref(
        `Progetti/${project.id}/${modelSelected.id}/f360/pic-${i}.png`
      );
      fetch(img.uri)
        .then((res) => res.blob())
        .then((blob) => {
          const file = new File([blob], `img-${i}.png`, blob);
          console.log(file);
          storageRefFile.put(file);
        });
    });
    model.style.width = "100%";
    model.style.height = "100%";
  }

  // console.log("merged 1", mergedImage);

  return (
    <Fragment>
      <div className="container">
        <button id="btn-spin" onClick={spinHandler}>
          Spin
        </button>
        <button id="btn-generate-img" onClick={uploadImgsToFirebase}>
          Generate img
        </button>
      </div>
    </Fragment>
  );
}

export default Image360;
