import React, { createRef, useEffect, useState } from "react";
import { Button } from "reactstrap";
import {useSelector, useDispatch} from 'react-redux';
import { modelActionsProject } from "../../redux/project";


function SelectedMenuOrientation(props){

    const dispatch = useDispatch();
    const project = props.project;

    function setMenuOnTop(){
        dispatch(modelActionsProject.setMenuOrientation({idProject: project.id, value: 2}));
    }
    function setMenuOnBottom(){
        dispatch(modelActionsProject.setMenuOrientation({idProject: project.id, value: 1}));
    }
    function setMenuOnRight(){
        dispatch(modelActionsProject.setMenuOrientation({idProject: project.id, value: 3}));
    }
    function setMenuOnLeft(){
        dispatch(modelActionsProject.setMenuOrientation({idProject: project.id, value: 4}));
    }


    return(
        <div>
            <Button onClick={()=> setMenuOnTop()}>Sopra</Button>
            <Button  onClick={()=> setMenuOnBottom()}>Sotto</Button>
            <Button  onClick={()=> setMenuOnRight()}>Destra</Button>
            <Button  onClick={()=> setMenuOnLeft()}>Sinistra</Button>


        </div>
    )
}

export default SelectedMenuOrientation;

