import { createSlice } from "@reduxjs/toolkit";
import notImage from "../assets/images/not-image.png";

const initialState = [
  {
    title: "3D",
    id: "defoult",
    src: null,
    alt: "This is a glb file_",
    autoRotate: false,
    cameraControls: true,
    disableZoom: false,
    speedRotate: "10deg",
    defaultSpeed: "10",
    enableRotation: true,
    yaw: 0,
    pitch: 0,
    hdr_src: "",
    lightExposition: 1.0,
    use_enviroment_skybox: false,
    background_color: "#f4f4f4",
    background_image: null,
    background_boolean_image: false,
    shadowIntensity: 5,
    shadowSmooth: 0.5,
    list_material_initial: [],
    list_material_two: [],
    model_material: null,
    poster_image: notImage,
    list_texture: null,
    emissive_factor: "#000000",
    ar_mode: true,
    hotspotActive: true,
    list_button_hotspot: [],
    list_button_hotspot_fix:[], 
    hotspot_detech_position: false,
    // hotspot_Temp: {
    //   temp_hotspot_id: 0,
    //   temp_hotspot_slot: "",
    //   temp_hotspot_class: "dim",
    //   temp_hotspot_position: "0 0 0",
    //   temp_hotspot_normal: "0 0 0",
    //   temp_hotspot_description: "Hotspot Title",
    // },
    hotspot_selected: "",
    hdr: '',
    skybox: '',
    original_model: "",
  },
];

const modelDefaultSlice = createSlice({
  name: "defaultModel",
  initialState,
  reducers: {},
});

export const modelDefaultActions = modelDefaultSlice.actions;

export default modelDefaultSlice.reducer;
