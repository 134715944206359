import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Label } from "reactstrap";
import { modelActions } from "../redux/model";
import { useDispatch } from "react-redux";
import SubtitleTab from "../component/ui/SubtitleTab";
import ButtonUpload from "../component/ui/ButtonUpload";
import InputCheckBox from "../component/ui/InputComponent/InputCheckBox";

function HDR(props) {
  const conf = useSelector((state) =>
    state.config.find((conf) => conf.id === "general")
  );

  const hdrList = conf.hdr_list;

  const dispatch = useDispatch();

  const [skybox, setSkyBox] = useState(false);

  function hdrHandler(event) {
    dispatch(
      modelActions.setHdr({
        idModel: props.modelSelected.id,
        value: event.target.value,
      })
    );
    if (skybox) {
      dispatch(
        modelActions.setSkybox({
          idModel: props.modelSelected.id,
          value: event.target.value,
        })
      );
    }
  }

  async function hdrUpload(event) {
    const hdr = await URL.createObjectURL(event.target.files[0]);
    dispatch(
      modelActions.setHdr({ idModel: props.modelSelected.id, value: hdr })
    );
  }

  function checkBoxHandler() {
    setSkyBox(!skybox);

    if (skybox) {
      dispatch(
        modelActions.setSkybox({
          idModel: props.modelSelected.id,
          value: "",
        })
      );
    } else {
      dispatch(
        modelActions.setSkybox({
          idModel: props.modelSelected.id,
          value: props.modelSelected.hdr,
        })
      );
    }
  }

  return (
    <div>
      <SubtitleTab title="Carica Environment" />
      <div>
        <select
          style={{
            display: "inline-block",
            position: "relative",
            borderRadius: "13.5px",
            color: "white",
            background: 'rgba(248, 245, 253, 0.2)',
            width: "176px",
            height: "27px",
          }}
          onChange={hdrHandler}
        >
          <option value="">None</option>
          {hdrList.map((ele, i) => {
            return (
              <option id={i} key={ele.name} value={ele.url}>
                {!ele.name ? "Material" : ele.name}
              </option>
            );
          })}
        </select>
      </div>
      <div>
        <InputCheckBox
          checked={skybox}
          onChange={checkBoxHandler}
          label="Use skybox env"
        />
      </div>
      {/* <ButtonUpload text="HDR" onChange={hdrUpload} accept=".hdr"/> */}
    </div>
  );
}

export default HDR;
