import React, { Fragment, useEffect, useState } from "react";
import Merge from "merge-images";
import { storage } from "../utility/firebase";
import { useSelector, useDispatch } from "react-redux";
import SubtitleTab from "../component/ui/SubtitleTab";
import RowSubTab from "../component/ui/InputComponent/RowSubTab";
import ColumSubTab from "../component/ui/InputComponent/ColumSubTab";
import SubTabContainer from "../component/ui/SubTabContainer";
import styles from "../assets/css/ImpostazioniProgetto.module.css";
import { modelActionsProject } from "../redux/project";
import IcoSx from "../assets/ico/icona-sinistra.svg";
import IcoSxSel from "../assets/ico/icona-sinistra-selezion.svg";
import IcoDx from "../assets/ico/icona-destra.svg";
import IcoDxSel from "../assets/ico/icona-destra-selezionato.svg";
import IconAlto from "../assets/ico/icona-alto.svg";
import IconAltoSel from "../assets/ico/icona-alto-selezionato.svg";
import IconBasso from "../assets/ico/icona-basso.svg";
import IconBassoSel from "../assets/ico/icona-bsso-selezionato.svg";

function ImpostazioniProgetto(props) {
  const dispatch = useDispatch();

  const idConfig = useSelector((state) =>
    state.config.find((config) => config.id === "general")
  );

  let project = useSelector((state) =>
    state.project.find((project) => project.id === idConfig.projectOnEdit)
  );
  const menuOption = project?.menu_type;
  const menuPosition = project?.menu_position;

  function setMenuTypeStyle(type) {
    console.log(type);
    dispatch(
      modelActionsProject.setMenuType({
        idProject: project.id,
        value: type,
      })
    );
  }

  function setMenuPositionType(type) {
    console.log(type);
    dispatch(
      modelActionsProject.setMenuPosition({
        idProject: project.id,
        value: type,
      })
    );
  }

  // console.log("merged 1", mergedImage);

  return (
    <>
      <SubTabContainer title="Stile Menu">
        <RowSubTab>
          <ColumSubTab>
            <img
              className={styles.ico}
              src={`${menuOption === "card" ? IcoSx : IcoSxSel}`}
              onClick={() => setMenuTypeStyle("card")}
            />
          </ColumSubTab>
          <ColumSubTab>
            <img
              className={styles.ico}
              src={`${menuOption === "plane" ? IcoSx : IcoSxSel}`}
              onClick={() => setMenuTypeStyle("plane")}
            />
          </ColumSubTab>
          <ColumSubTab>
            <img
              className={styles.ico}
              src={`${menuOption === "circle" ? IcoSx : IcoSxSel}`}
              onClick={() => setMenuTypeStyle("circle")}
            />
          </ColumSubTab>
        </RowSubTab>
      </SubTabContainer>

      <SubTabContainer title="Posizione Menu ">
        <RowSubTab>
          <ColumSubTab>
            <img
              className={styles.ico}
              src={`${menuPosition === "left" ? IcoSx : IcoSxSel}`}
              onClick={() => setMenuPositionType("left")}
            />
          </ColumSubTab>
          <ColumSubTab>
            <img
              className={styles.ico}
              src={`${menuPosition === "right" ? IcoDx : IcoDxSel}`}
              onClick={() => setMenuPositionType("right")}
            />
          </ColumSubTab>
          <ColumSubTab>
            <img
              className={styles.ico}
              src={`${menuPosition === "bottom" ? IconAlto : IconAltoSel}`}
              onClick={() => setMenuPositionType("bottom")}
            />
          </ColumSubTab>
          <ColumSubTab>
            <img
              className={styles.ico}
              src={`${
                menuPosition === "top"
                  ? IconBasso : IconBassoSel
              }`}
              onClick={() => setMenuPositionType("top")}
            />
          </ColumSubTab>
        </RowSubTab>
      </SubTabContainer>
    </>
  );
}

export default ImpostazioniProgetto;
