import React, { Fragment, useEffect, useState } from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Switch, Route, Redirect } from "react-router-dom";
import "./index.scss";
import './index.css';
import './utility/i18n';
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import store from "./store/index";
import App from "./App";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { routes } from "./router/route";
import ConfigDB from "./data/customizer/config";
import { AuthProvider } from "./utility/AuthContext";
import { auth } from "./utility/firebase";
import Login2 from "./pages/Login2";
import ActivateAccount from "./pages/ActivateAccount";
import RecoverPassword from "./pages/RecoverPassword";
import Error400 from './pages/errors/error400';
import Error404 from './pages/errors/error404';
import Error500 from './pages/errors/error500';
import Viewer3d from './pages/Viewer3d';
import { compose } from "redux";

const Root = (props) => {
  const [anim, setAnim] = useState("");
  const [currentUser, setCurrentUser] = useState(false);
  const animation =
  localStorage.getItem("animation") || ConfigDB.data.router_animation;
  const abortController = new AbortController();

  useEffect(() => {
    setAnim(animation);
    const layout = localStorage.getItem("layout_version");
    document.body.classList.add(layout);
    const color = localStorage.getItem("color");
    document
      .getElementById("color")
      .setAttribute(
        "href",
        `${process.env.PUBLIC_URL}/assets/css/${color}.css`
      );
    auth.onAuthStateChanged(setCurrentUser);
    console.ignoredYellowBox = ["Warning: Each", "Warning: Failed"];
    console.disableYellowBox = true;
    return function cleanup() {
      abortController.abort();
    };

    // eslint-disable-next-line
  }, []);

  return (
    <Fragment>
      <AuthProvider>
        <Provider store={store}>
          <BrowserRouter basename={`/`}>
            <Switch>
              <Route
                path={`${process.env.PUBLIC_URL}/login`}
                component={Login2}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/activate-account`}
                component={ActivateAccount}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/recover-password`}
                component={RecoverPassword}
              />
              <Route path={`${process.env.PUBLIC_URL}/pages/error-400`} component={Error400}/>
              {/*TODO ERROR PAGE <Route path="*" component={Error404}/> */}
              <Route path={`${process.env.PUBLIC_URL}/pages/error-500`} component={Error500}/>
              <Route exact path={`${process.env.PUBLIC_URL}/:token`} component={Viewer3d}/>

              {currentUser !== null ? (
                <Fragment>
                  <App>
                    <Route
                      exact
                      path={`${process.env.PUBLIC_URL}/`}
                      render={() => {
                        return <Redirect to={`${process.env.PUBLIC_URL}/dashboard/project`} />;
                      }}
                    />

                    <TransitionGroup>
                      {routes.map(({ path, Component }) => (
                        <Route key={path} exact path={path}>
                          {({ match }) => (
                            <CSSTransition
                              in={match != null}
                              timeout={500}
                              classNames={anim}
                              unmountOnExit
                            >
                              <div>
                                <Component />
                              </div>
                            </CSSTransition>
                          )}
                        </Route>
                      ))}
                    </TransitionGroup>
                  </App>
                </Fragment>
              ) : (
                <Redirect to={`${process.env.PUBLIC_URL}/login`} />
              )}
              
            </Switch>
          </BrowserRouter>
        </Provider>
      </AuthProvider>
    </Fragment>
  );
};
ReactDOM.render(<Root />, document.getElementById("root"));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
