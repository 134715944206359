import React from 'react';
import styles from './ContainerSelectObject.module.css';

function ContainerSelectObject(props) {
  return (
    <div className={styles.container}>
        {props.children}
    </div>
  )
}

export default ContainerSelectObject