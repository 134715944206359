import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
// import { useTranslation } from "react-i18next";
import { modelActions } from "../redux/model";
import { Button } from "reactstrap";
import { modelActionsProject } from "../redux/project";
import { nanoid } from "@reduxjs/toolkit";
import Modal from "../component/ui/modal/Modal";
import AddModel from "./AddModel";
import BackDrop from "../component/ui/modal/BackDrop";

function AddNewProject(props) {
  const dispatch = useDispatch();
  let idModel;
  const [files, setFiles] = useState([]);
  const [screenshot, setScreenshot] = useState(false);

  const project = props.project;
  const modelDefault = useSelector((state) =>
    state.defaultModel.find((model) => model.id === "default")
  );

  const [isModalOpen, setIsModalOpen] = useState(false);

  function duplicateDefault() {
    const id = nanoid();
    idModel = id;
    dispatch(modelActions.setNewModel({ idModel: id, value: modelDefault }));
    dispatch(
      modelActionsProject.addModelToList({ idProject: project.id, idModel: id })
    );
  }

  function newProjectHandler() {
    // duplicateDefault();
    // dispatch(
    //   modelActionsProject.setSelectedModel({
    //     idProject: project.id,
    //     value: idModel,
    //   })
    // );
    setIsModalOpen(true);
  }

  return (
    <div>
      <Button onClick={newProjectHandler}> Add new Model </Button>
      {isModalOpen && (
        <Modal>
          <AddModel closeModal={() => setIsModalOpen(false)} />
        </Modal>
      )}
      {isModalOpen && <BackDrop onCancel={() => setIsModalOpen(false)} />}
    </div>
  );
}

export default AddNewProject;
