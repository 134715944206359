import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { modelActions } from "../../redux/model";
import { hotspotCreator } from "../../model/hotspotData";
import { generalActions } from "../../redux/generalConfig";
import { modelActionsProject } from "../../redux/project";
import ButtonAr from "../ui/ButtonAr";

function ModelViewer(props) {
  const dispatch = useDispatch();

  const [modalQR, setModalQR] = useState(false);

  const idConfig = useSelector((state) =>
    state.config.find((config) => config.id === "general")
  );

  const arButton = idConfig.qrCode;
  const modalQr = idConfig.modalQr;

  const project = useSelector((state) =>
    state.project.find((project) => project.id === idConfig.projectOnEdit)
  );

  const modelSelected = useSelector((state) =>
    state.model.find((model) => model.id === project?.selected_model)
  );

  function castValues(value) {
    return !value ? null : value;
  }

  const MODEL_INSTANCE = {
    src: modelSelected?.src,
    alt: modelSelected?.alt,
    ref: props.refs,
    cameraOrbit: modelSelected?.cameraOrbit,
    cameraControls: modelSelected?.cameraControls,
    autoRotate: modelSelected?.autoRotate,
    disableZoom: modelSelected?.disableZoom,
    lightExposition: modelSelected?.lightExposition,
    shadowSmooth: modelSelected?.shadowSmooth,
    shadowIntensity: modelSelected?.shadowIntensity,
    rotationPerSecond: modelSelected?.speedRotate,
    animationName: modelSelected?.animationName,
    poster: modelSelected?.poster,
    ios: modelSelected?.ios,
    numberS: modelSelected?.setNumberStep,
    scale: modelSelected?.scale,
    text: modelSelected?.text,
    hdr: modelSelected?.hdr,
    skybox: modelSelected?.skybox,
  };

  function camera() {
    if (MODEL_INSTANCE.cameraOrbit === null) {
      MODEL_INSTANCE.cameraOrbit = "0deg 0deg 101%";
    }
    return MODEL_INSTANCE.cameraOrbit;
  }

  function onClick(e) {
    if (modelSelected?.hotspot_detech_position) {
      const viewer = document.getElementById("model");
      const rect = viewer.getBoundingClientRect();
      const x = e.clientX - rect.left;
      const y = e.clientY - rect.top;
      const positionAndNormal = viewer.positionAndNormalFromPoint(
        e.clientX,
        e.clientY
      );

      if (positionAndNormal == null) {
        return;
      }

      const curentHotspot = modelSelected.list_button_hotspot.find(
        (hotspot) => hotspot.id === modelSelected.hotspot_selected
      );

      const newHotspot = hotspotCreator(
        "UPDATE",
        curentHotspot,
        "position",
        positionAndNormal
      );

      dispatch(
        modelActions.setEditHotspot({
          idModel: modelSelected.id,
          hotspot: newHotspot,
        })
      );
      dispatch(
        modelActions.setSelectedHotspot({
          idModel: modelSelected.id,
          value: newHotspot.id,
        })
      );

      // dispatch(modelActions.setTempHotspotPosition({idModel: modelSelected.id, value:`${positionAndNormal.position.x.toFixed(5)} ${positionAndNormal.position.y.toFixed(5)} ${positionAndNormal.position.z.toFixed(5)}`}));
      // dispatch(modelActions.setTempHotspotNormal({idModel: modelSelected.id, value:`${positionAndNormal.normal.x} ${positionAndNormal.normal.y} ${positionAndNormal.normal.z}`}));
    }
  }

  function modalHandler() {
    setModalQR(!modalQR);
    dispatch(generalActions.setModalQr({ idConfig: "general" }));
    dispatch(modelActionsProject.setModalQr({ idProject: project.id }));
  }

  return (
    <div onClick={onClick}>
      <model-viewer
        src={MODEL_INSTANCE.src}
        alt={MODEL_INSTANCE.alt}
        ref={MODEL_INSTANCE.ref}
        id="model"
        camera-controls={castValues(MODEL_INSTANCE.cameraControls)}
        auto-rotate={castValues(MODEL_INSTANCE.autoRotate)}
        rotation-per-second={MODEL_INSTANCE.rotationPerSecond}
        disable-zoom={castValues(MODEL_INSTANCE.disableZoom)}
        exposure={MODEL_INSTANCE.lightExposition}
        shadow-intensity={MODEL_INSTANCE.shadowIntensity}
        shadow-softness={MODEL_INSTANCE.shadowSmooth}
        camera-orbit={camera()}
        poster={MODEL_INSTANCE.poster}
        ar
        ar-modes="webxr scene-viewer quick-look"
        animation-crossfade-duration="0"
        environment-image={MODEL_INSTANCE.hdr}
        skybox-image={MODEL_INSTANCE.skybox}
      >
        {arButton && <ButtonAr onClick={modalHandler} isModal={modalQr} />}
        {props.children}
      </model-viewer>
    </div>
  );
}

export default ModelViewer;
