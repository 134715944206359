import React, { useState } from "react";
import styles from "./InputNumber.module.css";

function NumberFactor(props) {
  return (
    <div className={styles.inputNumberContainer} key={props.id}>
      <div className={styles.inputNumber}>
        <input
          type="number"
          step={0.01}
          min={0}
          max={1}
          className={styles.inputNumber}
          value={props.value}
          onChange={props.onChange}
        />
      </div>
    </div>
  );
}

export default NumberFactor;
