export const hotspotCreator = (type, hotspot, dataType, data) => {
  if (type === "UPDATE") {
    const newHotspot = {
      id: hotspot?.id,
      name: dataType === "name" ? data : hotspot?.name,
      slot: hotspot.slot,
      class: dataType === "class" ? data : hotspot?.class,
      position:
        dataType === "position"
          ? `${data.position.x} ${data.position.y} ${data.position.z}`
          : hotspot?.position,
      normal:
        dataType === "position"
          ? `${data.normal.x} ${data.normal.y} ${data.normal.z}`
          : hotspot?.normal,
      description: dataType === "description" ? data : hotspot?.description,
      contentBackgroundColor:
        dataType === "contentBackground"
          ? data
          : hotspot?.contentBackgroundColor,
      borderContentColor:
        dataType === "borderContentColor" ? data : hotspot?.borderContentColor,
      borderContentRadius:
        dataType === "borderContentRadius" ? data : hotspot?.borderContentRadius,
      borderContentSize:
        dataType === "borderContentSize" ? data : hotspot?.borderContentSize,
      viewContent: dataType === "viewContent" ? data : hotspot?.viewContent,
      isAnimationHotspot:
        dataType === "isAnimationHotspot" ? data : hotspot?.isAnimationHotspot,
      animation: dataType === "animation" ? data : hotspot?.animation,
      animation_duration:
        dataType === "animation_duration" ? data : hotspot?.animation_duration,
      shape: dataType === "shape" ? data : hotspot?.shape,
      shape_width: dataType === "shape_width" ? data : hotspot?.shape_width,
      shape_color: dataType === "shape_color" ? data : hotspot?.shape_color,
      shape_border_color:
        dataType === "shape_border_color" ? data : hotspot?.shape_border_color,
      shape_icon: dataType === "shape_icon" ? data : hotspot?.shape_icon,
    };
    return newHotspot;
  } else {
    let idHotspot = hotspot;
    return {
      id: idHotspot,
      position: "0.0 0.0 0.0",
      name: "hotspot-" + idHotspot.substring(1, 4),
      normal: "0.0 0.0 0.0",
      description: "Your Hotspot Text",
      class: "hotspot-custom",
      slot: `hotspot-${Math.random()}`,
      contentBackgroundColor: "#ffffff",
      viewContent: false,
      borderContentColor: "#ffffff",
      borderContentRadius: "2",
      borderContentSize: "2",
      isAnimationHotspot: false,
      animation: "",
      animation_duration: 0,
      shape: "circle",
      shape_width: "1",
      shape_color: "#ffffff",
      shape_border_color: "#000000",
      shape_icon: "",
    };
  }
};
