import { createSlice } from "@reduxjs/toolkit";
import SpruitSunriseHDR from "../assets/hdr/spruit_sunrise_1k_HDR.hdr";
import Pillars from "../assets/hdr/pillars_1k.hdr";


const initialState = [
  {
    id: "general",
    projectOnEdit: "",
    isDataProjectIsLoad: false,
    qrCode: false,
    qrForeGroundColor: '#000000',
    qrBackGroundColor: '#ffffff',
    modalQr: false,
    hdr_list: [
      { name: "Spruit Sunrise", url: SpruitSunriseHDR },
      { name: "Pillars", url: Pillars },
    
    ],
  },
];

const configSlice = createSlice({
  name: "config",
  initialState,
  reducers: {
    setProjectOnEdit(state, action) {
      const { idConfig, value } = action.payload;
      const selectConfig = state.find((config) => config.id === idConfig);
      if (selectConfig) {
        selectConfig.projectOnEdit = value;
      }
    },
    setFirebaseDataLoad(state, action){
      const { idConfig, value } = action.payload;
      const selectConfig = state.find((config) => config.id === idConfig);
      if (selectConfig) {
        selectConfig.isDataProjectIsLoad = value;
      }
    },
    setQrCode(state, action) {
      const { idConfig } = action.payload;
      const selectConfig = state.find((config) => config.id === idConfig);
      if (selectConfig) {
        selectConfig.qrCode = !selectConfig.qrCode;
      }
    },
    setQrBackGroundColor(state, action) {
      const { idConfig, value } = action.payload;
      const selectConfig = state.find((config) => config.id === idConfig);
      if (selectConfig) {
        selectConfig.qrBackGroundColor = value;
      }
    },
    setQrForeGroundColor(state, action) {
      const { idConfig, value } = action.payload;
      const selectConfig = state.find((config) => config.id === idConfig);
      if (selectConfig) {
        selectConfig.qrForeGroundColor = value;
      }
    },
    setModalQr(state, action) {
      const { idConfig } = action.payload;
      const selectConfig = state.find((config) => config.id === idConfig);
      if (selectConfig) {
        selectConfig.modalQr = !selectConfig.modalQr;
      }
    }
  },
});

export const generalActions = configSlice.actions;

export default configSlice.reducer;
