import React, { Fragment, useState, createRef, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector, useDispatch } from 'react-redux'
import ModelViewer from '../component/viewer/ModelViewer'
import Screenshot from '../actions/Screenshot'
import {
  Container,
  Row,
  Col,
  TabContent,
  TabPane,
  Button,
  Input,
  Label,
} from 'reactstrap'
import SelectMaterial from '../actions/SelectMaterial'
import Textures from '../actions/Textures'
import Exposure from '../actions/Exposure'
import ShadowIntensity from '../actions/ShadowIntensity'
import Smooth from '../actions/Smooth'
import AutoRotate from '../actions/AutoRotate'
import CameraControls from '../actions/CameraControls'
import ToggleHotspot from '../actions/ToggleHotspot'
import ToggleHotspotFix from '../actions/ToggleHotspotButton'
import BackGroundEdit from '../actions/BackGroundEdit'
import ModelsThumbView from '../actions/ModelsThumbView'
import { modelActions } from '../redux/model'
import { useHistory, Prompt, Redirect } from 'react-router-dom'
import AddFirstModel from '../actions/AddFirstModel'
import HDR from '../actions/HDR'
import AddNewProject from '../actions/AddNewModel'
import SelectedMenuOrientation from '../actions/menu/SelectedMenuOrientation'
import ProjectModelViewer from '../component/viewer/ProjectModelViewer'
import Transitions from '../actions/animations/transitions/Transitions'
import {
  firebaseSaveProject,
  deleteModel,
} from '../utility/function/firebaseFunction'
import stylesButton from '../component/ui/ButtonUpload.module.css'
import { useAuth } from '../utility/AuthContext'
import {
  firebaseSetProjectToUser,
  setNewGlb,
} from '../utility/function/firebaseFunction'
import ModelHeader from '../component/ui/ModelHeader'
import ModelContainer from '../component/ui/ModelContainer'
import RightColumn from '../component/ui/RightColumm'
import TabContainer from '../component/ui/TabContainer'
import styles from '../assets/css/EditModel.module.css'
import ButtonUpload from '../component/ui/ButtonUpload'
import ModalLoading from '../component/ui/modal/ModalLoading'
import BackDrop from '../component/ui/modal/BackDrop'
import HotspotView from '../component/ui/hotspotView'
import ProjectDashBoardCard from '../component/ui/ProjectDashBoardCard'
import { nanoid } from '@reduxjs/toolkit'
import { modelActionsProject } from '../redux/project'
import InputCheckBox from '../component/ui/InputComponent/InputCheckBox'
import { storage } from '../utility/firebase'
import { FormControlUnstyledContext } from '@mui/base'
import Image360 from '../actions/Image360'
import CardModelView from '../component/viewer/CardModelView'
import Styles from '../assets/css/NewProject.module.css'
import QRCode from '../actions/QRCode'
import styleModal from '../component/ui/modal/Modal.module.css'
import Modal from '../component/ui/modal/ModalRename'
import stylesBackDrop from '../component/ui/modal/BackDrop.module.css'
import ContainerSelectObject from '../component/ui/ContainerSelectObject'
import DropZoneModel from '../component/ui/DropZoneModel'
import ImpostazioniProgetto from '../actions/ImpostazioniProgetto'
import MenuContainer from '../component/viewer/MenuContainer'

function EditModel(props) {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const [RightLineTab, setRightLineTab] = useState('1')
  const { currentUser } = useAuth()

  const [isInSave, setIsInSave] = useState(false)
  const [modalRename, setModalRename] = useState(false)
  const [isGlbLoaded, setIsGlbLoaded] = useState(
    props.project?.isFirstModelLoaded,
  )
  const [isSaved, setIsSaved] = useState(false)
  const [hasPoster, setHasPoster] = useState(false)
  const [displayProjectViewer, setDisplayProjectViewer] = useState(false)
  const [displayModelViewer, setDisplayModelViewer] = useState(true)
  const [modelName, setModelName] = useState('')

  const modelViewerRef = createRef()
  const reduxModel = useSelector((state) => state.model)

  const idConfig = useSelector((state) =>
    state.config.find((config) => config.id === 'general'),
  )

  let project = useSelector((state) =>
    state.project.find((project) => project.id === idConfig.projectOnEdit),
  )

  const modelSelected = useSelector((state) =>
    state.model.find((model) => model.id === project.selected_model),
  )

  const modelInView = useSelector((state) =>
    state.model.find((model) => model.id === project.selected_model_view),
  )

  const modelList = useSelector((state) => state.model)

  const [modelSaveVariant, setModelSaveVariant] = useState(false)

  async function setProjectTab(tab) {
    if (tab === '6' || tab === '7' || tab === '8' || tab === '9') {
      //trigger loading modal
      setIsInSave(true)

      if (!modelSaveVariant) {
        let url = await setNewGlb(modelSelected, project)
        dispatch(
          modelActions.setSrc({
            idModel: modelSelected.id,
            src: url,
          }),
        )
        setModelSaveVariant(true)
      }
      setDisplayProjectViewer(true)
      setDisplayModelViewer(false)
    } else {
      //setViewerMaterial();
      setDisplayModelViewer(true)
      setModelSaveVariant(false)
      setDisplayProjectViewer(false)
    }

    setRightLineTab(tab)
    setIsInSave(false)
  }

  function modalRenameHandler(model, project) {
    dispatch(
      modelActionsProject.setSelectedModel({
        idProject: project.id,
        value: model.id,
      }),
    )
    setModalRename(true)
  }

  function rename() {
    dispatch(
      modelActions.setTitle({ idModel: modelSelected.id, value: modelName }),
    )
    setModalRename(false)
  }

  async function duplicateModel(model, project) {
    const idModel = nanoid()

    dispatch(modelActions.setNewModel({ idModel: idModel, value: model }))

    dispatch(
      modelActionsProject.addModelToList({
        idProject: project.id,
        idModel: idModel,
      }),
    )

    const responseSrc = await fetch(model.src)
    const blobSrc = await responseSrc.blob()

    dispatch(
      modelActions.setSrc({
        idModel: modelSelected.id,
        // src: url,
        src: URL.createObjectURL(blobSrc),
      }),
    )
  }

  async function removeModel(model, project) {
    if (modelList.length === 1) {
      return
    }

    const ids = []
    let index = 0

    modelList.map((m, i) => {
      if (m.id !== model.id) {
        ids.push(m.id)
      } else {
        index = i
      }
    })

    console.log({ index })

    dispatch(modelActions.removeModelList({ index }))

    dispatch(
      modelActionsProject.setModelList({
        idProject: project.id,
        value: ids,
      }),
    )

    dispatch(
      modelActionsProject.setSelectedModel({
        idProject: project.id,
        value: modelList[0],
      }),
    )

    await deleteModel(project.id, model.id)
  }

  function setEditModel(model, project) {
    dispatch(
      modelActionsProject.setSelectedModel({
        idProject: project.id,
        value: model.id,
      }),
    )
    setProjectTab('1')
  }


  async function saveHandler() {
    setIsSaved(true)
    setIsInSave(true)

    // let responseSrcModel= null;
    // if (!displayProjectViewer) {
    //   let exportModel = await setNewGlb(modelSelected, project)
    //   responseSrcModel = await fetch(exportModel);
    //   dispatch(
    //     modelActions.setSrc({
    //       idModel: modelSelected.id,
    //       src: responseSrcModel
    //     })
    //   );
    // }else{
    //   responseSrcModel = await fetch(modelSelected.src);
    // }
    if (RightLineTab === '1' || RightLineTab === '2' || RightLineTab === '3' || RightLineTab === '4') {  
    let exportModel = await setNewGlb(modelSelected, project)
     console.log(exportModel)
    dispatch(
      modelActions.setSrc({
        idModel: modelSelected.id,
        src: exportModel,
      }),
     )
    }
    // let responseSrcModel = await fetch(exportModel);
    // const blobSrcModel = await responseSrcModel.blob();

    // //Save Model
    // let storageRefFileModel = storage.ref(
    //   `Progetti/${project.id}/${modelSelected.id}/model-${modelSelected.id}.glb`,
    // )

    // let urlModel = await storageRefFileModel.put(blobSrcModel).then(()=>{
    //   return storageRefFileModel.getDownloadURL()
    // })
    firebaseSetProjectToUser(currentUser.email, project.id) 
    firebaseSaveProject(project, reduxModel).then((result)=>{
      setIsInSave(false);
    })
       

    // await dispatchFirebaseModel(urlModel).then(()=>{
    //    console.log(urlModel)
    //    firebaseSaveProject(project, reduxModel)
    //    firebaseSetProjectToUser(currentUser.email, project.id)    
    // })
   
   
    //save original

    // const responseSrcModelOriginal = await fetch(modelSelected.model_original);
    // const blobSrcModelOriginal = await responseSrcModelOriginal.blob();

    // let storageRefFileModelOriginal = storage.ref(
    //   `Progetti/${project.id}/${modelSelected.id}/model-original-${modelSelected.id}.glb`,
    // )
    // let urlModelOriginal = await storageRefFileModelOriginal
    //   .put(blobSrcModelOriginal)
    //   .then((snapshot) => {
    //     return storageRefFileModelOriginal.getDownloadURL()
    //   })

    // dispatch(
    //   modelActions.setOriginalModel({
    //     idModel: modelSelected.id,
    //     value: urlModelOriginal,
    //   }),
    //  )
  }


  function selecteModelInView(project, modelId) {
    console.log('select')
    dispatch(
      modelActionsProject.setSelectedModelInView({
        idProject: project.id,
        value: modelId,
      }),
    )
  }

  function handleNewModel() {
    setProjectTab('0')
  }

  function isModelLoaded() {
    const isLoaded = document.querySelector('model-viewer').loaded
    if (isLoaded) {
    }
  }

  function isFacebookApp() {
    let ua = navigator.userAgent || navigator.vendor || window.opera
    return ua.indexOf('FBAN') > -1 || ua.indexOf('FBAV') > -1
  }

  return (
    <Fragment>
      {!project && <Redirect to="/dashboard/project" />}
      <Prompt
        when={!isSaved}
        message="Project not saved, your modifications will be lost. Leave?"
      ></Prompt>
      <Container fluid={true}>
        <Row>
          <Col className={`col-md-9 ${styles['no-padding']}`}>
            <ModelHeader project={project} />
            <div>
              {!project?.isFirstModelLoaded && displayModelViewer && (
                <AddFirstModel />
              )}
              {/*Modal Loading */}
              {isInSave && (
                <ModalLoading>
                  <p>Loading...</p>
                </ModalLoading>
              )}
              {isInSave && <BackDrop />}
              {project?.isFirstModelLoaded && displayModelViewer && (
                <ModelContainer modelSelected={modelSelected}>
                  <ModelViewer refs={modelViewerRef}>
                    {modelSelected?.hotspotActive &&
                      modelSelected?.list_button_hotspot.map((button, i) => (
                        <HotspotView
                          key={i}
                          button={button}
                          i={i}
                        ></HotspotView>
                      ))}
                  </ModelViewer>
                </ModelContainer>
              )}
              {displayProjectViewer && (
                <>
                  <ModelContainer modelSelected={modelInView} project={project}>
                    {project?.menu_position === 'top' && (
                      <MenuContainer style={'TOP'} menu={project.menu_type}>
                        {project?.model_list.map((modelIdCard, i) => (
                          <CardModelView
                            key={i}
                            modelId={modelIdCard}
                            selected={project.selected_model_view}
                            project={project}
                            selectModel={() =>
                              selecteModelInView(project, modelIdCard)
                            }
                            cardStyle={project?.menu_type}
                          ></CardModelView>
                        ))}
                      </MenuContainer>
                    )}
                    {project?.menu_position === 'left' && (
                      <MenuContainer style={'LEFT'} menu={project.menu_type}>
                        {project?.model_list.map((modelIdCard, i) => (
                          <CardModelView
                            key={i}
                            modelId={modelIdCard}
                            selected={project.selected_model_view}
                            project={project}
                            selectModel={() =>
                              selecteModelInView(project, modelIdCard)
                            }
                            cardStyle={project?.menu_type}
                          ></CardModelView>
                        ))}
                      </MenuContainer>
                    )}

                    <ProjectModelViewer project={project}></ProjectModelViewer>

                    {project?.menu_position === 'bottom' && (
                      <MenuContainer style={'BOTTOM'} menu={project.menu_type}>
                        {project?.model_list.map((modelIdCard, i) => (
                          <CardModelView
                            key={i}
                            modelId={modelIdCard}
                            selected={project.selected_model_view}
                            project={project}
                            selectModel={() =>
                              selecteModelInView(project, modelIdCard)
                            }
                            cardStyle={project?.menu_type}
                          ></CardModelView>
                        ))}
                      </MenuContainer>
                    )}

                    {project?.menu_position === 'right' && (
                      <MenuContainer style={'RIGHT'} menu={project.menu_type}>
                        {project?.model_list.map((modelIdCard, i) => (
                          <CardModelView
                            key={i}
                            modelId={modelIdCard}
                            selected={project.selected_model_view}
                            project={project}
                            selectModel={() =>
                              selecteModelInView(project, modelIdCard)
                            }
                            cardStyle={project?.menu_type}
                          ></CardModelView>
                        ))}
                      </MenuContainer>
                    )}
                  </ModelContainer>
                </>
              )}
            </div>
          </Col>
          <Col className={`col-md-2 ${styles['no-padding']}`}>
            <TabContent activeTab={RightLineTab} className="text-right">
              <TabPane tabId="0">{/* <DropZoneModel /> */}</TabPane>
              <TabPane tabId="1">
                <TabContainer
                  id="1"
                  disabled={project?.isFirstModelLoaded}
                  title="CHECK BOX AUTO ROTATE"
                  tooltip="tooltip info test test test"
                >
                  <AutoRotate modelSelected={modelSelected} />
                </TabContainer>
                <TabContainer
                  id="2"
                  disabled={project?.isFirstModelLoaded}
                  title="CHECK BOX CAMERA CONTROL"
                  tooltip="tooltip second lorem ipsum"
                >
                  <CameraControls modelSelected={modelSelected} />
                </TabContainer>
              </TabPane>
              <TabPane tabId="2">
                <TabContainer
                  id="3"
                  disabled={project?.isFirstModelLoaded}
                  title="BACKGROUND"
                  tooltip="background"
                >
                  <BackGroundEdit modelSelected={modelSelected} />
                </TabContainer>
                <TabContainer
                  id="4"
                  disabled={project?.isFirstModelLoaded}
                  title="ENVIRONMENT"
                  tooltip="env"
                >
                  <HDR modelSelected={modelSelected} />
                  <Exposure modelSelected={modelSelected} />
                </TabContainer>
                <TabContainer
                  id="5"
                  disabled={project?.isFirstModelLoaded}
                  title="OMBRE"
                  tooltip="ombreTest"
                >
                  <ShadowIntensity modelSelected={modelSelected} />
                  <Smooth modelSelected={modelSelected} />
                </TabContainer>
              </TabPane>

              <TabPane tabId="3">
                <TabContainer
                  id="6"
                  disabled={project?.isFirstModelLoaded}
                  title="MATERIALE"
                  tooltip="materiale"
                >
                  <SelectMaterial
                    modelViewerRef={modelViewerRef}
                    modelSelected={modelSelected}
                  />
                </TabContainer>

                <Textures
                  isGlbLoaded={isGlbLoaded}
                  modelViewerRef={modelViewerRef}
                  modelSelected={modelSelected}
                />
              </TabPane>
              <TabPane tabId="4">
                <TabContainer
                  id="7"
                  disabled={project?.isFirstModelLoaded}
                  title="Hotspot"
                  tootlip="hotspot"
                >
                  <ToggleHotspot
                    isGlbLoaded={isGlbLoaded}
                    modelViewerRef={modelViewerRef}
                    modelSelected={modelSelected}
                  />
                </TabContainer>
                <TabContainer
                  id="7"
                  disabled={project?.isFirstModelLoaded}
                  title="Hotspot Button"
                  tootlip="hotspot"
                >
                  <ToggleHotspotFix
                    isGlbLoaded={isGlbLoaded}
                    modelViewerRef={modelViewerRef}
                    modelSelected={modelSelected}
                  />
                </TabContainer>
              </TabPane>
              <TabPane tabId="5">
                <TabContainer
                  id="8"
                  disabled={project?.isFirstModelLoaded}
                  title="TRANSIZIONI"
                  tootlip="transitions"
                >
                  <Transitions modelSelected={modelSelected} />
                </TabContainer>
                <TabContainer
                  id="9"
                  disabled={project?.isFirstModelLoaded}
                  title="GENERA IMMAGINE ANTEPRIMA"
                  tooltip=""
                >
                  <Screenshot
                    isGlbLoaded={isGlbLoaded}
                    modelViewerRef={modelViewerRef}
                    posterGlb={modelSelected?.poster_image}
                    project={project}
                    modelSelectedId={modelSelected?.id}
                    onClick={() => setHasPoster(true)}
                  />
                </TabContainer>
              </TabPane>
              <TabPane tabId="6">
                <TabContainer
                  id="10"
                  disabled={project?.isFirstModelLoaded}
                  title="COLLEZIONI"
                  tootlip="transitions"
                >
                  a project view
                  {modelList.map((model, i) => (
                    <ProjectDashBoardCard
                      title={model.title}
                      poster={model.poster_image}
                      isModelCard={true}
                      onClickEditTitle={() =>
                        modalRenameHandler(model, project)
                      }
                      onClickDuplicate={() => duplicateModel(model, project)}
                      onClickEdit={() => setEditModel(model, project)}
                      onClickDelete={() => removeModel(model, project)}
                      key={nanoid()}
                    ></ProjectDashBoardCard>
                  ))}
                  <div>
                    <button
                      className={stylesButton.button}
                      onClick={handleNewModel}
                    >
                      Add New Model
                    </button>
                  </div>
                  {modalRename && (
                    <Modal className={styleModal['modal-rename']}>
                      <div>
                        <Label>Change project name</Label>
                        <Input
                          type="text"
                          maxLength="30"
                          onChange={(e) => setModelName(e.target.value)}
                          // defaultValue={props.project?.title}
                        />
                        <Button onClick={rename}>Submit</Button>
                      </div>
                    </Modal>
                  )}
                  {modalRename && (
                    <BackDrop
                      className={stylesBackDrop.backdrop}
                      onCancel={() => setModalRename(false)}
                    />
                  )}
                </TabContainer>
                <TabContainer>
                  <AddNewProject project={project}></AddNewProject>
                </TabContainer>
              </TabPane>
              <TabPane tabId="7">
                <TabContainer
                  id="11"
                  disabled={project?.isFirstModelLoaded}
                  title="test"
                  tootlip="Impostazioni Progetto"
                >
                  <ImpostazioniProgetto
                    selectedProject={project}
                  ></ImpostazioniProgetto>
                  <Image360 />
                </TabContainer>
              </TabPane>

              <TabPane tabId="8">
                <TabContainer
                  id="12"
                  disabled={project?.isFirstModelLoaded}
                  title="AR"
                  tootlip="AR transitions"
                >
                  <QRCode project={project} />
                </TabContainer>
              </TabPane>

              <TabPane tabId="9">
                <div>edit Menu</div>
                <SelectedMenuOrientation
                  project={project}
                ></SelectedMenuOrientation>
              </TabPane>
            </TabContent>
          </Col>
          <Col className={`col-md-1 ${styles['no-padding']}`}>
            <RightColumn
              disabled={!project?.isFirstModelLoaded}
              tab={(t) => setProjectTab(t)}
              saveHandler={() => saveHandler()}
            />
          </Col>
        </Row>
      </Container>
    </Fragment>
  )
}

export default EditModel
