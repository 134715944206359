
import React, { useState } from "react";
import { useSelector } from "react-redux";
import styles from "./HotspotView.module.css";

function HotspotView(props) {

  const [contentIsView , setContentIsView] = useState(false);

  function actionOnclick(){
    setContentIsView(!contentIsView);
  }

  return (

    <button
    key={Math.random()}
    slot={props.button.slot}
    className={styles[`${props.button.class}`]}
    style={{
      backgroundColor:`${props.button.shape_color}`
    }}
    data-position={props.button.position}
    data-normal={props.button.normal}
    onClick={actionOnclick}
     >
      {contentIsView && (
        <div className={styles["hotspot-container"]} dangerouslySetInnerHTML={{__html:props.button.description}} 
        style={{
          backgroundColor:`${props.button.contentBackgroundColor}`,
          border: `${props.button.borderContentSize} solid ${props.button.borderContentColor}`,
          borderRadius: `${props.button.borderContentRadius}`,
          
          }} >
      
    </div>
      )}
    
    
  </button>
  );
}

export default HotspotView;