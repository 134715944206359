import React, { createRef, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
// import { useTranslation } from "react-i18next";
import { modelActions } from "../redux/model";
import { Label } from "reactstrap";
import styles from "../assets/css/OutputSlider.module.css";
import NumberFactor from "../component/ui/InputComponent/NumberFactor";
import InputSliderRow from "../component/ui/InputComponent/InputSliderRow";

function Metalness(props) {
  const dispatch = useDispatch();

  const newMaterialList = [];

  const listOfMaterial = props.modelSelected.list_material_two;
  // const { t } = useTranslation();

  const materialsViewer =
    document.querySelector("model-viewer").model?.materials;

  const selectedMaterialViewer = materialsViewer?.find(
    (mat) => mat.name === props.materialSelected?.name
  );

  //const metalnessGlb = useSelector((state) => state.model.metalness);

  useEffect(() => {
    // const metalness = props.materialSelected.metallicFactor;
    // console.log(metalness);
    // selectedMaterialViewer.pbrMetallicRoughness.setMetallicFactor(metalness)
  }, []);

  // let value = props.materialSelected?.metallicFactor;
  // function setValue(v) {
  //   value = v;
  // }

  const [value, setValue] = useState(props.materialSelected?.metallicFactor);

  function metalnessHandlder(event) {
    setValue(event.target.value);

    selectedMaterialViewer.pbrMetallicRoughness.setMetallicFactor(value);

    listOfMaterial.map((material) => {
      if (material.name === props.materialSelected.name) {
        let a = {
          name: material.name,
          metallicFactor: value,
          roughnessFactor: material.roughnessFactor,
          baseColorFactor: material.baseColorFactor,
          baseColorImage: material.baseColorImage,
          metallicRoughnessImage: material.metallicRoughnessImage,
          emissiveImage: material.emissiveImage,
          normalImage: material.normalImage,
          occlusionImage: material.occlusionImage,
        };
        newMaterialList.push(a);
      } else {
        let b = {
          name: material.name,
          metallicFactor: material.metallicFactor,
          roughnessFactor: material.roughnessFactor,
          baseColorFactor: material.baseColorFactor,
          baseColorImage: material.baseColorImage,
          metallicRoughnessImage: material.metallicRoughnessImage,
          emissiveImage: material.emissiveImage,
          normalImage: material.normalImage,
          occlusionImage: material.occlusionImage,
        };
        newMaterialList.push(b);
      }
    });

    dispatch(
      modelActions.updateListMaterial({
        idModel: props.modelSelected.id,
        value: newMaterialList,
      })
    );

  }

  useEffect(() => {
    setValue(props.materialSelected?.metallicFactor)
  }, [props.materialSelected.name])


  return (
    <div>
      <div style={{ display: "flex" }}>
        <InputSliderRow
          id="metalness"
          maxValue={1}
          minValue={0}
          step={0.1}
          value={value}
          onChange={metalnessHandlder}
        ></InputSliderRow>
      </div>
    </div>
  );
}

export default Metalness;
