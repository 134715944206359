import React, { useState } from "react";
import {
  Container,
  Row,
  Col,
  CardBody,
  Form,
  FormGroup,
  Input,
  Label,
  Button,
} from "reactstrap";
import Cookies from "js-cookie";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { authActions } from "../redux/auth";
import { Link, useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import LogoSecondary from "../assets/images/logo/LogoSecondary.png";
import { firestore } from "../utility/firebase";
import { USER } from "../model/user";
import { auth } from "../utility/firebase";
import { useAuth } from "../utility/AuthContext";

// export let userLogData = null;

function LoginRedux(props) {
  const history = useHistory();

  const dispatch = useDispatch();
  const { currentUser } = useAuth();
  // const isAuth = useSelector((state) => state.auth.isAuthenticated);
  const userName = useSelector((state) => state.auth.userName);
  const userLastName = useSelector((state) => state.auth.userLastName);
  const userEmail = useSelector((state) => state.auth.userEmail);
  const userPassword = useSelector((state) => state.auth.userPassword);
  const hasCompany = useSelector((state) => state.auth.isCompany);
  const companyName = useSelector((state) => state.auth.companyName);
  const companyVat = useSelector((state) => state.auth.companyVat);
  // const userRegisterData = useSelector((state) => state.auth.userRegisterData);

  const [isCompany, setIsCompany] = useState(false);
  const [isTermsAccepted, setIsTermsAccepted] = useState(false);

  const [confirmPasswordRegister, setConfirmPasswordRegister] = useState("");
  // eslint-disable-next-line
  const [loading, setLoading] = useState(false);

  const toggleForm = () => {
    document.querySelector(".cont").classList.toggle("s--signup");
  };

  function addUserOnFirebaseCollection() {
    USER.name = userName;
    USER.lastName = userLastName;
    USER.email = userEmail;
    USER.password = userPassword;
    USER.subscriptionDate = new Date();
    USER.isCompany = hasCompany;
    USER.vat = companyVat;
    USER.companyName = companyName;
    USER.address = null;
    USER.navigator = navigator.userAgent;
    USER.project_list = [];
    dispatch(
      authActions.setUserRegisterData({
        name: userName,
        lastName: userLastName,
        email: userEmail,
        password: userPassword,
        subscriptionDate: new Date(),
        isCompany: hasCompany,
        vat: companyVat,
        companyName: companyName,
        address: null,
        navigator: navigator.userAgent,
        project_list: [],
      })
    );
    firestore
      .collection("Account")
      .doc(userEmail)
      .set(USER)
      .then(() => {
        console.log("data stored in database with success");
      })
      .catch((error) => console.log(error.message));

    firestore.collection("Log_accessi").doc().set({
      account: userEmail,
      listaLog: [],
    });
  }

  async function RegisterAuth(event) {
    event.preventDefault();

    if (userPassword !== confirmPasswordRegister) {
      toast.error("Passwords are not equal");
      return;
    }
    if (!isTermsAccepted) {
      toast.error("Please accept the therms of privacy!");
      return;
    }
    try {
      await auth.createUserWithEmailAndPassword(userEmail, userPassword);
      await addUserOnFirebaseCollection();
    } catch (error) {
      toast.error(error.message);
      console.log(error.message);
      return;
    }
    Cookies.set("email", userEmail);
    history.push(`${process.env.PUBLIC_URL}/activate-account`);
  }

  function readUserFromFirebase() {
    // works
    firestore
      .collection("Account")
      .get()
      .then((snapshot) => {
        snapshot.forEach((doc) => {
          if (doc.id === userEmail) {
            dispatch(authActions.setUserLogData(doc.data()));
          }
        });
      });
  }

  async function writeLoginAccess() {
    let docRef = null;

    await firestore
      .collection("Log_accessi")
      .where("account", "==", userEmail)
      .get()
      .then((docs) => {
        docs.forEach((doc) => {
          docRef = doc.ref;
        });
      })
      .catch((error) => {
        console.log(error.message);
      });

    firestore.runTransaction((transaction) => {
      return transaction.get(docRef).then((tDoc) => {
        if (!tDoc.exists) {
          console.log("not exists");
          return;
        }
        let logAccess = tDoc.data().listaLog;
        let newLog = {
          data: new Date(),
          navigator: navigator.userAgent,
        };
        logAccess.push(newLog);
        transaction.update(docRef, { listaLog: logAccess });
      });
    });
  }

  const loginAuth = async (event) => {
    event.preventDefault();
    setLoading(true);
    try {
      await auth.signInWithEmailAndPassword(userEmail, userPassword);
      await writeLoginAccess();
      await readUserFromFirebase();
    } catch (error) {
      console.log("error:", error);
      setTimeout(() => {
        toast.error(
          `Oppss.. The password is invalid or the user does not have a password.`
        );
        console.log(error.message);
      }, 200);
    }
    Cookies.set("isAuth", true);
    Cookies.set("email", userEmail);
    history.push(`${process.env.PUBLIC_URL}/dashboard/project`);

    sessionStorage.setItem("userLog", {
      data: new Date(),
      navigator: navigator.userAgent,
    });
  };

  //console.log("token user => ", currentUser.uid);

  return (
    <div className="page-wrapper">
      <Container fluid={true} className="p-0">
        {/*  <!-- login page start--> */}
        <div className="authentication-main m-0 only-login">
          <Row>
            <Col md="12">
              <div className="auth-innerright">
                <div className="authentication-box">
                  <CardBody className="h-100-d-center">
                    <div className="cont text-center b-light">
                      <div>
                        <Form className="theme-form">
                          <div className="m-45"></div>
                          <div className="m-45"></div>
                          <h4>LOGIN</h4>
                          <h6>Enter your Username and Password</h6>
                          <div className="m-45"></div>
                          <FormGroup>
                            <Label className="col-form-label pt-0">Email</Label>
                            <input
                              className="form-control"
                              type="email"
                              name="email"
                              //defaultValue={userEmail}
                              onChange={(e) =>
                                dispatch(
                                  authActions.setUserEmail(e.target.value)
                                )
                              }
                              placeholder="Email address"
                              required
                            />
                          </FormGroup>
                          <FormGroup>
                            <Label className="col-form-label">Password</Label>
                            <input
                              className="form-control"
                              type="password"
                              name="password"
                              onChange={(e) =>
                                dispatch(
                                  authActions.setUserPassword(e.target.value)
                                )
                              }
                              placeholder="Password"
                              required
                            />
                          </FormGroup>
                          <div className="checkbox p-0">
                            <Input id="checkbox1" type="checkbox" />
                            <Label for="checkbox1">Remember me</Label>
                          </div>
                          <div>
                            <Link
                              to={`${process.env.PUBLIC_URL}/recover-password`}
                            >
                              Forgot your password?
                            </Link>
                          </div>
                          <FormGroup className="form-row mt-3 mb-0">
                            <Button
                              color="primary btn-block"
                              onClick={(event) => loginAuth(event)}
                            >
                              LOGIN
                            </Button>
                          </FormGroup>
                          <div className="m-45"></div>
                          <div className="m-45"></div>
                          <div className="m-45"></div>
                          <div className="logo-wrapper">
                            By
                            <Link to="/default/sample-page">
                              <img width="150px" src={LogoSecondary} alt="aa" />
                            </Link>
                          </div>
                          <div className="m-45"></div>
                        </Form>
                      </div>
                      <div className="sub-cont">
                        <div className="img">
                          <div className="img__text m--up">
                            <h2>New User?</h2>
                            <p>
                              Sign up and discover great amount of new
                              opportunities!
                            </p>
                          </div>
                          <div className="img__text m--in">
                            <h2>One of us?</h2>
                            <p>
                              If you already has an account, just sign in. We've
                              missed you!
                            </p>
                          </div>
                          <div className="img__btn" onClick={toggleForm}>
                            <span className="m--up">Sign up</span>
                            <span className="m--in">Sign in</span>
                          </div>
                        </div>
                        <div>
                          <Form className="theme-form" onSubmit={RegisterAuth}>
                            <h4>NEW USER</h4>
                            <h6>Enter your Username and Password For Signup</h6>
                            <div className="m-45"></div>
                            <Row form>
                              <Col md="6">
                                <FormGroup>
                                  <Label className="col-form-label pt-0">
                                    Your name
                                  </Label>
                                  <input
                                    className="form-control"
                                    type="text"
                                    placeholder="First Name"
                                    onChange={(e) =>
                                      dispatch(
                                        authActions.setUserName(e.target.value)
                                      )
                                    }
                                    required
                                  />
                                </FormGroup>
                              </Col>
                              <Col md="6">
                                <FormGroup>
                                  <Label className="col-form-label pt-0">
                                    Last Name
                                  </Label>
                                  <input
                                    className="form-control"
                                    type="text"
                                    placeholder="Last Name"
                                    onChange={(e) =>
                                      dispatch(
                                        authActions.setUserLastName(
                                          e.target.value
                                        )
                                      )
                                    }
                                    required
                                  />
                                </FormGroup>
                              </Col>
                            </Row>

                            <FormGroup>
                              <input
                                className="form-control"
                                type="email"
                                name="email"
                                //defaultValue={userEmail}
                                placeholder="Email address"
                                onChange={(e) =>
                                  dispatch(
                                    authActions.setUserEmail(e.target.value)
                                  )
                                }
                                required
                              />
                            </FormGroup>
                            <Row form>
                              <Col md="6">
                                <FormGroup>
                                  <input
                                    className="form-control"
                                    type="password"
                                    placeholder="Password"
                                    onChange={(e) =>
                                      dispatch(
                                        authActions.setUserPassword(
                                          e.target.value
                                        )
                                      )
                                    }
                                    required
                                  />
                                </FormGroup>
                              </Col>
                              <Col md="6">
                                <FormGroup>
                                  <input
                                    className="form-control"
                                    type="password"
                                    placeholder="Repeat Password"
                                    onChange={(e) =>
                                      setConfirmPasswordRegister(e.target.value)
                                    }
                                    required
                                  />
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row form>
                              <Col md="12">
                                <FormGroup>
                                  <div className="checkbox checkbox-primary">
                                    <Input
                                      className="checkbox_animated"
                                      id="checkbox2"
                                      type="checkbox"
                                      onChange={() => {
                                        dispatch(authActions.isCompany());
                                        setIsCompany(true);
                                      }}
                                    />
                                    <Label for="checkbox2">
                                      Register as a company
                                    </Label>
                                  </div>
                                </FormGroup>
                              </Col>
                            </Row>
                            {isCompany && (
                              <div>
                                <Row>
                                  <Col md="6">
                                    <FormGroup>
                                      <Label>Company name</Label>
                                      <input
                                        className="form-control"
                                        type="text"
                                        onChange={(e) =>
                                          dispatch(
                                            authActions.setCompanyName(
                                              e.target.value
                                            )
                                          )
                                        }
                                      />
                                    </FormGroup>
                                  </Col>
                                  <Col md="6">
                                    <FormGroup>
                                      <Label>VAT ID</Label>
                                      <input
                                        className="form-control"
                                        type="text"
                                        onChange={(e) =>
                                          dispatch(
                                            authActions.setCompanyVat(
                                              e.target.value
                                            )
                                          )
                                        }
                                      />
                                    </FormGroup>
                                  </Col>
                                </Row>
                              </div>
                            )}
                            <Row form>
                              <Col md="12">
                                <FormGroup>
                                  <div className="checkbox checkbox-primary">
                                    <Input
                                      id="privacy-policy"
                                      type="checkbox"
                                      onChange={() =>
                                        setIsTermsAccepted(!isTermsAccepted)
                                      }
                                      required
                                    />
                                    <Label for="privacy-policy">
                                      I accept the terms of service and privacy
                                      policy
                                    </Label>
                                  </div>
                                </FormGroup>
                              </Col>
                            </Row>
                            <Row form>
                              <Col sm="4">
                                <Button color="primary">Sign Up</Button>
                              </Col>
                              <Col sm="8">
                                <div className="text-left mt-2 m-l-20">
                                  Are you already user? 
                                  <a
                                    className="btn-link text-capitalize"
                                    href="login.html"
                                  >
                                    Login
                                  </a>
                                </div>
                              </Col>
                            </Row>
                          </Form>
                        </div>
                      </div>
                    </div>
                  </CardBody>
                </div>
              </div>
            </Col>
          </Row>
        </div>
        <ToastContainer />
        {/* <!-- login page end--> */}
      </Container>
    </div>
  );
}

export default LoginRedux;
