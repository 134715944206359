import styles from "../ui/AddFirstProjectDashboard.module.css";
import React, { Children } from "react";

export default function AddFirstProjectDashboard(props) {
  return (
    <div>
      <div className={styles.elem1}>
        <div className={styles.group68}>{props.children}</div>
      </div>
    </div>
  );
}
