import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Label } from "reactstrap";
import Metalness from "../../actions/Metalness";
import Roughness from "../../actions/Roughness";
import NotImage from "../../assets/images/not-texture.png";
import SubtitleTab from "../../component/ui/SubtitleTab";
import { modelActions } from "../../redux/model";
import { changeTexture } from "../../utility/function/textureChange";
import InputImageRow from "../../component/ui/InputComponent/InputImageRow";

function MetallicRoughnessTexture(props) {
  const dispatch = useDispatch();

  const idConfig = useSelector((state) =>
    state.config.find((config) => config.id === "general")
  );

  const project = useSelector((state) =>
    state.project.find((project) => project.id === idConfig.projectOnEdit)
  );

  const newMaterialList = [];
  const listOfMaterial = props?.modelSelected?.list_material_two;

  const materialsViewer =
    document.querySelector("model-viewer").model?.materials;

  const selectedMaterialViewer = materialsViewer?.find(
    (mat) => mat.name === props.materialSelected?.name
  );

  async function changeTextureHandler(event) {
    if (!event.target.files[0]) {
      return;
    }

    const url = await changeTexture(
      "metallicRoughnessTexture",
      selectedMaterialViewer,
      event.target.files[0],
      props.modelSelected,
      project
    );

    listOfMaterial.map((material) => {
      if (material.name === props.materialSelected.name) {
        let a = {
          name: material.name,
          metallicFactor: material.metallicFactor,
          roughnessFactor: material.roughnessFactor,
          baseColorFactor: material.baseColorFactor,
          emissiveFactor: material.emissiveFactor,
          baseColorImage: material.baseColorImage,
          metallicRoughnessImage: url,
          emissiveImage: material.emissiveImage,
          normalImage: material.normalImage,
          occlusionImage: material.occlusionImage,
        };
        newMaterialList.push(a);
      } else {
        let b = {
          name: material.name,
          metallicFactor: material.metallicFactor,
          roughnessFactor: material.roughnessFactor,
          baseColorFactor: material.baseColorFactor,
          emissiveFactor: material.emissiveFactor,
          baseColorImage: material.baseColorImage,
          metallicRoughnessImage: material.metallicRoughnessImage,
          emissiveImage: material.emissiveImage,
          normalImage: material.normalImage,
          occlusionImage: material.occlusionImage,
        };
        newMaterialList.push(b);
      }
    });

    dispatch(
      modelActions.updateListMaterial({
        idModel: props.modelSelected.id,
        value: newMaterialList,
      })
    );
  }

  async function defaultThumb() {
    if (!props.originalTxt?.source) {
      return null;
    }
    const promise = await props.originalTxt.source?.createThumbnail(256, 256);
    return promise;
  }

  async function restoreTexture() {
    
    const savedTxt = props.originalTxt;
    selectedMaterialViewer.pbrMetallicRoughness.metallicRoughnessTexture.setTexture(
      savedTxt
    );

    const thumb = await defaultThumb();
    listOfMaterial.map((material) => {
      if (material.name === props.materialSelected.name) {
        newMaterialList.push({
          name: material.name,
          metallicFactor: material.metallicFactor,
          roughnessFactor: material.roughnessFactor,
          baseColorFactor: material.baseColorFactor,
          emissiveFactor: material.emissiveFactor,
          baseColorImage: material.baseColorImage,
          metallicRoughnessImage: thumb ? thumb : NotImage,
          emissiveImage: material.emissiveImage,
          normalImage: material.normalImage,
          occlusionImage: material.occlusionImage,
        });
      } else {
        newMaterialList.push({
          name: material.name,
          metallicFactor: material.metallicFactor,
          roughnessFactor: material.roughnessFactor,
          baseColorFactor: material.baseColorFactor,
          emissiveFactor: material.emissiveFactor,
          baseColorImage: material.baseColorImage,
          metallicRoughnessImage: material.metallicRoughnessImage,
          emissiveImage: material.emissiveImage,
          normalImage: material.normalImage,
          occlusionImage: material.occlusionImage,
        });
      }
    });
    dispatch(
      modelActions.updateListMaterial({
        idModel: props.modelSelected.id,
        value: newMaterialList,
      })
    );
  }

  function removeTexture() {
    selectedMaterialViewer.pbrMetallicRoughness.metallicRoughnessTexture.setTexture(
      null
    );

    listOfMaterial.map((material) => {
      if (material.name === selectedMaterialViewer.name) {
        let a = {
          name: material.name,
          metallicFactor: material.metallicFactor,
          roughnessFactor: material.roughnessFactor,
          baseColorFactor: material.baseColorFactor,
          emissiveFactor: material.emissiveFactor,
          baseColorImage: material.baseColorImage,
          metallicRoughnessImage: NotImage,
          emissiveImage: material.emissiveImage,
          normalImage: material.normalImage,
          occlusionImage: material.occlusionImage,
        };
        newMaterialList.push(a);
      } else {
        let b = {
          name: material.name,
          metallicFactor: material.metallicFactor,
          roughnessFactor: material.roughnessFactor,
          baseColorFactor: material.baseColorFactor,
          emissiveFactor: material.emissiveFactor,
          baseColorImage: material.baseColorImage,
          metallicRoughnessImage: material.metallicRoughnessImage,
          emissiveImage: material.emissiveImage,
          normalImage: material.normalImage,
          occlusionImage: material.occlusionImage,
        };
        newMaterialList.push(b);
      }
    });
    dispatch(
      modelActions.updateListMaterial({
        idModel: props.modelSelected.id,
        value: newMaterialList,
      })
    );
  }

  return (
    <div>
      <SubtitleTab title="Texture roughness" />
      <InputImageRow id={"Metallic-Roughness"} text="Image" image={props.materialSelected?.metallicRoughnessImage} onChange={changeTextureHandler} accept="image/*" remove={true} restore={true} restoreTexture={restoreTexture} removeTexture={removeTexture}></InputImageRow>

     
      <SubtitleTab title="Fattore roughness" />
      <Roughness
        modelViewerRef={props.modelViewerRef}
        // isGlbLoaded={isGlbLoaded}
        modelSelected={props.modelSelected}
        materialSelected={props.materialSelected}
      />

      <SubtitleTab title="Fattore metallic" />
      <Metalness
        modelViewerRef={props.modelViewerRef}
        // isGlbLoaded={isGlbLoaded}
        modelSelected={props.modelSelected}
        materialSelected={props.materialSelected}
      />
     
    </div>
  );
}

export default MetallicRoughnessTexture;
