import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { modelActions } from "../redux/model";
import { useTranslation } from "react-i18next";
import { Label } from "reactstrap";
import SubtitleTab from "../component/ui/SubtitleTab";
import InputSliderRow from "../component/ui/InputComponent/InputSliderRow";

function Smooth(props) {
    
  const { t } = useTranslation();

  const dispatch = useDispatch();

  function smoothHandler(event) {
    dispatch(modelActions.setShadowSmooth({idModel: props.modelSelected.id, value: event.target.value}));
  }

  return (
    <div>
      <SubtitleTab title="Durezza" />
      {/* <Label for="range3">{t("tab1_smooth")}</Label> */}
      <InputSliderRow  id={"smoot"}
          maxValue={1}
          minValue={0}
          step={0.1}
          value={props.modelSelected?.shadowSmooth}
          onChange={smoothHandler}></InputSliderRow>
    </div>
  );
}

export default Smooth;
