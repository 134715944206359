import React, { useState, useRef, useEffect } from "react";
import { useAuth } from "../utility/AuthContext";
// import { useHistory } from "react-router-dom";
import Breadcrumbs from "../component/common/breadcrumb/breadcrumb";
import { useSelector, useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { firestore } from "../utility/firebase";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";
// import DatePicker from "react-datepicker";
// import { Typeahead } from "react-bootstrap-typeahead";
import { authActions } from "../redux/auth";
import "react-bootstrap-typeahead/css/Typeahead.css";
import "react-datepicker/dist/react-datepicker.css";
import {
  Container,
  Row,
  Col,
  Card,
  CardHeader,
  CardBody,
  // TabContent,
  // TabPane,
  // Nav,
  // NavItem,
  // NavLink,
  CardFooter,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
} from "reactstrap";

function Profile() {

  const { t } = useTranslation();

  const dispatch = useDispatch();

  const { currentUser } = useAuth();

  const passwordCurrRef = useRef();
  const passwordRef = useRef();
  const passwordConfRef = useRef();
  //user data
  const [email, setEmail] = useState("");
  const [name, setName] = useState("");
  const [lastName, setLastName] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [companyVat, setCompanyVat] = useState();
  //address
  const [country, setCountry] = useState("");
  const [address, setAddress] = useState("");
  const [unit, setUnit] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [postal, setPostal] = useState("");

  const userLogData = useSelector((state) => state.auth.userLogData);
  const userRegisterData = useSelector((state) => state.auth.userRegisterData);

  useEffect(() => {
    //get user data from firebase
    firestore
      .collection("Account")
      .doc(currentUser.email)
      .get()
      .then((doc) => {
        dispatch(authActions.setUserRegisterData(doc.data()));
      });

    // console.log(userRegisterData);

    // eslint-disable-next-line
  }, []);

  async function changeUserDataHandler() {
    if (name) {
      await firestore
        .collection("Account")
        .doc(currentUser.email)
        .update({
          name: name,
        })
        .then(() => {
          console.log("data stored in database with success ", name);
          toast.success(t("success_edit_name"));
        })
        .catch((error) => {
          console.log(error.message);
          toast.error(t("error_edit_name"));
        });
    }
    if (lastName) {
      await firestore
        .collection("Account")
        .doc(currentUser.email)
        .update({
          lastName: lastName,
        })
        .then(() => {
          console.log("data stored in database with success ", lastName);
          toast.success(t("success_edit_surname"));
        })
        .catch((error) => {
          console.log(error.message);
          toast.error(t("error_edit_surname"));
        });
    }
    if (email) {
      await firestore
        .collection("Account")
        .doc(email)
        .set({
          name: userLogData.name,
          lastName: userLogData.lastName,
          email: userLogData.email,
          password: userLogData.password,
          subscriptionDate: userLogData.subscriptionDate,
          isCompany: userLogData.isCompany,
          vat: userLogData.vat,
          companyName: userLogData.companyName,
          address: null,
          navigator: userLogData.navigator,
          previuousEmail: currentUser.email,
        })
        .then(() => {
          console.log("data stored in database with success ", email);
          toast.success();
        })
        .catch((error) => console.log(error.message));
      currentUser.updateEmail(email);
      Cookies.set("email", email);
      toast.success(t("success_edit_email"));
    }
    if (companyName) {
      await firestore
        .collection("Account")
        .doc(currentUser.email)
        .update({
          companyName: companyName,
        })
        .then(() => {
          console.log("data stored in database with success ", companyName);
          toast.success(t("success_edit_companyname"));
        })
        .catch((error) => {
          console.log(error.message);
          toast.error(t("error_edit_companyname"));
        });
    }
    if (companyVat) {
      await firestore
        .collection("Account")
        .doc(currentUser.email)
        .update({ isCompany: true, vat: companyVat })
        .then(() => {
          console.log("data stored with success ", companyVat);
          toast.success(t("success_edit_companyvat"));
        })
        .catch((error) => {
          console.log(error.message);
          toast.error(t("error_edit_companyvat"));
        });
    }
  }

  async function changePasswordHandler() {
    if (userLogData.password !== passwordCurrRef.current.value) {
      toast.error(t("error_current_password"));
      return;
    }
    if (passwordRef.current.value !== passwordConfRef.current.value) {
      toast.error(t("error_edit_password"));
      return;
    } else if (
      passwordRef.current.value.length === 0 ||
      passwordConfRef.current.value.length === 0
    ) {
      toast.error(t("error_edit_password_length"));
      return;
    }
    try {
      currentUser.updatePassword(passwordRef.current.value);
      await firestore
        .collection("Account")
        .doc(currentUser.email)
        .update({
          password: passwordRef.current.value,
        })
        .then(() => {
          console.log(
            "data stored in database with success ",
            passwordRef.current.value
          );
          toast.success(t("success_edit_password"));
        });
    } catch (error) {
      toast.error(t("error_change_password"));
    }
    console.log("passsword changed to: ", passwordRef.current.value);
  }

  async function fatturaFormHandler() {
    if (!country || !address || !unit || !city || !state || !postal) {
      toast.error(t("error_empty_fields"));
      return;
    }
    try {
      await firestore
        .collection("Account")
        .doc(currentUser.email)
        .update({
          address: {
            stato: country,
            indirizzo: address,
            numero_civico: unit,
            citta: city,
            provincia: state,
            codice_postale: postal,
          },
        })
        .then(() => {
          console.log("data stored in database with success address");
          toast.success(t("success_add_address"));
        });
    } catch (error) {
      toast.error(t("error_add_address"));
    }
  }

  return (
    <React.Fragment>
      <Breadcrumbs parent="dashboard" title={t("breadtitle_user_profile")} />
      <Container className="p-fixed" fluid={true}>
        <Row>
          <Col lg="12 box-md-6" xl="6 xl-100">
            <Card>
              <CardHeader>
                <h5>{t("user_details")}</h5>
              </CardHeader>
              <CardBody>
                <Form className="theme-form">
                  <FormGroup className="row">
                    <Label
                      className="col-sm-3 col-form-label"
                      htmlFor="firstName"
                    >
                      {t("name")}
                    </Label>
                    <Col sm="9">
                      <Input
                        className="form-control btn-pill"
                        type="text"
                        placeholder="First name"
                        defaultValue={userRegisterData.name}
                        onChange={(e) => setName(e.target.value)}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup className="row">
                    <Label
                      className="col-sm-3 col-form-label"
                      htmlFor="lastName"
                    >
                      {t("surname")}
                    </Label>
                    <Col sm="9">
                      <Input
                        className="form-control btn-pill"
                        type="text"
                        placeholder="Last name"
                        defaultValue={userRegisterData.lastName}
                        onChange={(e) => setLastName(e.target.value)}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup className="row">
                    <Label
                      className="col-sm-3 col-form-label"
                      htmlFor="inputEmail3"
                    >
                      {t("email")}
                    </Label>
                    <Col sm="9">
                      <Input
                        className="form-control btn-pill"
                        type="email"
                        placeholder="Email"
                        value={currentUser.email}
                        onChange={(e) => setEmail(e.target.value)}
                        disabled
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup className="row">
                    <Label
                      className="col-sm-3 col-form-label"
                      htmlFor="inputCompany"
                    >
                      {t("company_name")}
                    </Label>
                    <Col sm="9">
                      <Input
                        className="form-control btn-pill"
                        type="text"
                        placeholder="Company name"
                        defaultValue={userRegisterData.companyName}
                        onChange={(e) => setCompanyName(e.target.value)}
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup className="row">
                    <Label
                      className="col-sm-3 col-form-label"
                      htmlFor="inputCompanyVat"
                    >
                      {t("company_vat")}
                    </Label>
                    <Col sm="9">
                      <Input
                        className="form-control btn-pill"
                        type="text"
                        placeholder="Company vat"
                        defaultValue={userRegisterData.vat}
                        onChange={(e) => setCompanyVat(e.target.value)}
                      />
                    </Col>
                  </FormGroup>
                </Form>
              </CardBody>
              <CardFooter>
                <Button
                  color="primary btn-pill"
                  className="mr-1"
                  onClick={changeUserDataHandler}
                >
                  {t("submit")}
                </Button>
              </CardFooter>
            </Card>
          </Col>
          <Col lg="12 box-md-6" xl="6 xl-100">
            <Card>
              <CardHeader>
                <h5>{t("user_password")}</h5>
              </CardHeader>
              <CardBody>
                <Form className="theme-form">
                  <FormGroup className="row">
                    <Label
                      className="col-sm-3 col-form-label"
                      htmlFor="inputPassword1"
                    >
                      {t("current_password")}
                    </Label>
                    <Col sm="9">
                      <input
                        className="form-control btn-pill"
                        type="password"
                        placeholder="Current Password"
                        ref={passwordCurrRef}
                        required
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup className="row">
                    <Label
                      className="col-sm-3 col-form-label"
                      htmlFor="inputPassword2"
                    >
                      {t("new_password")}
                    </Label>
                    <Col sm="9">
                      <input
                        className="form-control btn-pill"
                        type="password"
                        placeholder="Password"
                        ref={passwordRef}
                        required
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup className="row">
                    <Label
                      className="col-sm-3 col-form-label"
                      htmlFor="inputPassword3"
                    >
                      {t("repeat_password")}
                    </Label>
                    <Col sm="9">
                      <input
                        className="form-control btn-pill"
                        type="password"
                        placeholder="Password"
                        ref={passwordConfRef}
                        required
                      />
                    </Col>
                  </FormGroup>
                </Form>
              </CardBody>
              <CardFooter>
                <Button
                  color="primary btn-pill"
                  className="mr-1"
                  onClick={changePasswordHandler}
                >
                  {t("submit")}
                </Button>
              </CardFooter>
            </Card>
          </Col>

          {/*Addresses */}
          <Col lg="12 box-md-6" xl="6 xl-100">
            <Card>
              <CardHeader>
                <h5>{t("faturation_info")}</h5>
              </CardHeader>
              <CardBody>
                <Form className="theme-form">
                  <FormGroup className="row">
                    <Label
                      className="col-sm-3 col-form-label"
                      htmlFor="inputStato"
                    >
                      {t("country")}
                    </Label>
                    <Col sm="9">
                      <input
                        className="form-control btn-pill"
                        type="text"
                        placeholder="Country"
                        onChange={(e) => setCountry(e.target.value)}
                        defaultValue={"userLogData.address.stato"}
                        required
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup className="row">
                    <Label
                      className="col-sm-3 col-form-label"
                      htmlFor="inputAddress"
                    >
                      {t("address")}
                    </Label>
                    <Col sm="9">
                      <input
                        className="form-control btn-pill"
                        type="text"
                        placeholder="Address"
                        onChange={(e) => setAddress(e.target.value)}
                        defaultValue={"userLogData.address.indirizzo"}
                        required
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup className="row">
                    <Label
                      className="col-sm-3 col-form-label"
                      htmlFor="inputCivico"
                    >
                      {t("unit_number")}
                    </Label>
                    <Col sm="9">
                      <input
                        className="form-control btn-pill"
                        type="text"
                        placeholder="Unit number"
                        onChange={(e) => setUnit(e.target.value)}
                        defaultValue={"userLogData.address.numero_civico"}
                        required
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup className="row">
                    <Label
                      className="col-sm-3 col-form-label"
                      htmlFor="inputCitta"
                    >
                      {t("city")}
                    </Label>
                    <Col sm="9">
                      <input
                        className="form-control btn-pill"
                        type="text"
                        placeholder="City"
                        onChange={(e) => setCity(e.target.value)}
                        defaultValue={"userLogData.address.citta"}
                        required
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup className="row">
                    <Label
                      className="col-sm-3 col-form-label"
                      htmlFor="inputProvincia"
                    >
                      {t("state")}
                    </Label>
                    <Col sm="9">
                      <input
                        className="form-control btn-pill"
                        type="text"
                        placeholder="Province"
                        onChange={(e) => setState(e.target.value)}
                        defaultValue={"userLogData.address.provincia"}
                        required
                      />
                    </Col>
                  </FormGroup>
                  <FormGroup className="row">
                    <Label
                      className="col-sm-3 col-form-label"
                      htmlFor="inputPostale"
                    >
                      {t("postal_code")}
                    </Label>
                    <Col sm="9">
                      <input
                        className="form-control btn-pill"
                        type="text"
                        placeholder="Postal code"
                        onChange={(e) => setPostal(e.target.value)}
                        defaultValue={"userLogData.address.codice_postale"}
                        required
                      />
                    </Col>
                  </FormGroup>
                </Form>
              </CardBody>
              <CardFooter>
                <Button
                  color="primary btn-pill"
                  className="mr-1"
                  onClick={fatturaFormHandler}
                >
                  {t("submit")}
                </Button>
              </CardFooter>
            </Card>
          </Col>
        </Row>
      </Container>
    </React.Fragment>
  );
}

export default Profile;
