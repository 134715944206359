import React, { createRef } from "react";
import styles from "./Slider.module.css";

function Slider(props) {
  return (
    <div className={styles.inputSliderContainer}>
      <input
        className={styles.range}
        id={props.id}
        type="range"
        value={props.defaultValue}
        max={props.maxValue}
        min={props.minValue}
        step={props.step}
        onChange={props.onChange}
        disabled={props.disable}
      />
    </div>
  );
}

export default Slider;
