import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import BaseColorTexture from "./textures/BaseColorTexture";
import MetallicRoughnessTexture from "./textures/MetallicRoughnessTexture";
import EmissiveTexture from "./textures/EmissiveTexture";
import NormalTexture from "./textures/NormalTexture";
import OcclusionTexture from "./textures/OcclusionTexture";
import ContainerSelectObject from "../component/ui/ContainerSelectObject";
import SubtitleTab from "../component/ui/SubtitleTab";
import ItemSelectObject from "../component/ui/ItemSelectObject";
import TabContainer from "../component/ui/TabContainer";
import SubTabContainer from "../component/ui/SubTabContainer";

function SelectMaterial(props) {
  const dispatch = useDispatch();

  const [index, setIndex] = useState(-1);

  const modelSelected = props?.modelSelected;

  const [materialSelected, setMaterialSelected] = useState(
    modelSelected?.list_material_two[0]
  );
  const [isSelected, setMaterialIsSelected] = useState(false);

  // const [materialNameSelected , setMaterialName] = useState()

  // const materials = props.modelSelected?.list_material_two;
  const [colorFactor, setColorFactor] = useState("#ffffff");
  const [emissiveFactor, setEmissiveFactor] = useState("#ffffff");

  const [baseColorImage, setBaseColorImage] = useState();
  const [originalBaseColorTxt, setOriginalBaseColorTxt] = useState();

  const [metallicRoughnessImage, setMetallicRoughnessImage] = useState();
  const [emissiveImage, setEmissiveImage] = useState();
  const [normalImage, setNormalImage] = useState();
  const [occlusionImage, setOcclusionImage] = useState();

  const [originalMetallicTxt, setOriginalMetallicTxt] = useState();
  const [originalEmissiveTxt, setOriginalEmissiveTxt] = useState();
  const [originalNormalTxt, setOriginalNormalTxt] = useState();
  const [originalOcclusionTxt, setOriginalOcclusionTxt] = useState();

  useEffect(async () => {
    // setOriginalBaseColorTxt(
    //   modelSelected?.list_material_two[index]?.pbrMetallicRoughness
    //     ?.baseColorTexture?.texture
    // );
    // setOriginalMetallicTxt(
    //   modelSelected?.list_material_two[index]?.pbrMetallicRoughness
    //     ?.metallicRoughnessTexture?.texture
    // );
    // setOriginalEmissiveTxt(
    //   modelSelected?.list_material_two[index]?.emissiveTexture?.texture
    // );
    // setOriginalNormalTxt(
    //   modelSelected?.list_material_two[index]?.normalTexture?.texture
    // );
    // setOriginalOcclusionTxt(
    //   modelSelected?.list_material_two[index]?.occlusionTexture?.texture
    // );

    setMaterialSelected(modelSelected?.list_material_two[index]);
    // setBaseColorImage(materialSelected?.baseColorImage);
    // setMetallicRoughnessImage(materialSelected?.metallicRoughnessImage);
    // setEmissiveImage(materialSelected?.emissiveImage);
    // setNormalImage(materialSelected?.normalImage);
    // setOcclusionImage(materialSelected?.occlusionImage);
  }, [props]);

  function rgb2hex(r, g, b) {
    try {
      var rHex = parseInt(r).toString(16).padStart(2, "0");
      var gHex = parseInt(g).toString(16).padStart(2, "0");
      var bHex = parseInt(b).toString(16).padStart(2, "0");
    } catch (e) {
      return false;
    }
    if (rHex.length > 2 || gHex.length > 2 || bHex.length > 2) return false;
    return "#" + rHex + gHex + bHex;
  }

  function selectMaterials(i) {
    // console.log("select" , i, modelSelected.list_material_two[i]);
    setIndex(i);
    const r =
      document.querySelector("model-viewer").model.materials[index]
        ?.pbrMetallicRoughness?.baseColorFactor[0];
    const g =
      document.querySelector("model-viewer").model.materials[index]
        ?.pbrMetallicRoughness?.baseColorFactor[1];
    const b =
      document.querySelector("model-viewer").model.materials[index]
        ?.pbrMetallicRoughness?.baseColorFactor[2];
    const c = rgb2hex(r, g, b);
    // console.log(r,g,b);
    setColorFactor("#ffffff");
    setEmissiveFactor("#ffffff");

    setBaseColorImage(modelSelected.list_material_two[i].baseColorImage);
    setMetallicRoughnessImage(
      modelSelected.list_material_two[i].metallicRoughnessImage
    );
    setEmissiveImage(modelSelected.list_material_two[i].emissiveImage);
    setNormalImage(modelSelected.list_material_two[i].normalImage);
    setOcclusionImage(modelSelected.list_material_two[i].occlusionImage);

    // setOriginalBaseColorTxt(
    //   modelSelected.list_material_initial[i].baseColorImage
    // );
    setMaterialSelected(modelSelected.list_material_two[i]);
    setMaterialIsSelected(true);
  }

  function setColorHandler(color) {
    setColorFactor(color);
  }

  return (
    <div className="controls">
      <SubtitleTab title="Seleziona materiale" />

      <ContainerSelectObject>
        <div>
          <div>
            {props.modelSelected?.list_material_two &&
              props.modelSelected?.list_material_two.map((material, i) => {
                return (
                  <ItemSelectObject
                    title={material.name || `material ${i+1}`}
                    key={i}
                    index={i}
                    onSelectClick={() => selectMaterials(i)}
                    onActive={material?.name && material?.name === materialSelected?.name}
                  />
                );
              })}
          </div>
        </div>
      </ContainerSelectObject>
      {isSelected && (
        <div>
          <SubTabContainer id="mat-1" title="BASE COLOR" tooltip="base color">
            <BaseColorTexture
              modelViewerRef={props.modelViewerRef}
              // isGlbLoaded={isGlbLoaded}
              color={colorFactor}
              // setColor={(color) => setColorHandler(color)}
              modelSelected={props.modelSelected}
              materialSelected={materialSelected}
              materialIndex={index}
              image={baseColorImage}
              originalTxt={originalBaseColorTxt}
              // originalImage={materialSelected?.baseColorImage}
            />
          </SubTabContainer>
          <SubTabContainer
            id="mat-2"
            title="METALLIC ROUGHNESS"
            tooltip="metallness roughness"
          >
            <MetallicRoughnessTexture
              modelViewerRef={props.modelViewerRef}
              // isGlbLoaded={isGlbLoaded}
              color={emissiveFactor}
              modelSelected={props.modelSelected}
              materialSelected={materialSelected}
              materialIndex={index}
              image={metallicRoughnessImage}
              originalTxt={originalMetallicTxt}
            />
          </SubTabContainer>
          <SubTabContainer id="mat-3" title="EMISSION" tooltip="emissive">
            <EmissiveTexture
              modelViewerRef={props.modelViewerRef}
              // isGlbLoaded={isGlbLoaded}
              modelSelected={props.modelSelected}
              materialSelected={materialSelected}
              materialIndex={index}
              image={emissiveImage}
              originalTxt={originalEmissiveTxt}
            />
          </SubTabContainer>
          <SubTabContainer id="mat-4" title="NORMAL" tooltip="normal map">
            <NormalTexture
              modelViewerRef={props.modelViewerRef}
              // isGlbLoaded={isGlbLoaded}
              modelSelected={props.modelSelected}
              materialSelected={materialSelected}
              materialIndex={index}
              image={normalImage}
              originalTxt={originalNormalTxt}
            />
          </SubTabContainer>
          <SubTabContainer
            id="mat-5"
            title="OCCLUSION"
            tooltip="occlusion texture"
          >
            <OcclusionTexture
              modelViewerRef={props.modelViewerRef}
              // isGlbLoaded={isGlbLoaded}
              modelSelected={props.modelSelected}
              materialSelected={materialSelected}
              materialIndex={index}
              image={occlusionImage}
              originalTxt={originalOcclusionTxt}
            />
          </SubTabContainer>
        </div>
      )}
    </div>
  );
}

export default SelectMaterial;
