import React from 'react'
import styles from './Modal.module.css';

function ModalLoading(props) {
  return (
    <div className={styles["modal-loading"]}>
      <div className={styles["modal-dialog"]}>
        <div className={styles["modal-body"]}>{props.children}</div>
      </div>
    </div>
  )
}

export default ModalLoading