import React, { useState, useEffect } from "react";
import { Label } from "reactstrap";
import Slider from "../../../component/ui/InputComponent/Slider";
import ColumSubTab from "../../../component/ui/InputComponent/ColumSubTab";
import RowSubTab from "../../../component/ui/InputComponent/RowSubTab";
import styles from "../../../assets/css/Transitions.module.css";
import icoSx from "../../../assets/ico/icona-sinistra.svg";
import icoSxSel from "../../../assets/ico/icona-sinistra-selezion.svg";
import icoDx from "../../../assets/ico/icona-destra.svg";
import icoDxSel from "../../../assets/ico/icona-destra-selezionato.svg";
import icoBasso from "../../../assets/ico/icona-basso.svg";
import icoBassoSel from "../../../assets/ico/icona-bsso-selezionato.svg";
import icoAlto from "../../../assets/ico/icona-alto.svg";
import icoAltoSel from "../../../assets/ico/icona-alto-selezionato.svg";

function Transitions(props) {

  const [time, setTime] = useState(1);
  
  const [selectedSvg1, setSelectedSvg1] = useState(false);
  const [selectedSvg2, setSelectedSvg2] = useState(false);
  const [selectedSvg3, setSelectedSvg3] = useState(false);
  const [selectedSvg4, setSelectedSvg4] = useState(false);
  //
  const [selectedSvgU1, setSelectedSvgU1] = useState(false);
  const [selectedSvgU2, setSelectedSvgU2] = useState(false);
  const [selectedSvgU3, setSelectedSvgU3] = useState(false);
  const [selectedSvgU4, setSelectedSvgU4] = useState(false);

  function timeHandler(event) {
    setTime(event.target.value);
  }

  function transitionSpostaDx(event) {
    event.preventDefault();

    setSelectedSvg1(true);
    setSelectedSvg2(false);
    setSelectedSvg3(false);
    setSelectedSvg4(false);

    const { x, y } = document.querySelector("model-viewer").getDimensions();
    // console.log(document.querySelector("model-viewer").cameraTarget);
    try {
      document.querySelector("model-viewer").cameraOrbit = "0deg 75deg 105%";
      document.querySelector("model-viewer").cameraControls = false;
      document.querySelector("model-viewer").autoRotate = false;
    } catch {}

    // console.log("dimension:", x, y);

    let puntoIniziale = 50;
    let puntoFinale = 0;
    let tempo = time * 1000;
    let contatore = 0;
    let velocitaMedia = (puntoFinale - puntoIniziale) / tempo;
    let step = 100;

    const interval = setInterval(() => {
      contatore++;
      let currentPosition =
        puntoIniziale - Math.abs(velocitaMedia) * (step * contatore);
      if (currentPosition === puntoFinale) {
        document.querySelector("model-viewer").cameraTarget = `auto auto auto`;
        document.querySelector("model-viewer").cameraControls =
          props.modelSelected.cameraControls;
        clearInterval(interval);
      }
      document.querySelector(
        "model-viewer"
      ).cameraTarget = `${currentPosition}m auto auto`;
    }, step);
  }

  function transitionSpostaSx(event) {
    event.preventDefault();

    setSelectedSvg1(false);
    setSelectedSvg2(true);
    setSelectedSvg3(false);
    setSelectedSvg4(false);

    const { x, y } = document.querySelector("model-viewer").getDimensions();
    // console.log(document.querySelector("model-viewer").cameraTarget);
    try {
      document.querySelector("model-viewer").cameraOrbit = "0deg 75deg 105%";
      document.querySelector("model-viewer").cameraControls = false;
      document.querySelector("model-viewer").autoRotate = false;
    } catch {}

    // console.log("dimension:", x, y);

    let puntoIniziale = 50;
    let puntoFinale = 0;
    let tempo = time * 1000;
    let contatore = 0;
    let velocitaMedia = (puntoFinale - puntoIniziale) / tempo;
    let step = 100;

    const interval = setInterval(() => {
      contatore++;
      let currentPosition =
        puntoIniziale - Math.abs(velocitaMedia) * (step * contatore);

      if (currentPosition === puntoFinale) {
        document.querySelector("model-viewer").cameraTarget = `auto auto auto`;
        document.querySelector("model-viewer").cameraControls =
          props.modelSelected.cameraControls;
        clearInterval(interval);
      }
      document.querySelector(
        "model-viewer"
      ).cameraTarget = `-${currentPosition}m auto auto`;
    }, step);
  }

  function transitionSpostaAlto(event) {
    event.preventDefault();

    setSelectedSvg1(false);
    setSelectedSvg2(false);
    setSelectedSvg3(true);
    setSelectedSvg4(false);

    const { x, y } = document.querySelector("model-viewer").getDimensions();
    // console.log(document.querySelector("model-viewer").cameraTarget);
    try {
      document.querySelector("model-viewer").cameraOrbit = "0deg 75deg 105%";
      document.querySelector("model-viewer").cameraControls = false;
      document.querySelector("model-viewer").autoRotate = false;
    } catch {}

    // console.log("dimension:", x, y);

    let puntoIniziale = 50;
    let puntoFinale = 0;
    let tempo = time * 1000;
    let contatore = 0;
    let velocitaMedia = (puntoFinale - puntoIniziale) / tempo;
    let step = 100;

    const interval = setInterval(() => {
      contatore++;
      let currentPosition =
        puntoIniziale - Math.abs(velocitaMedia) * (step * contatore);

      if (currentPosition === puntoFinale) {
        document.querySelector("model-viewer").cameraTarget = `auto auto auto`;
        document.querySelector("model-viewer").cameraControls =
          props.modelSelected.cameraControls;
        clearInterval(interval);
      }
      document.querySelector(
        "model-viewer"
      ).cameraTarget = `auto -${currentPosition}m auto`;
    }, step);
  }

  function transitionSpostaBasso(event) {
    event.preventDefault();

    setSelectedSvg1(false);
    setSelectedSvg2(false);
    setSelectedSvg3(false);
    setSelectedSvg4(true);

    const { x, y } = document.querySelector("model-viewer").getDimensions();
    // console.log(document.querySelector("model-viewer").cameraTarget);
    try {
      document.querySelector("model-viewer").cameraOrbit = "0deg 75deg 105%";
      document.querySelector("model-viewer").cameraControls = false;
      document.querySelector("model-viewer").autoRotate = false;
    } catch {}

    // console.log("dimension:", x, y);

    let puntoIniziale = 50;
    let puntoFinale = 0;
    let tempo = time * 1000;
    let contatore = 0;
    let velocitaMedia = (puntoFinale - puntoIniziale) / tempo;
    let step = 100;

    const interval = setInterval(() => {
      contatore++;
      let currentPosition =
        puntoIniziale - Math.abs(velocitaMedia) * (step * contatore);

      if (currentPosition === puntoFinale) {
        document.querySelector("model-viewer").cameraTarget = `auto auto auto`;
        document.querySelector("model-viewer").cameraControls =
          props.modelSelected.cameraControls;
        clearInterval(interval);
      }
      document.querySelector(
        "model-viewer"
      ).cameraTarget = `auto ${currentPosition}m auto`;
    }, step);
  }

  function uscitaDx(event) {
    event.preventDefault();

    setSelectedSvgU1(true);
    setSelectedSvgU2(false);
    setSelectedSvgU3(false);
    setSelectedSvgU4(false);

    const { x, y } = document.querySelector("model-viewer").getDimensions();

    try {
      document.querySelector("model-viewer").cameraOrbit = "0deg 75deg 105%";
      document.querySelector("model-viewer").cameraControls = false;
      document.querySelector("model-viewer").autoRotate = false;
    } catch {}

    //to do

    let puntoIniziale = 50;
    let puntoFinale = 0;
    let tempo = time * 1000;
    let contatore = 0;
    let velocitaMedia = (puntoFinale - puntoIniziale) / tempo;
    let step = 100;

    const interval = setInterval(() => {
      contatore++;
      let currentPosition =
        puntoIniziale - Math.abs(velocitaMedia) * (step * contatore);
      if (currentPosition === puntoFinale) {
        document.querySelector("model-viewer").cameraTarget = `auto auto auto`;
        document.querySelector("model-viewer").cameraControls =
          props.modelSelected.cameraControls;
        clearInterval(interval);
      }
      document.querySelector(
        "model-viewer"
      ).cameraTarget = `-${currentPosition}m auto auto`;
    }, step);
  }

  function uscitaSx(event) {
    event.preventDefault();

    setSelectedSvgU1(false);
    setSelectedSvgU2(true);
    setSelectedSvgU3(false);
    setSelectedSvgU4(false);
  }

  function uscitaAlto(event) {
    event.preventDefault();

    setSelectedSvgU1(false);
    setSelectedSvgU2(false);
    setSelectedSvgU3(true);
    setSelectedSvgU4(false);
  }

  function uscitaBasso(event) {
    event.preventDefault();

    setSelectedSvgU1(false);
    setSelectedSvgU2(false);
    setSelectedSvgU3(false);
    setSelectedSvgU4(true);
  }

  return (
    <div>
      <div>
        <Label for="tempo">Set Time</Label>
        <Slider
          id="tempo"
          maxValue={4}
          minValue={0.5}
          defaultValue={time}
          value={time}
          step={0.5}
          onChange={timeHandler}
        />
      </div>
      <ColumSubTab>
        <RowSubTab>
          <p>Transizione in entrata</p>
        </RowSubTab>
        <RowSubTab>
          <div>
            <img
              className={styles.ico}
              src={!selectedSvg1 ? icoSx : icoSxSel}
              onClick={transitionSpostaDx}
            />
          </div>
          <div>
            <img
              className={styles.ico}
              src={`${!selectedSvg2 ? icoDx : icoDxSel}`}
              onClick={transitionSpostaSx}
            />
          </div>
          <div>
            <img
              className={styles.ico}
              src={`${!selectedSvg3 ? icoBasso : icoBassoSel}`}
              onClick={transitionSpostaAlto}
            />
          </div>
          <div>
            <img
              className={styles.ico}
              src={`${!selectedSvg4 ? icoAlto : icoAltoSel}`}
              onClick={transitionSpostaBasso}
            />
          </div>
        </RowSubTab>
      </ColumSubTab>

      <ColumSubTab>
        <RowSubTab>
          <p>Transizione in uscita</p>
        </RowSubTab>
        <RowSubTab>
          <div>
            <img
              className={styles.ico}
              src={`${!selectedSvgU1 ? icoSx : icoSxSel}`}
              onClick={uscitaDx}
            />
          </div>
          <div>
            <img
              className={styles.ico}
              src={`${!selectedSvgU2 ? icoDx : icoDxSel}`}
              onClick={uscitaSx}
            />
          </div>
          <div>
            <img
              className={styles.ico}
              src={`${!selectedSvgU3 ? icoBasso : icoBassoSel}`}
              onClick={uscitaAlto}
            />
          </div>
          <div>
            <img
              className={styles.ico}
              src={`${!selectedSvgU4 ? icoAlto : icoAltoSel}`}
              onClick={uscitaBasso}
            />
          </div>
        </RowSubTab>
      </ColumSubTab>
    </div>
  );
}

export default Transitions;
