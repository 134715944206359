import React, { useState, useEffect } from "react";
import SunEditor from "suneditor-react";
import "suneditor/dist/css/suneditor.min.css"; // Import Sun Editor's CSS File

const InputTextArea = (props) => {
  const [display, setDisplay] = useState(true);

  const hotspot = props.modelSelected?.list_button_hotspot?.find(
    (hotspot) => hotspot.id === props.modelSelected.hotspot_selected
  );

  const [content, setContent] = useState(hotspot?.description);

  function handleChange(value) {
    setContent(value);
  }

  useEffect(() => {
    props.onChange(content);
  }, [content]);

  const [defValue, setDefValue] = useState('Sample Text');

  useEffect(() => {
    setDefValue(hotspot.description);
  }, [hotspot.description])

  return (
    <>
      <SunEditor
        id="editor"
        lang="it"
        name={"Hotspot Container"}
        setContents={defValue}
        width="80%"
        height="200px"
        autoFocus="true"
        imageMultipleFile="true"
        imageUploadSizeLimit="4000"
        videoFileInput="false"
        onChange={handleChange}
        setOptions={{
          buttonList: [
            [
              "font",
              "fontSize",
              "formatBlock",
              "underline",
              "italic",
              "strike",
              "subscript",
              "fontColor",
              "hiliteColor",
              "textStyle",
              "bold",
              "align",
              "horizontalRule",
              "list",
              "lineHeight",
              "table",
              "link",
              "image",
              "video",
            ],
          ],
        }}
      />
    </>
  );
};
export default InputTextArea;
