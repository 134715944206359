import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { modelActions } from "../redux/model";
import { Button } from "reactstrap";
import { nanoid } from "@reduxjs/toolkit";
import ContainerSelectObject from "../component/ui/ContainerSelectObject";
import SubtitleTab from "../component/ui/SubtitleTab";
import styles from "../component/ui/ItemSelectObject.module.css";
import ItemSelectObject from "../component/ui/ItemSelectObject";
import SubTabContainer from "../component/ui/SubTabContainer";
import ColorPickerContainer from "../component/ui/InputComponent/ColorPickerContainer";
import InputTextArea from "../component/ui/InputComponent/InputTextArea";
import { FormControlLabel } from "@mui/material";
import RowSubTab from "../component/ui/InputComponent/RowSubTab";
import { hotspotCreator } from "../model/hotspotData";
import NumberFactor from "../component/ui/InputComponent/NumberFactor";
import ColumSubTab from "../component/ui/InputComponent/ColumSubTab";
import ButtonUpload from "../component/ui/ButtonUpload";
import InputCheckBox from "../component/ui/InputComponent/InputCheckBox";
import icoPlus from "../assets/ico/ico1.png";
import icoCerchio from "../assets/ico/icona-cerchio1.svg";
import icoCerchioSel from "../assets/ico/icona-cerchio-selezionato.svg";
import icoQuadrato from "../assets/ico/icona-quadrato.svg";
import icoQuadratoSel from "../assets/ico/icona-quadrato-selezionato.svg";
import icoQuad from "../assets/ico/icona-rettangolo.svg";
import icoQuadSel from "../assets/ico/icona-quad-sel.svg";
import icoTrinagle from "../assets/ico/icona-trianglolo.svg";
import icoTringleSel from "../assets/ico/icona-trianglolo-sel.svg";

function ToggleHotspot(props) {
  const dispatch = useDispatch();

  const idConfig = useSelector((state) =>
    state.config.find((config) => config.id === "general")
  );

  const project = useSelector((state) =>
    state.project.find((project) => project.id === idConfig.projectOnEdit)
  );

  const modelSelected = useSelector((state) =>
    state.model.find((model) => model.id === project.selected_model)
  );
  const listButtonHotspot = modelSelected?.list_button_hotspot;

  const [hotspotEdit, setEditHotspotDetech] = useState(false);
  const hotspot_selected = modelSelected?.list_button_hotspot?.find(
    (hotspot) => hotspot.id === props.modelSelected.hotspot_selected
  );

  const [backgroundContent, setContentBackgroundColor] = useState("#ffffff");
  const [BorderContetColor, setBorderContetColor] = useState("#ffffff");
  const [content, setContent] = useState("Your Hotspot Text");
  const [borderContentRadius, setBorderContentRadius] = useState("2px");
  const [borderContentSize, setBorderContentSize] = useState("2px");
  const [hotspotShapeColor, setHotspotShapeCOlor] = useState("#ffffff");
  const [hotspotShapeBorderColor, setHotspotBorderShapeCOlor] =
    useState("#ffffff");

  const [color, setColorBorder] = useState("#000000");

  const [selectedSvg1, setSelectedSvg1] = useState(false);
  const [selectedSvg2, setSelectedSvg2] = useState(false);
  const [selectedSvg3, setSelectedSvg3] = useState(false);
  const [selectedSvg4, setSelectedSvg4] = useState(false);

  function liveEditHotspot(dataType, data) {
     const newHotspot = hotspotCreator("UPDATE", hotspot_selected, dataType, data);
    dispatch(
      modelActions.setEditHotspot({
        idModel: props.modelSelected.id,
        hotspot: newHotspot,
      })
    );
  }

  function addHotspot() {
    setEditHotspotDetech(true);
    //Aggiungo un Nuovo Hotspot con valori di default e lo selezione per l'edit e attivo la modalità detetch
    dispatch(
      modelActions.setHotspotDetechPosition({
        idModel: props.modelSelected.id,
        value: true,
      })
    );

    const listHotspot = [];

    const idHotspot = nanoid();

    listButtonHotspot.map((hotspot) => listHotspot.push(hotspot));
    listHotspot.push(hotspotCreator("NEW", idHotspot, "", ""));
    dispatch(
      modelActions.setListButtonHotspot({
        idModel: props.modelSelected.id,
        value: listHotspot,
      })
    );

    dispatch(
      modelActions.setSelectedHotspot({
        idModel: props.modelSelected.id,
        value: idHotspot,
      })
    );

    setContentBackgroundColor("#ffffff");
    setBorderContetColor("#ffffff");
    setContent("Your Hotspot Text");
    setBorderContentRadius("2px");
    setBorderContentSize("2px");
  }

  function onChangeContentHotspot(content) {
    setContent(content);
    liveEditHotspot("description", content);
  }
  function hotspotContentColorHandler(color) {
    setContentBackgroundColor(color);
    liveEditHotspot("contentBackground", color);
  }
  function hotspotContentBorderHandler(color) {
    setBorderContetColor(color);
    liveEditHotspot("borderContentColor", color);
  }
  function hotspotContentBorderRadius(size) {
    setBorderContentRadius(size.target.value);
    liveEditHotspot("borderContentRadius", size.target.value);
  }
  function hotspotContentBorderSize(size) {
    setBorderContentSize(size.target.value);
    liveEditHotspot("borderContentSize", size.target.value);
  }
  function hotspotColorHandler(color) {
    setHotspotShapeCOlor(color);
    liveEditHotspot("shape_color", color);
  }
  function hotspotBorderHandler(color) {
    setHotspotBorderShapeCOlor(color);
    liveEditHotspot("shape_border_color", color);
  }

  function detechPosition() {
    dispatch(
      modelActions.setHotspotDetechPosition({
        idModel: props.modelSelected.id,
        value: !props.modelSelected.hotspot_detech_position,
      })
    );
  }

  function removeHotspot(id) {
    const listHotspot = [];

    listButtonHotspot.map((hotspot) => {
      if (hotspot.id !== id) {
        listHotspot.push(hotspot);
      }
    });
    dispatch(
      modelActions.setListButtonHotspot({
        idModel: props.modelSelected.id,
        value: listHotspot,
      })
    );
    console.log(hotspot_selected);
    if (hotspot_selected.id === id) {
      console.log("if hotspot");
      if (listHotspot.length !== 0) {
        selectHotspotAction(0);
      } else {
        setEditHotspotDetech(false);
      }
    }
  }

  function saveHotspot() {}

  function selectHotspotAction(i) {
    setContentBackgroundColor(listButtonHotspot[i].contentBackgroundColor);
    setBorderContetColor(listButtonHotspot[i].borderContentColor);
    setContent(listButtonHotspot[i].description);
    setBorderContentRadius(listButtonHotspot[i].borderContentRadius);
    setBorderContentSize(listButtonHotspot[i].borderContentSize);

    setEditHotspotDetech(true);

    dispatch(
      modelActions.setSelectedHotspot({
        idModel: props.modelSelected.id,
        value: listButtonHotspot[i].id,
      })
    );
  }

  function selectIcoHandler1() {
    setSelectedSvg1(true);
    setSelectedSvg2(false);
    setSelectedSvg3(false);
    setSelectedSvg4(false);
  }
  function selectIcoHandler2() {
    setSelectedSvg1(false);
    setSelectedSvg2(true);
    setSelectedSvg3(false);
    setSelectedSvg4(false);
  }
  function selectIcoHandler3() {
    setSelectedSvg1(false);
    setSelectedSvg2(false);
    setSelectedSvg3(true);
    setSelectedSvg4(false);
  }
  function selectIcoHandler4() {
    setSelectedSvg1(false);
    setSelectedSvg2(false);
    setSelectedSvg3(false);
    setSelectedSvg4(true);
  }

  return (
    <div className="controls">
      <SubtitleTab title="Aggiungi Hotspot" />
      <ContainerSelectObject>
        <div>
          <div>
            <div className={styles["container-plus"]} onClick={addHotspot}>
              <div className={styles.elem1}>
                <img
                  src={icoPlus}
                  alt="add hotspot"
                  className={styles["icon-plus"]}
                />
              </div>
            </div>
            {listButtonHotspot?.map((hotspot, i) => (
              <ItemSelectObject
                title={hotspot.name}
                key={i}
                index={hotspot.id}
                onSelectClick={() => selectHotspotAction(i)}
                isDeletable={true}
                deletableAction={() => removeHotspot(hotspot.id)}
                onActive={hotspot.id === props.modelSelected.hotspot_selected}
              />
            ))}
          </div>
        </div>
      </ContainerSelectObject>

      {hotspotEdit && (
        <div>
          <SubTabContainer
            id="hot-6"
            title="POSIZIONE"
            tooltip="Cattura Posizione"
          >
            <InputCheckBox
              label="Cattura Posizione"
              checked={props.modelSelected.hotspot_detech_position}
              onChange={detechPosition}
            />
          </SubTabContainer>

          <SubTabContainer id="hot-4" title="CONTAINER" tooltip="container">
            <InputTextArea
              modelSelected={props.modelSelected}
              onChange={onChangeContentHotspot}
              content={content}
            ></InputTextArea>
            <RowSubTab>
              <ColumSubTab>
                Container{" "}
                <ColorPickerContainer
                  setColor={(color) => hotspotContentColorHandler(color)}
                  color={backgroundContent}
                  id={`HotspotContainerBackground-${props.modelSelected?.listButtonHotspot?.get[0]}`}
                ></ColorPickerContainer>
              </ColumSubTab>
              <ColumSubTab>
                Border{" "}
                <ColorPickerContainer
                  isBorder={true}
                  setColor={(color) => hotspotContentBorderHandler(color)}
                  color={BorderContetColor}
                  id={`HotspotContainerBorder-${props.modelSelected?.listButtonHotspot?.get[0]}`}
                ></ColorPickerContainer>
              </ColumSubTab>
            </RowSubTab>
            <RowSubTab>
              <ColumSubTab>
                Radius{" "}
                <NumberFactor
                  value={borderContentRadius}
                  id={hotspot_selected?.id}
                  onChange={hotspotContentBorderRadius}
                ></NumberFactor>
              </ColumSubTab>
              <ColumSubTab>
                border Size{" "}
                <NumberFactor
                  value={borderContentSize}
                  id={hotspot_selected?.id}
                  onChange={hotspotContentBorderSize}
                ></NumberFactor>
              </ColumSubTab>
            </RowSubTab>
          </SubTabContainer>

          <SubTabContainer id="hot-2" title="STILE HOTSPOT" tooltip="icona">
            <div>colore</div>
            <RowSubTab>
              <ColumSubTab>
                Container{" "}
                <ColorPickerContainer
                  setColor={(color) => hotspotColorHandler(color)}
                  color={hotspotShapeColor}
                  id={`HotspotBackground-${props.modelSelected?.listButtonHotspot?.get[0]}`}
                ></ColorPickerContainer>
              </ColumSubTab>
              <ColumSubTab>
                Border{" "}
                <ColorPickerContainer
                  isBorder={true}
                  setColor={(color) => hotspotBorderHandler(color)}
                  color={hotspotShapeBorderColor}
                  id={`HotspotBorder-${props.modelSelected?.listButtonHotspot?.get[0]}`}
                ></ColorPickerContainer>
              </ColumSubTab>
            </RowSubTab>

            <RowSubTab>
              <div>forma</div>
            </RowSubTab>

            <RowSubTab>
              <div>
                <img
                  id="ico-cerchio"
                  // className={selectedSvg1 ? styles["filter-selected"] : ""}
                  src={`${!selectedSvg1 ? icoCerchio : icoCerchioSel}`}
                  alt=""
                  onClick={/*setSelectedSvg1(!selectedSvg1)*/ selectIcoHandler1}
                ></img>
                <img
                  id="ico-quadrato1"
                  // className={selectedSvg2 ? styles["filter-selected"] : ""}
                  src={`${!selectedSvg2 ? icoQuadrato : icoQuadratoSel}`}
                  alt=""
                  onClick={selectIcoHandler2}
                ></img>
                <img
                  id="ico-quadrato2"
                  // className={selectedSvg3 ? styles["filter-selected"] : ""}
                  src={`${!selectedSvg3 ? icoQuad : icoQuadSel}`}
                  alt=""
                  onClick={selectIcoHandler3}
                ></img>
                <img
                  id="ico-triangolo"
                  // className={selectedSvg4 ? styles["filter-selected"] : ""}
                  src={`${!selectedSvg4 ? icoTrinagle : icoTringleSel}`}
                  alt=""
                  onClick={selectIcoHandler4}
                ></img>
              </div>
            </RowSubTab>

            <RowSubTab>
              <ButtonUpload
                id="btn-upload-htspt"
                text="Image"
                accept="image/*"
                onChange={() => {}}
              />
            </RowSubTab>
          </SubTabContainer>

          <SubTabContainer
            id="hot-3"
            title="COLORE E BACKGROUND"
            tooltip="color & background"
          ></SubTabContainer>

          <SubTabContainer id="hot-1" title="ANIMAZIONE" tooltip="animazioni">
            <div>animations</div>
          </SubTabContainer>
        </div>
      )}
    </div>
  );
}

export default ToggleHotspot;
