import React, { useState } from "react";
import ReactTooltip from "react-tooltip";
import icoArrow from "../../assets/ico/icona-info-freccia.svg";
import icoArrowClose from "../../assets/ico/icona-freccia2.png";
import icoInfo from "../../assets/ico/icona-info.svg"
import styles from "./SubTabContainer.module.css";

function SubTabContainer(props) {
  const [close, setClose] = useState(false);

  return (
    <div className={styles.container}>
      <div
        className={`${styles["container"]} ${
          close && styles["background-fade-out"]
        }`}
      >
        <div className={styles["Frame1boxautorotate1783"]}>
          <div className={styles["row-align"]}>

          <div className={styles["iconfreccia"]}
              onClick={() => {
                setClose(!close);
              }}
            >
              <img
                className={styles["image3"]}
                id={props.id}
                src={
                  !close
                    ? icoArrow
                    : icoArrowClose
                }
                alt="freccia22629"
              />
            </div>

          <div className={styles["titlecheckboxrotate1257"]}>
           
            <span className={styles["text"]}>{props.title}</span>
           
          </div>
          <div
              className={styles["iconinfo522241"]}
              data-tip
              data-for={`info-tip-${props.id}`}
            >
              <div
                alt="Ellipse54522242"
                className={styles["svg"]}
              >
              <img
                src={icoInfo}
                alt="icon_info522243"
                className={styles["image2"]}
              />
              </div>
            </div>
            <ReactTooltip
              id={`info-tip-${props.id}`}
              place="bottom"
              effect="float"
            >
              {props.tooltip}
            </ReactTooltip>
          </div>
          <div
            className={`${styles["autorotate522251"]} ${
              close && styles["fade-out"]
            }`}
          >
            {props.children}
          </div>
        </div>
      </div>
    </div>
  );
}

export default SubTabContainer;
