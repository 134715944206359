import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Label } from "reactstrap";
import NotImage from "../../assets/images/not-texture.png";
import { toast } from "react-toastify";
import { modelActions } from "../../redux/model";
import { changeTexture, rgbaTohex } from "../../utility/function/textureChange";
import SubtitleTab from "../../component/ui/SubtitleTab";
import ColorPickerContainer from "../../component/ui/InputComponent/ColorPickerContainer";
import InputImageRow from "../../component/ui/InputComponent/InputImageRow";
import { saveColorFactorEmissive } from "../../utility/function/textureChange";

function EmissiveTexture(props) {
  const dispatch = useDispatch();

  const idConfig = useSelector((state) =>
    state.config.find((config) => config.id === "general")
  );

  const project = useSelector((state) =>
    state.project.find((project) => project.id === idConfig.projectOnEdit)
  );

  const materialsViewer =
    document.querySelector("model-viewer")?.model?.materials;

  const selectedMaterialViewer = materialsViewer?.find(
    (mat) => mat.name === props.materialSelected?.name
  );

  const newMaterialList = [];
  const listOfMaterial = props.modelSelected.list_material_two;

  const [colorGlb, setColorGlb] = useState("#ffffff");

  // let colorGlb = "#ffffff";
  // function setColorGlb(color) {
  //   colorGlb = color;
  // }

  function convertColor(hex) {
    let c;
    if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
      c = hex.substring(1).split("");
      if (c.length === 3) {
        c = [c[0], c[0], c[1], c[1], c[2], c[2]];
      }
      c = "0x" + c.join("");
      return `${[(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",")},1`;
    }
  }

  let newMaterialialList = [];

  function colorHandler(color) {
    setColorGlb(color);

    let color1 = convertColor(color);
    color1 = color1.split(",").map((numberString) => parseFloat(numberString));

    const colordef = [
      color1[0] / 255,
      color1[1] / 255,
      color1[2] / 255,
      1
    ];
    console.log(colordef);

    selectedMaterialViewer.setEmissiveFactor(colordef);

    newMaterialialList = saveColorFactorEmissive(
      listOfMaterial,
      selectedMaterialViewer,
      colordef,
      colorGlb
    );
    
    dispatch(
      modelActions.updateListMaterial({
        idModel: props.modelSelected.id,
        value: newMaterialialList,
      })
    );
  }

  async function changeTextureHandler(event) {
    if (!event.target.files[0]) {
      return;
    }
    console.log(document.querySelector('model-viewer').model.materials[0])
    const url = await changeTexture('emissiveTexture', selectedMaterialViewer, event.target.files[0], props.modelSelected, project);

    listOfMaterial.map((material) => {
      if (material.name === props.materialSelected.name) {
        let a = {
          name: material.name,
          metallicFactor: material.metallicFactor,
          roughnessFactor: material.roughnessFactor,
          baseColorFactor: material.baseColorFactor,
          emissiveFactor: material.emissiveFactor,
          baseColorImage: material.baseColorImage,
          metallicRoughnessImage: material.metallicRoughnessImage,
          emissiveImage: url,
          normalImage: material.normalImage,
          occlusionImage: material.occlusionImage,
        };
        newMaterialList.push(a);
      }
       else {
        let b = {
          name: material.name,
          metallicFactor: material.metallicFactor,
          roughnessFactor: material.roughnessFactor,
          baseColorFactor: material.baseColorFactor,
          emissiveFactor: material.emissiveFactor,
          baseColorImage: material.baseColorImage,
          metallicRoughnessImage: material.metallicRoughnessImage,
          emissiveImage: material.emissiveImage,
          normalImage: material.normalImage,
          occlusionImage: material.occlusionImage,
        };
        newMaterialList.push(b);
      }
    });

    dispatch(
      modelActions.updateListMaterial({
        idModel: props.modelSelected.id,
        value: newMaterialList,
      })
    );
  }

  async function defaultThumb() {
    if (!props.originalTxt?.source) {
      return null;
    }
    const promise = await props.originalTxt.source?.createThumbnail(256, 256);
    return promise;
  }

  async function restoreTexture() {
    const savedTxt = props.originalTxt;
    selectedMaterialViewer.emissiveTexture.setTexture(savedTxt);
    const thumb = await defaultThumb();
    listOfMaterial.map((material) => {
      if (material.name === props.materialSelected.name) {
        newMaterialList.push({
          name: material.name,
          metallicFactor: material.metallicFactor,
          roughnessFactor: material.roughnessFactor,
          baseColorFactor: material.baseColorFactor,
          emissiveFactor: material.emissiveFactor,
          baseColorImage: material.baseColorImage,
          metallicRoughnessImage: material.metallicRoughnessImage,
          emissiveImage: thumb ? thumb : NotImage,
          normalImage: material.normalImage,
          occlusionImage: material.occlusionImage,
        });
      } else {
        newMaterialList.push({
          name: material.name,
          metallicFactor: material.metallicFactor,
          roughnessFactor: material.roughnessFactor,
          baseColorFactor: material.baseColorFactor,
          emissiveFactor: material.emissiveFactor,
          baseColorImage: material.baseColorImage,
          metallicRoughnessImage: material.metallicRoughnessImage,
          emissiveImage: material.emissiveImage,
          normalImage: material.normalImage,
          occlusionImage: material.occlusionImage,
          //
        });
      }
    });

    dispatch(
      modelActions.updateListMaterial({
        idModel: props.modelSelected.id,
        value: newMaterialList,
      })
    );
  }

  function removeTexture() {
    selectedMaterialViewer.emissiveTexture.setTexture(null);

    listOfMaterial.map((material) => {
      if (material.name === selectedMaterialViewer.name) {
        let a = {
          name: material.name,
          metallicFactor: material.metallicFactor,
          roughnessFactor: material.roughnessFactor,
          baseColorFactor: material.baseColorFactor,
          emissiveFactor: material.emissiveFactor,
          baseColorImage: material.baseColorImage,
          metallicRoughnessImage: material.metallicRoughnessImage,
          emissiveImage: NotImage,
          normalImage: material.normalImage,
          occlusionImage: material.occlusionImage,
        };
        newMaterialList.push(a);
      } else {
        let b = {
          name: material.name,
          metallicFactor: material.metallicFactor,
          roughnessFactor: material.roughnessFactor,
          baseColorFactor: material.baseColorFactor,
          emissiveFactor: material.emissiveFactor,
          baseColorImage: material.baseColorImage,
          metallicRoughnessImage: material.metallicRoughnessImage,
          emissiveImage: material.emissiveImage,
          normalImage: material.normalImage,
          occlusionImage: material.occlusionImage,
        };
        newMaterialList.push(b);
      }
    });
    dispatch(
      modelActions.updateListMaterial({
        idModel: props.modelSelected.id,
        value: newMaterialList,
      })
    );
  }

  useEffect(() => {
    const rgba = selectedMaterialViewer.emissiveFactor;
    const newColor = rgbaTohex(rgba);
    setColorGlb(newColor);
  }, [selectedMaterialViewer])

  return (
    <div>
      <SubtitleTab title="Texture emission" />
      <ColorPickerContainer
        setColor={(colorGlb) => colorHandler(colorGlb)}
        color={colorGlb}
        id={`baseColorMaterial-${props.materialSelected?.name}`}
      ></ColorPickerContainer>
      <InputImageRow id={"emissive-texture"} text="Image" image={props.materialSelected?.emissiveImage} onChange={changeTextureHandler} accept="image/*" remove={true} restore={true} restoreTexture={restoreTexture} removeTexture={removeTexture}></InputImageRow>
    </div>
  );
}

export default EmissiveTexture;
