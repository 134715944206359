import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { modelActions } from "../redux/model";
import { Label } from "reactstrap";
import InputSliderRow from "../component/ui/InputComponent/InputSliderRow";

import SubtitleTab from "../component/ui/SubtitleTab";

function Exposure(props) {
  const { t } = useTranslation();
  const dispatch = useDispatch();

  function lightHandler(event) {
    dispatch(
      modelActions.setLightExposition({
        idModel: props.modelSelected.id,
        value: event.target.value,
      })
    );
  }

  return (
    <div>
      <SubtitleTab title="Esposizione" />

      <InputSliderRow
        id={"exposure"}
        maxValue={2}
        minValue={0}
        step={0.1}
        value={props.modelSelected?.lightExposition}
        onChange={lightHandler}
      ></InputSliderRow>
    </div>
  );
}

export default Exposure;
