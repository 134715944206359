import React, { useState } from "react";
import { useAuth } from "../utility/AuthContext";
import {
  Container,
  Card,
  CardHeader,
  CardBody,
  Label,
  CardFooter,
  Button,
  Col
} from "reactstrap";
import { Link } from 'react-router-dom';

function RecoverPassword() {
  const [error, setError] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [email, setEmail] = useState(false);
  const [message, setMessage] = useState("");
  const { resetPassword } = useAuth();

  async function submitHandler(event) {
    event.preventDefault();

    try {
      setMessage("");
      setError("");
      setIsLoading(true);
      await resetPassword(email);
      setMessage("Check your email");
      // toast.info(message);
    } catch (err) {
      setError(err.message);
      // toast.error(err.message);
    }
    setIsLoading(false);
  }

  return (
    <React.Fragment>
      {/* <h1>Recover Password</h1>
      <section>
        {error && <p className="centered">{error}</p>}
        {message && <p className="centered">{message}</p>}
        <form onSubmit={submitHandler}>
          <div>
            <label htmlFor="email">Your Email</label>
            <input type="email" id="email" ref={emailRef} required />
          </div>
          <div>
            <button disabled={isLoading}>Recover Password</button>
          </div>
        </form>
      </section> */}
      <Container fluid={true} className="centered">
        <Col  xl="5 xl-100">
          <Card>
            <CardHeader>
            <Link to={`${process.env.PUBLIC_URL}/login`}>ico<i className= "typcn typcn-arrow-left"/></Link>
              <h5>Recover Password</h5>
            </CardHeader>
            <CardBody>
              <form className="theme-form">
                <div>
                  <Label className="col-form-label pt-0">Email address</Label>
                  <input
                    onChange={(e) => setEmail(e.target.value)}
                    className="form-control btn-pill"
                    type="email"
                    placeholder="Enter email"
                    required
                  />
                </div>
              </form>
            </CardBody>
            <CardFooter>
              <Button
                color="primary btn-pill"
                className="mr-1"
                disabled={isLoading}
                onClick={submitHandler}
              >
                Submit
              </Button>
              {error && <p>{error}</p>}
              {message && <p>{message}</p>}
            </CardFooter>
          </Card>
        </Col>
      </Container>
    </React.Fragment>
  );
}

export default RecoverPassword;
