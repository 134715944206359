import React from "react";
import { modelActions } from "../redux/model";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import InputCheckBox from "../component/ui/InputComponent/InputCheckBox";

function CameraControls(props) {
  const dispatch = useDispatch();
  const { t } = useTranslation();

  function controlsHandler() {
    dispatch(
      modelActions.setCameraControls({
        idModel: props.modelSelected.id,
        value: !props.modelSelected.cameraControls,
      })
    );
  }

  return (
    <div>
      <InputCheckBox
        checked={props?.modelSelected?.cameraControls}
        onChange={controlsHandler}
        label={t("tab1_cameracontrols")}
      />
    </div>
  );
}

export default CameraControls;
