import React, { useState, useEffect } from "react";
import InputCheckBox from "../component/ui/InputComponent/InputCheckBox";
import SubTabContainer from "../component/ui/SubTabContainer";
import { generalActions } from "../redux/generalConfig";
import { modelActionsProject } from "../redux/project";
import { useDispatch, useSelector } from "react-redux";
import ColorPickerContainer from "../component/ui/InputComponent/ColorPickerContainer";
import RowSubTab from "../component/ui/InputComponent/RowSubTab";
import { firestore } from "../utility/firebase";
import ColumSubTab from "../component/ui/InputComponent/ColumSubTab";
import { QRCodeSVG } from "qrcode.react";

function QRCode(props) {
  
  const dispatch = useDispatch();

  const idConfig = useSelector((state) =>
    state.config.find((config) => config.id === "general")
  );
  
  const bgColor = idConfig.qrBackGroundColor;
  const fgColor = idConfig.qrForeGroundColor;

  let project = useSelector((state) =>
    state.project.find((project) => project.id === idConfig.projectOnEdit)
  );

  const arButton = idConfig?.qrCode;

  function displayModalQR() {
    // setModalQR(!modalQR);
    dispatch(generalActions.setQrCode({ idConfig: "general" }));
    dispatch(modelActionsProject.setQrCode({ idProject: project.id }));
  }

  const [arBtn, setArBtn] = useState(arButton);

  // useEffect(() => {
  //   if (props.viewerPage) {
  //     firestore
  //       .collection("Progetti")
  //       .doc(project.id)
  //       .get()
  //       .then((doc) => {
  //         const progetto = doc.data();
  //         if(progetto.qrCode) {
  //           setArBtn(progetto.qrCode);
  //         }
  //       });
  //   }
  // }, []);

  return (
    <div>
      <InputCheckBox
        checked={arButton}
        onChange={displayModalQR}
        label="Abilita AR"
      />
      {/* {modalQR && <ModalQR fgColor={fgColor} bgColor={bgColor} />}
      {modalQR && <BackDropTransparent onCancel={displayModalQR} />} */}
      <RowSubTab>
        <ColumSubTab>
          Background
          <ColorPickerContainer
            setColor={(color) => dispatch(generalActions.setQrBackGroundColor({idConfig: "general",value: color}))}
            color={bgColor}
            id={`HotspotContainerBackground-bgcolor`}
          ></ColorPickerContainer>
        </ColumSubTab>
        <ColumSubTab>
          Foreground
          <ColorPickerContainer
            setColor={(color) => dispatch(generalActions.setQrForeGroundColor({idConfig: "general",value: color}))}
            color={fgColor}
            id={`HotspotContainerBorder-fgcolor`}
          ></ColorPickerContainer>
        </ColumSubTab>
      </RowSubTab>
      <RowSubTab>
      <SubTabContainer id="qr" title="QR CODE" tooltip="Qr code">
        <QRCodeSVG
          value={`http://localhost:3000/${props.project?.id}`}
          bgColor={bgColor}
          fgColor={fgColor}
        />
        </SubTabContainer>
      </RowSubTab>
    </div>
  );
}

export default QRCode;
