import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useDropzone } from "react-dropzone";
import model, { modelActions } from "../redux/model";
import { Button } from "reactstrap";
import NotImage from "../assets/images/not-texture.png";
import { useHistory } from "react-router-dom";
import { modelActionsProject } from "../redux/project";
import { projectDefaultActions } from "../redux/defaultProject";
import { useParams } from "react-router-dom";
import { storage } from "../utility/firebase";
import DropZoneModel from "../component/ui/DropZoneModel";

function AddFirstModel() {
  const [files, setFiles] = useState([]);

  const dispatch = useDispatch();
  const history = useHistory();
  const { id } = useParams();

  const idConfig = useSelector((state) =>
    state.config.find((config) => config.id === "general")
  );

  const project = useSelector((state) =>
    state.project.find((project) => project.id === idConfig.projectOnEdit)
  );
  const modelSelected = useSelector((state) =>
    state.model.find((model) => model.id === project.selected_model)
  );

  async function defaultThumbBaseColorTexture(material) {
    let promise = "";
    if (!material.pbrMetallicRoughness?.baseColorTexture?.texture) {
      return NotImage;
    } else {
      promise =
        await material.pbrMetallicRoughness?.baseColorTexture?.texture?.source?.createThumbnail(
          512,
          512
        );
    }

    // let storageRefFile = storage.ref(
    //   `Progetti/${project.id}/${modelSelected.id}/model-${modelSelected.id}-material-${material.name}-baseColor.png`
    // );
    const responseSrc = await fetch(promise);
    const blobSrc = await responseSrc.blob();
    // const url = await storageRefFile.put(blobSrc).then((snapshot) => {
    //   return storageRefFile.getDownloadURL();
    // });

    // return url;
    return URL.createObjectURL(blobSrc)
  }

  async function defaultThumbMetallicRoughnessTexture(material) {
    let promise = "";
    if (!material.pbrMetallicRoughness?.metallicRoughnessTexture?.texture) {
      return NotImage;
    } else {
      promise =
        await material.pbrMetallicRoughness?.metallicRoughnessTexture?.texture?.source?.createThumbnail(
          512,
          512
        );
    }
    // let storageRefFile = storage.ref(
    //   `Progetti/${project.id}/${modelSelected.id}/model-${modelSelected.id}-material-${material.name}-metalness.png`
    // );
    const responseSrc = await fetch(promise);
    const blobSrc = await responseSrc.blob();
    // const url = await storageRefFile.put(blobSrc).then((snapshot) => {
    //   return storageRefFile.getDownloadURL();
    // });

    // return url;
    return URL.createObjectURL(blobSrc);
  }
  async function defaultThumbEmissiveTexture(material) {
    let promise = "";
    if (!material.emissiveTexture?.texture) {
      return NotImage;
    } else {
      promise =
        await material.emissiveTexture?.texture?.source?.createThumbnail(
          512,
          512
        );
    }

    // let storageRefFile = storage.ref(
    //   `Progetti/${project.id}/${modelSelected.id}/model-${modelSelected.id}-material-${material.name}-emissive.png`
    // );
    const responseSrc = await fetch(promise);
    const blobSrc = await responseSrc.blob();
    // const url = await storageRefFile.put(blobSrc).then((snapshot) => {
    //   return storageRefFile.getDownloadURL();
    // });

    // return url;
    return URL.createObjectURL(blobSrc);

  }

  async function defaultThumbNormalTexture(material) {
    let promise = "";
    if (!material.normalTexture?.texture) {
      return NotImage;
    } else {
      promise = await material.normalTexture?.texture?.source?.createThumbnail(
        512,
        512
      );
    }

    // let storageRefFile = storage.ref(
    //   `Progetti/${project.id}/${modelSelected.id}/model-${modelSelected.id}-material-${material.name}-normal.png`
    // );
    const responseSrc = await fetch(promise);
    const blobSrc = await responseSrc.blob();
    // const url = await storageRefFile.put(blobSrc).then((snapshot) => {
    //   return storageRefFile.getDownloadURL();
    // });

    // return url;
    return URL.createObjectURL(blobSrc);

  }

  async function defaultThumbOcclusionTexture(material) {
    let promise = "";

    if (!material.occlusionTexture?.texture) {
      return NotImage;
    } else {
      promise =
        await material.occlusionTexture?.texture?.source?.createThumbnail(
          512,
          512
        );
    }

    // let storageRefFile = storage.ref(
    //   `Progetti/${project.id}/${modelSelected.id}/model-${modelSelected.id}-material-${material.name}-occlusion.png`
    // );
    const responseSrc = await fetch(promise);
    const blobSrc = await responseSrc.blob();
    // const url = await storageRefFile.put(blobSrc).then((snapshot) => {
    //   return storageRefFile.getDownloadURL();
    // });

    // return url;
    return URL.createObjectURL(blobSrc);

  }

  async function saveReduxInfo() {
    const materials = document.getElementById("model")?.model?.materials;
    materials.map(async (material) => {
      let baseColorImage = null;
      let metallicRoughnessImage = null;
      let emissiveImage = null;
      let normalImage = null;
      let occlusionImage = null;


      baseColorImage = await defaultThumbBaseColorTexture(material);
      metallicRoughnessImage = await defaultThumbMetallicRoughnessTexture(
        material
      );
      emissiveImage = await defaultThumbEmissiveTexture(material);
      normalImage = await defaultThumbNormalTexture(material);
      occlusionImage = await defaultThumbOcclusionTexture(material);

      dispatch(
        modelActions.setListMaterialTwo({
          idModel: modelSelected.id,
          value: material,
          image: baseColorImage,
          imageMR: metallicRoughnessImage,
          imageEM: emissiveImage,
          imageNR: normalImage,
          imageOC: occlusionImage,
        })
      );
      // dispatch(
      //   modelActions.setListMaterialInitial({
      //     idModel: modelSelected.id,
      //     value: material,
      //     image: baseColorImage,
      //     imageMR: metallicRoughnessImage,
      //     imageEM: emissiveImage,
      //     imageNR: normalImage,
      //     imageOC: occlusionImage,
      //   })
      // );
    });
  }

  let url;
  const { getRootProps, getInputProps } = useDropzone({
    accept: ".glb",
    onDrop: async (acceptedFiles) => {
      setFiles(
        acceptedFiles.map(async (file) => {
          // let storageRefFile = storage.ref(
          //   `Progetti/${project.id}/${modelSelected.id}/model-${modelSelected.id}.glb`
          // );
          const responseSrc = await fetch(URL.createObjectURL(file));
          const blobSrc = await responseSrc.blob();

          // url = await storageRefFile.put(blobSrc).then((snapshot) => {
          //   return storageRefFile.getDownloadURL();
          // });
          let srcModel = URL.createObjectURL(blobSrc);
          dispatch(
            modelActions.setSrc({
              idModel: modelSelected.id,
              // src: url,
              src: srcModel
            })
          );
          dispatch(
            modelActions.setOriginalModel({
              idModel: modelSelected.id,
              value: srcModel
            })
          )
          dispatch(
            modelActionsProject.setIsModelLoaded({
              idProject: project.id,
              value: true,
            })
          );
          isModelLoaded();
        })
      );
    },
  });

  function isModelLoaded() {
    let model = document.querySelector("model-viewer");
    console.log('prova caricare');
    if (model.loaded) {
      saveReduxInfo();
    } else {
      setTimeout(() => {
        isModelLoaded();
      }, 100);
    }
  }

  // useEffect(() => {
  //   if (project.isFirstModelLoaded) {
  //     setTimeout(() => {
  //       saveReduxInfo();
  //     }, 1700);
  //   }
  // }, [project.isFirstModelLoaded]);

  return (
    <div>
      <div {...getRootProps({ className: "dropzone" })}>
        <input {...getInputProps()} />
        <DropZoneModel />
      </div>
    </div>
  );
}

export default AddFirstModel;
