// starter kit

import Samplepage from "../component/starterkits/samplepage";
import EditModel from "../pages/EditModel";
import TabCard from "../component/ui/cards/tabCard";
import Profile from "../pages/Profile";
import EditProject from "../pages/EditProject";


export const routes = [
  { path: "/dashboard/project", Component: Samplepage },
  { path: "/dashboard/profile", Component: Profile },
  { path: "/dashboard/documentation", Component: TabCard },
  { path: "/dashboard/edit-model", Component: EditModel},
  { path: "/dashboard/edit-project", Component: EditProject},
];
