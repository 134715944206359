import React from "react";
import styles from "../ui/ModelContainer.module.css";
import CardModelView from "../viewer/CardModelView";

function MenuContainer(props) {
  let project = props.project;

  return (
    <>
      {props.style === "TOP" && (
        <div
          className={
            props?.menu === "plane"
              ? styles["wrapper-classic"]
              : styles["row-wrapp"]
          }
        >
          {props.children}
        </div>
      )}

      {props.style === "BOTTOM" && (
        <div
          className={
            props?.menu === "plane"
              ? styles["wrapper-classic"]
              : styles["row-wrapp"]
          }
        >
          {props.children}
        </div>
      )}

      {props.style === "LEFT" && (
        <div
          className={
            props?.menu === "plane"
              ? styles["wrapper-classic-lateral"]
              : styles["row-wrapp-lateral"]
          }
        >
          {props.children}
        </div>
      )}
      {props.style === "RIGHT" && (
        <div
          className={
            props?.menu === "plane"
              ? styles["wrapper-classic-lateral"]
              : styles["row-wrapp-lateral"]
          }
        >
          {props.children}
        </div>
      )}
    </>
  );
}

export default MenuContainer;
