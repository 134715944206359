import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useDropzone } from "react-dropzone";
import { modelActions } from "../redux/model";
import { Button } from "reactstrap";
import NotImage from "../assets/images/not-texture.png";
import { useHistory } from "react-router-dom";

function AddModel(props) {
  const [isGlbLoaded, setIsGlbLoaded] = useState(false);

  const [files, setFiles] = useState([]);

  const dispatch = useDispatch();
  const history = useHistory();
  const idConfig = useSelector((state)=> state.config.find((config)=> config.id === "general"));


  const project = useSelector((state) =>
    state.project.find((project) => project.id === idConfig.projectOnEdit)
  );
  const modelSelected = useSelector((state) =>
    state.model.find((model) => model.id === project.selected_model)
  );

  const { getRootProps, getInputProps } = useDropzone({
    accept: ".glb",
    onDrop: (acceptedFiles) => {
      setFiles(
        acceptedFiles.map((file) => {
          dispatch(
            modelActions.setSrc({
              idModel: modelSelected.id,
              src: URL.createObjectURL(file),
            })
          );
        })
      );
      setIsGlbLoaded(true);
    },
  });

  async function defaultThumbBaseColorTexture(material) {
    if (!material.pbrMetallicRoughness?.baseColorTexture?.texture) {
      return NotImage;
    }
    const promise =
      await material.pbrMetallicRoughness?.baseColorTexture?.texture?.source?.createThumbnail(
        256,
        256
      );
      console.log(promise);
    return promise;
  }
  async function defaultThumbMetallicRoughnessTexture(material) {
    if (!material.pbrMetallicRoughness?.metallicRoughnessTexture?.texture) {
      return NotImage;
    }
    const promise =
      await material.pbrMetallicRoughness?.metallicRoughnessTexture?.texture?.source?.createThumbnail(
        256,
        256
      );
    return promise;
  }
  async function defaultThumbEmissiveTexture(material) {
    if (!material.emissiveTexture?.texture) {
      return NotImage;
    }
    const promise =
      await material.emissiveTexture?.texture?.source?.createThumbnail(
        256,
        256
      );
    return promise;
  }
  async function defaultThumbNormalTexture(material) {
    if (!material.normalTexture?.texture) {
      return NotImage;
    }
    const promise =
      await material.normalTexture?.texture?.source?.createThumbnail(256, 256);
    return promise;
  }
  async function defaultThumbOcclusionTexture(material) {
    if (!material.occlusionTexture?.texture) {
      return NotImage;
    }
    const promise =
      await material.occlusionTexture?.texture?.source?.createThumbnail(
        256,
        256
      );
    return promise;
  }

  async function saveReduxInfo() {
    const materials = document.getElementById("modal")?.model?.materials;

    materials.map(async (material) => {
      let baseColorImage = null;
      let metallicRoughnessImage = null;
      let emissiveImage = null;
      let normalImage = null;
      let occlusionImage = null;

      baseColorImage = await defaultThumbBaseColorTexture(material);
      metallicRoughnessImage = await defaultThumbMetallicRoughnessTexture(
        material
      );
      emissiveImage = await defaultThumbEmissiveTexture(material);
      normalImage = await defaultThumbNormalTexture(material);
      occlusionImage = await defaultThumbOcclusionTexture(material);

      dispatch(
        modelActions.setListMaterialTwo({
          idModel: modelSelected.id,
          value: material,
          image: baseColorImage,
          imageMR: metallicRoughnessImage,
          imageEM: emissiveImage,
          imageNR: normalImage,
          imageOC: occlusionImage,
          emissiveTexture: material.emissiveTexture,
          normalTexture: material.normalTexture,
          occlusionTexture: material.occlusionTexture,
        })
      );
    });
  }

  useEffect(() => {
    if (isGlbLoaded) {
      setTimeout(() => {
        saveReduxInfo();
      }, 1700);
    }
  }, [isGlbLoaded]);

  function editModelHandler() {
    // history.push("/dashboard/edit-model");
  }

  return (
    <div>
      {!isGlbLoaded && (
        <div {...getRootProps({ className: "dropzone" })}>
          <input {...getInputProps()} />
          <p>Drag 'n' drop your .glb here, or click to select file</p>
        </div>
      )}
      {isGlbLoaded && (
        <div>
          <model-viewer
            id="modal"
            src={modelSelected?.src}
            camera-controls={true}
            auto-rotate={true}
          />
          <div>
            <Button onClick={editModelHandler}>Edit Model</Button>
          </div>
        </div>
      )}
    </div>
  );
}

export default AddModel;
