import React, { useState } from "react";
import styles from "./InputImage.module.css";
import ButtonUpload from "../ButtonUpload";

function InputImageRow(props) {
  return (
    <div className={styles["row-element"]} key={props.id}>
      <div
        style={{
          backgroundImage: `url(${props.image})`,
          backgroundSize: "cover",
          width: "60px",
          height: "60px",
          borderRadius: "13px",
          padding: "0px",
          marginRight: "25px",
        }}
        onClick={() => props.onChange}
      ></div>

      <ButtonUpload
        text={props.text}
        onChange={props.onChange}
        accept={props.accept}
        id={props.id}
      ></ButtonUpload>
      {props.restore && (
        <i
          className={styles["restore-button"]}
          onClick={props.restoreTexture}
        />
      )}
      {props.remove && (
        <i className={styles["remove-button"]} onClick={props.removeTexture} />
      )}
    </div>
  );
}

export default InputImageRow;
