import { createSlice } from '@reduxjs/toolkit';

const initialAuthState = {
  isAuthenticated: false,
  userName: '',
  userLastName: '',
  userEmail: '',
  userPassword: '',
  isCompany: false,
  companyName: '',
  companyVat: '',
  userLogData: {},
  userRegisterData: {}
};

const authSlice = createSlice({
  name: 'authentication',
  initialState: initialAuthState,
  reducers: {
    login(state) {
      state.isAuthenticated = true;
    },
    logout(state) {
      state.isAuthenticated = false;
    },
    setUserName(state, action) {
      state.userName = action.payload;
    },
    setUserLastName(state, action) {
      state.userLastName = action.payload;
    },
    setUserEmail(state, action) {
      state.userEmail = action.payload;
    },
    setUserPassword(state, action) {
      state.userPassword = action.payload;
    },
    isCompany(state) {
      state.isCompany = true;
    },
    setCompanyName(state, action) {
      state.companyName = action.payload;
    },
    setCompanyVat(state, action) {
      state.companyVat = action.payload;
    },
    setUserLogData(state, action) {
      state.userLogData = action.payload;
    },
    setUserRegisterData(state, action) {
      state.userRegisterData = action.payload;
    }
  },
});

export const authActions = authSlice.actions;

export default authSlice.reducer;