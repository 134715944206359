import React from "react";
import icoSpaarkly from "../../assets/ico/icona_spaarkly.png";
import style from "../ui/ProjectHeader.module.css";



export default function ProjectHeader(){

    return (
        <div className={style.headerProject}>
          <img
            src={icoSpaarkly}
            alt="image not found"
            className={style.spaarklyLogo1}
          />
        </div>
      )
}

