import React, { createRef, useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
// import { useTranslation } from "react-i18next";
import { modelActions } from "../redux/model";

import { useDropzone } from "react-dropzone";
import { toast } from "react-toastify";
import { Form, Button } from "reactstrap";
import { modelActionsProject } from "../redux/project";
import { nanoid } from "@reduxjs/toolkit";

function CloneModel(props) {
  const dispatch = useDispatch();
  const project = props.project;
  const model = props.model;

  function duplicateModel() {
    const idModel = nanoid();
    dispatch(modelActions.setNewModel({ idModel: idModel, value: model }));
    dispatch(
      modelActionsProject.addModelToList({
        idProject: project.id,
        idModel: idModel,
      })
    );
  }

  return (
    <div>
      <Button onClick={duplicateModel}> Duplicate Model</Button>
    </div>
  );
}

export default CloneModel;
